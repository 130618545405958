import { faChevronLeft, faChevronRight, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'


export default function Earnings() {

    const tableEntries = [
        { name: "Anup Kumar", earn: "$35", date: "10/05/2002", time: "10:12 AM", status: "Received" },
        { name: "Adlene Smith", earn: "$40", date: "11/15/2022", time: "02:35 PM", status: "Pending" },
        { name: "Raj Mohan", earn: "$30", date: "12/07/2023", time: "09:45 AM", status: "Received" },
        { name: "Sushwanth Rao", earn: "$50", date: "01/23/2023", time: "01:20 PM", status: "Pending" },
        { name: "Thomas Brown", earn: "$45", date: "03/14/2024", time: "11:10 AM", status: "Received" },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [search, setSearch] = useState('');

    const filteredEntries = tableEntries.filter(entry =>
        entry.name.toLowerCase().includes(search.toLowerCase())
    );
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    let currentEntities = filteredEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(filteredEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(filteredEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='w-[100%] bg-white/5 border rounded-xl p-7 lg:p-10 font-poppins'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-8'>
                <div className=' rounded-md border-2 px-3 py-5 flex gap-3'>
                    <div className='px-1 flex items-center'><FontAwesomeIcon icon={faWallet} className=' p-4 bg-white rounded-full text-[#F98736] text-xl' /></div>
                    <div className='grid content-center'>
                        <p className='uppercase  font-bold text-xl'>$ 690</p>
                        <p>Total earnings</p>
                    </div>
                </div>
                <div className=' rounded-md border-2 px-3 py-5 flex gap-3'>
                    <div className='px-1 flex items-center'><FontAwesomeIcon icon={faWallet} className='p-4 bg-white rounded-full text-[#F98736] text-xl' /></div>
                    <div className='grid content-center'>
                        <p className='uppercase  font-bold text-xl'>$ 410</p>
                        <p>Referral earnings</p>
                    </div>
                </div>
                <div className=' rounded-md border-2 px-3 py-5 flex gap-3'>
                    <div className='px-1 flex items-center'><FontAwesomeIcon icon={faWallet} className='p-4 bg-white rounded-full text-[#F98736] text-xl' /></div>
                    <div className='grid content-center'>
                        <p className='uppercase  font-bold text-xl'>$ 280</p>
                        <p>Purchased earnings</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center pt-10 pb-5'>
                <p className=' uppercase font-bold  text-2xl'>Transactions</p>
                <select className='bg-transparent outline-none border-2 px-3 py-1 rounded-lg'>
                    <option value="1" className='text-black'>Referrals</option>
                    <option value="2" className='text-black'>Unknown</option>
                    <option value="3" className='text-black'>No-name</option>
                </select>
            </div>
            <div className='rounded-2xl'>
                <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                    <div className='md:w-[40%]'>
                        <input
                            type='search'
                            className='border rounded-md py-2 w-[100%] px-5 outline-none bg-transparent placeholder:text-white'
                            placeholder='Enter User'
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='flex items-center'>
                        <p className='text-end text-sm px-3 mb-2'>Show no of entity
                            <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                <option className='text-black' value="5">5</option>
                                <option className='text-black' value="7">7</option>
                                <option className='text-black' value="10">10</option>
                            </select>
                        </p>
                    </div>
                </div>
                <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                    <table className='w-full text-center  '>
                        <thead className='  uppercase font-bold text-lg border-b py-3'>
                            <tr>
                                <th></th>
                                <th className='py-3'>Person Name</th>
                                <th>Rewards Earned</th>
                                <th>Date & Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {currentEntities.map((entity, index) => (
                                <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                    <td className='px-3'><input type='checkbox'></input></td>
                                    <td className='px-5 md:px-0 '>{entity.name}</td>
                                    <td className='px-5 md:px-0'>{entity.earn}</td>
                                    <td className='px-5 md:px-0'>{entity.date} {entity.time}</td>
                                    <td className={`px-5 md:px-0 font-bold ${entity.status === 'Received' ? 'text-[#00ff00]' : 'text-white'}`}>{entity.status}</td>
                                </tr>
                            ))}
                            {/* Add empty rows if needed */}
                            {Array.from({ length: emptyRowCount }).map((_, index) => (
                                <tr key={`empty-${index}`} className='h-16'>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='mt-2 flex justify-between text-xs px-3'>
                    <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                    <div className='my-auto flex justify-center items-center gap-1'>
                        <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                        {pageNumbers.map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                onClick={() => handlePageClick(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                    </div>
                    <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                </div>
            </div>
        </div>
    )
}
