import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronLeft, faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import * as XLSX from 'xlsx';
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';
import { saveAs } from 'file-saver';

export default function AllMemeberReports() {

    const [tableEntries, settableEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const searchTermLower = searchTerm.toLowerCase();

    const filteredEntities = currentEntities.filter((entity) => {
        return (
            (entity.id && entity.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.showdetails && entity.showdetails.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.upline && entity.upline.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.downline && entity.downline.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.Cust_MyID && entity.Cust_MyID.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.custID && entity.custID.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.username && entity.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.password && entity.password.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.sponsorid && String(entity.sponsorid).toLowerCase().includes(searchTermLower)) ||
            (entity.Cust_Name && entity.Cust_Name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.email && entity.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.Cust_mobileNo && entity.Cust_mobileNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.Entry_Date && entity.Entry_Date.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.activedate && entity.activedate.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (entity.Cust_Package && entity.Cust_Package.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        fetchmemoryData();
    }, []);

    const fetchmemoryData = async () => {
        try {
            const response = await axios.get('http://localhost:3006/api/AllMember');

            console.log("data", response.data);
            settableEntries(response.data.data);

            console.log("data", response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [filters, setFilters] = useState({
        CusID: '',
        username: '',
        password: '',
        sponsorid: '',
        Cust_Name: '',
        email: '',
        Cust_mobileNo: '',
        Entry_Date: '',
        activeDate: '',
        Cust_Package: ''
    });

    const handleInputChange = (value, field) => {
        const updatedFilters = {
            ...filters,
            [field]: value,
        };
        setFilters(updatedFilters);
        console.log("Updated Filters:", updatedFilters);
        filterData(updatedFilters, field);
    };

    const filterData = async (value, key) => {

        try {
            const hasValidFilters = Object.values(value).some(filter => filter !== '');

            if (!hasValidFilters) {
                console.error('No valid filters provided');
                return; // Early return if no valid filters
            }
            let post = {
                [key]: value[key]
            };
            const response = await axios.post('http://localhost:3006/api/getAllMember', post);

            console.log("data", response.data);
            settableEntries(response.data.data);

            console.log("data", response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const exportToExcel = (data) => {
        if (!data || data.length === 0) {
            alert('No data available to export');
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Entities');

        XLSX.writeFile(workbook, 'entities.xlsx');
    };

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const doc = new Document({
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        text: "Your Data",
                        heading: "Heading1",
                    }),
                    new Table({
                        rows: filteredEntities.map(entity => {
                            return new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph(entity?.id ? entity.id.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.showdetails ? entity.showdetails.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.upline ? entity.upline.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.downline ? entity.downline.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.Cust_MyID ? entity.Cust_MyID.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.custID ? entity.custID.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.username ? entity.username.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.email ? entity.email.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.Cust_mobileNo ? entity.Cust_mobileNo.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.Entry_Date ? entity.Entry_Date.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.activedate ? entity.activedate.toString() : 'Null')],
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(entity?.Cust_Package ? entity.Cust_Package.toString() : 'Null')],
                                    }),
                                ],
                            });
                        }),
                    }),
                ],
            }],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "data.docx");
        }).catch(err => {
            console.error("Error generating document:", err);
        });
    };

    const DownloadCSV = ({ data, fileName }) => {
        const convertToCSV = (objArray) => {
            const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
            let str = '';
            const headers = Object.keys(array[0]);
            str += headers.join(',') + '\r\n';
            for (let i = 0; i < array.length; i++) {
                let line = '';
                for (let index in array[i]) {
                    if (line !== '') line += ',';
                    line += array[i][index];
                }
                str += line + '\r\n';
            }
            return str;
        };

        const downloadCSV = () => {
            const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
            const csvURL = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = csvURL;
            link.download = `${fileName}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <button onClick={downloadCSV} className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>
                Export To CSV
            </button>
        );
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 pr-3 md:pr-10 mt-5 font-poppins'>
                <div className=''>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                        <div className=''>
                            <p className='my-auto font-bold text-xl  uppercase'>All Member Report <span className='font-poppins font-normal text-sm'> Users</span>
                            </p>
                        </div>

                    </div>
                    <div className='mb-5 border rounded-xl  text-sm'>
                        <div className='py-3 px-5 flex flex-col lg:flex-row items-center justify-between gap-3 md:gap-0 border-b'>
                            <p>All Customer Data with filter user</p>
                            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handleDownload}>Export To Word</button>
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={() => exportToExcel(filteredEntities)}>Export To Excel</button>
                                {/* <button onClick={downloadCSV} className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>
      Export To CSV
    </button> */}
                                <DownloadCSV data={filteredEntities} fileName="FilteredData" />
                                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handlePrint}>Export To Print</button>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row items-center justify-between px-5 py-2'>
                            <p className='text-end px-3 my-auto font-bold'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                            <div className='flex flex-col md:flex-row items-center text-nowrap gap-3 md:w-[35%]'>
                                <p className='font-bold'>Search :</p>
                                <input className='w-[100%] py-1 rounded-md bg-transparent border outline-none px-3' value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}></input>
                            </div>
                        </div>
                        <div className='overflow-hidden hover:overflow-x-auto'>
                            <table className='w-full text-center  text-[13px] text-nowrap'>
                                <thead className='uppercase font-bold border-y py-3'>
                                    <tr>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2 py-3'>Serial No</th>
                                        <th className='px-2'>Cust ID</th>
                                        <th className='px-2'>User Name</th>
                                        <th className='px-2'>Password</th>
                                        <th className='px-2'>Sponser ID</th>
                                        <th className='px-2'>Cust Name</th>
                                        <th className='px-2'>Email</th>
                                        <th className='px-2'>Mobile No</th>
                                        <th className='px-2'>Register date</th>
                                        <th className='px-2'>Active Date</th>
                                        <th className='px-2'>Last Active Package</th>
                                    </tr>
                                    <tr className='border-t'>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2'></th>
                                        <th className='px-2 py-3'><input className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5' placeholder='Serial No'
                                            onChange={(e) => handleInputChange(e.target.value, 'Cust_MyID')}></input></th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Cust ID'
                                                onChange={(e) => handleInputChange(e.target.value, 'CusID')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='User Name'
                                                onChange={(e) => handleInputChange(e.target.value, 'username')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='password'
                                                type='password'
                                                onChange={(e) => handleInputChange(e.target.value, 'password')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Sponsor ID'
                                                onChange={(e) => handleInputChange(e.target.value, 'sponsorid')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Cust Name'
                                                onChange={(e) => handleInputChange(e.target.value, 'Cust_Name')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Email'
                                                onChange={(e) => handleInputChange(e.target.value, 'email')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Mobile'
                                                onChange={(e) => handleInputChange(e.target.value, 'Cust_mobileNo')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Register date'
                                                onChange={(e) => handleInputChange(e.target.value, 'Entry_Date')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Active date'
                                                onChange={(e) => handleInputChange(e.target.value, 'activeDate')}
                                            />
                                        </th>
                                        <th className='px-2 py-3'>
                                            <input
                                                className='border bg-transparent w-[100%] rounded-md px-2 placeholder:text-white/60 outline-none py-0.5'
                                                placeholder='Last active package'
                                                onChange={(e) => handleInputChange(e.target.value, 'Cust_Package')}
                                            />
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className='divide-y'>
                                    {filteredEntities.map((entity, index) => (
                                        <tr key={index} className=' h-16 text-sm text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-2'>{entity.id}</td>
                                            <td className='px-5 md:px-2'>{entity.showdetails}</td>
                                            <td className='px-5 md:px-2'>{entity.upline}</td>
                                            <td className='px-5 md:px-2'>{entity.downline}</td>
                                            <td className='px-5 md:px-2'><button className='p-1 bg-blue-500 rounded-md'>Login</button></td>
                                            <td className='px-5 md:px-2'>{ }</td>
                                            <td className='px-5 md:px-2'>{entity.CusID}</td>
                                            <td className='px-5 md:px-2'>{entity.username}</td>
                                            <td className='px-5 md:px-2'>{entity.password}</td>
                                            <td className='px-5 md:px-2'>{entity.sponsorid}</td>
                                            <td className='px-5 md:px-2'>{entity.Cust_Name}</td>
                                            <td className='px-5 md:px-2'>{entity.email}</td>
                                            <td className='px-5 md:px-2'>{entity.Cust_mobileNo}</td>
                                            <td className='px-5 md:px-2'>{entity.Entry_Date}</td>
                                            <td className='px-5 md:px-2'>{entity.activedate}</td>
                                            <td className='px-5 md:px-2'>{entity.Cust_Package}</td>
                                        </tr>
                                    ))}
                                    {/* Add empty rows if needed */}
                                    {Array.from({ length: emptyRowCount }).map((_, index) => (
                                        <tr key={`empty-${index}`} className='h-16'>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}