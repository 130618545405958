import React, { useState, useEffect } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import toast from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';

export default function Security() {

    const user = useSelector((state) => state.user);
    const CusID = user[0]?.CusID;

    const [tableEntries, setTableEntries] = useState([]);
    const [resetPassword, setResetPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);

    const handlePasswordToggle1 = () => {
        setVisible1(!visible1);
    };

    const handlePasswordToggle2 = () => {
        setVisible2(!visible2);
    };

    const handleResetPassword = () => {
        setResetPassword(!resetPassword);
    };

    const handleSubmit = async () => {

        if (newPassword !== confirmPassword) {
            toast.error("New password and Confirm password do not match!");
            return false;
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/changePassword`, {
                "currentPassword": currentPassword,
                "newPassword": newPassword,
                "CusID": CusID
            });
            if (response.data.status === true) {
                toast.success('Password changed successfully!');
                setResetPassword(false);
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");

            } else if (response.data.status === false && response.data.message === 'Current & New password should be different') {
                toast.error(response.data.message);

            } else if (response.data.status === false) {
                toast.error(response.data.message);
            } else {
                toast.error("An error occurred. Please try again");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    const getLoginDetials = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getDeviceLogin?CusID=${CusID}`);
            if (response.data.status === true) {
                setTableEntries(response.data.data);
            } else {
                setTableEntries([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (CusID !== null) {
            getLoginDetials();
        }
    }, [CusID]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <div>
            <div data-aos="fadeIn" data-aos-duration='2000'>
                <div className='bg-white/10 shadow-md rounded-xl p-5 mt-5'>
                    <p className='font-bold text-lg md:text-xl  uppercase'>Change Login Password</p>

                    {resetPassword ? (
                        <div className='mt-5 flex items-center justify-center '>
                            <div className='grid md:w-[50%] gap-5'>
                                <div className="relative">
                                    <input
                                        className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                        type={visible1 ? 'text' : 'password'}
                                        placeholder='Current password'
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    <span
                                        className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                        onClick={handlePasswordToggle1}>
                                        <FontAwesomeIcon icon={visible1 ? faEye : faEyeSlash} />
                                    </span>
                                </div>
                                <div className="relative">
                                    <input
                                        className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                        type={visible2 ? 'text' : 'password'}
                                        placeholder='New password'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <span
                                        className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                        onClick={handlePasswordToggle2}
                                    >
                                        <FontAwesomeIcon icon={visible2 ? faEye : faEyeSlash} />
                                    </span>
                                </div>
                                <div className="relative">
                                    <input
                                        className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                        type={visible3 ? 'text' : 'password'}
                                        placeholder='Confirm New password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>

                                <h1 className='text-lg font-semibold mt-5'>Password Requirements:</h1>
                                <div className='mt-1 pl-5'>
                                    <li>Minimum 8 characters long - the more, the better</li>
                                    <li>At least one lowercase character</li>
                                    <li>At least one number, symbol, or whitespace character</li>
                                </div>

                                <div className='mt-5 flex justify-end gap-3'>
                                    <button
                                        className='px-3 py-1 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm rounded-md font-semibold'
                                        onClick={handleSubmit}
                                    >
                                        Change Login Password
                                    </button>
                                    <button className='px-3 py-1 bg-gray-300 text-black px-4 py-2 text-sm font-semibold rounded-md shadow-md shadow-black/30' onClick={handleResetPassword}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='flex justify-center gap-5 mt-5'>
                                <button
                                    className=' bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md '
                                    onClick={handleResetPassword}
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className='mt-5'>
                    <div className='rounded-2xl'>
                        <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                            <div className='md:w-[40%]'>
                                <p className='my-auto font-bold text-xl  uppercase'>Recent Devices</p>
                            </div>
                        </div>
                        <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                            <table className='w-full text-center'>
                                <thead className='  uppercase font-bold text-lg border-b py-3'>
                                    <tr>
                                        <th className='py-3'>IP ADDRESS</th>
                                        <th>BROWSER</th>
                                        <th>DEVICE</th>
                                        <th>DEVICE TYPE</th>
                                        <th>PLACE</th>
                                        <th>DATE</th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y'>
                                    {tableEntries.map((entity, index) => (
                                        <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-0'>{entity.ip_address}</td>
                                            <td className='px-5 md:px-0'>{entity.browser}</td>
                                            <td className='px-5 md:px-0 capitalize'>{entity.device_type}</td>
                                            <td className='px-5 md:px-0'>{entity.os}</td>
                                            <td className='px-5 md:px-0'>{entity.country_name}</td>
                                            <td className='px-5 md:px-0'>{formatDate(entity.created_at)}</td>
                                        </tr>
                                    ))}
                                    {Array.from({ length: tableEntries }).map((_, index) => (
                                        <tr key={`empty-${index}`} className='h-16'>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
