import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";

AOS.init();

const Signin = () => {
    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);

    const handlePasswordVisibility = () => {
        setVisible(!visible);
    };


    return (
        <div>
            <Header />
            <div
                className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden flex items-center"
                style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
            >
                <div className="w-[90%] m-auto flex flex-col lg:flex-row gap-5 md:gap-0  justify-center items-center py-5">
                    <div className="lg:w-[50%] w-full h-full"
                        data-aos="fade-right"
                        data-aos-duration="2000">
                        <div><img src="/assets/Admin.png" className="w-[25%] mx-auto md:mx-0"></img></div>
                        <div>
                            <p className="font-bold text-lg md:text-2xl 2xl:text-3xl mt-12"> Build digital products with:</p>
                            <p className="font-bold text-sm md:text-lg 2xl:text-xl mt-6"> All-in-one tool</p>
                            <p className="text-sm 2xl:text-lg mt-3">Amazing Features to make your life easier & work efficient.</p>
                            <p className="font-bold text-sm md:text-lg 2xl:text-xl mt-6"> Easily add & manage your services</p>
                            <p className="text-sm 2xl:text-lg mt-3">It brings together your tasks, projects, timelines, files and more.</p>
                        </div>

                    </div>

                    <div className="lg:w-[40%]" data-aos="fade-left" data-aos-duration="2000" u>
                        <div className="border-2 rounded-md py-5 md:px-10 px-3">
                            <div className="text-center">
                                <p className=" text-3xl font-bold">SIGN IN</p>
                                <p className=" mt-1 text-sm px-2">
                                    Access to our dashboard.
                                </p>
                            </div>
                            <div className="border-b-2 py-3 pb-7 text-[#F23985] font-medium">
                                <div className="mt-2 relative">
                                    <input
                                        type="text"
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter Email"
                                    />
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-3 relative">
                                    <input
                                        type={visible ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={`w-full rounded-lg focus:outline-none py-3 px-10  text-sm `}
                                        placeholder="Enter the password"
                                    />
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                    <button
                                        onClick={handlePasswordVisibility}
                                        className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                                    >
                                        {visible ? (
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        )}
                                    </button>
                                </div>


                                <div className="mt-5 cursor-pointer" >
                                    <div className="shadow-lg text-white shadow-black/30 bg-white py-1 px-2 rounded-xl">
                                        <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">
                                            Sign up
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mt-5">
                                <p className="font-semibold text-sm">Or continue with</p>
                                <div className="flex justify-between gap-2 mt-2 mb-5">
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg">

                                        <button
                                            className="flex items-center gap-2"
                                        >
                                            <img src="../assets/google.png" className="w-4 h-4" alt="Google Icon" />
                                            <p className="font-semibold text-black text-sm">Google</p>
                                        </button>


                                    </div>
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer" >
                                        <img src="../assets/fb.png" className="w-5" />
                                        <p className="font-semibold text-black text-sm">Facebook</p>
                                    </div>
                                </div>
                                <p className="text-xs text-center font-semibold">
                                    {" "}
                                    By registering you with our
                                    <span className="text-black font-semibold">
                                        {" "}
                                        Terms and Conditions.
                                    </span>
                                </p>
                                <p className='text-center text-sm font-semibold mt-2'>Already a memeber ? <a href='/user/register' className='text-blue-800'>Register</a></p>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;

