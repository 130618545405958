import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import { SERVER_URL } from '../../../config';

export default function Profile() {

    const admin = useSelector((state) => state.admin);
    const adminID = admin[0] ? admin[0].adminID : '';

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone_number: '',
        address: ''
    });

    const fetchAdminDetails = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getAdminProfile?adminID=${adminID}`);
            if (response.data.status === true) {
                const res = response.data.data;
                setFormData({
                    username: res.name || '',
                    email: res.email || '',
                    phone_number: res.phoneNumber || '',
                    address: res.address || ''
                });
            }
        } catch (error) {
            console.error('Error fetching admin details:', error);
        }
    };

    useEffect(() => {
        if (adminID !== null) {
            fetchAdminDetails();
        }
    }, [adminID]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${SERVER_URL}/api/updateAdminProfile`, {
                username: formData.username,
                phone_number: formData.phone_number,
                address: formData.address,
                adminID: adminID
            });
            if (response.data.status === true) {
                toast.success('Admin profile updated successfully!');
                fetchAdminDetails();
            } else if (response.data.status === false) {
                toast.error('Profile not updated');
                fetchAdminDetails();
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Profile not updated');
        }
    };

    return (
        <div data-aos="fadeIn" data-aos-duration="2000">
            <div className="p-3 md:p-7 mt-5 font-poppins">
                <h1 className="text-xl md:text-2xl font-bold uppercase ">Edit Profile ( Details )</h1>
                <div className="border rounded-md mt-2 font-poppins">
                    <div className="px-5 py-2 border-b">
                        <p className="font-bold text-lg">Edit Profile</p>
                    </div>
                    <form onSubmit={handleSubmit} className="px-5 py-3">
                        <div className="w-[100%] lg:w-[80%] mx-auto flex flex-col gap-3">
                            <div className="flex flex-col md:flex-row gap-3 items-center">
                                <p className="text-end md:w-[30%] font-semibold">Username</p>
                                <input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    className="bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md"
                                />
                            </div>
                            <div className="flex flex-col md:flex-row gap-3 items-center">
                                <p className="text-end md:w-[30%] font-semibold">Email</p>
                                <input
                                    name="email"
                                    value={formData.email}
                                    className="bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md"
                                    disabled
                                />
                            </div>
                            <div className="flex flex-col md:flex-row gap-3 items-center">
                                <p className="text-end md:w-[30%] font-semibold">Mobile No</p>
                                <input
                                    type="tel"
                                    name="phone_number"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    className="bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md"
                                />
                            </div>
                            <div className="flex flex-col md:flex-row gap-3 items-center">
                                <p className="text-end md:w-[30%] font-semibold">Address</p>
                                <input
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md"
                                />
                            </div>
                            <div className="flex justify-center">
                                <button className="px-5 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md">
                                    Update Details
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}