import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import toast from "react-hot-toast";
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useSelector } from 'react-redux';

export default function Configpage() {

    const admin = useSelector((state) => state.admin);
    const adminID = admin[0] ? admin[0].adminID : '';

    const [tableEntries, setTableEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [newPageName, setNewPageName] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [newPermission, setNewPermission] = useState('1');
    const [isUpdate, setUpdate] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const fetchTableEntries = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getConfig`);
            if (response.data.status === true) {
                setTableEntries(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching config pages:', error);
            toast.error('Failed to fetch config data.');
        }
    };

    useEffect(() => {
        fetchTableEntries();
    }, []);

    const handleAddOrUpdateDetails = async () => {
        const configPageData = {
            pageName: newPageName,
            message: newMessage,
            permission: newPermission,
            adminID: adminID
        };

        try {
            const response = await axios.post(`${SERVER_URL}/api/updateConfig`, configPageData);

            if (response.data.status === true) {
                toast.success('Data updated');
                fetchTableEntries();
            } else {
                toast.success('Data Not Updated');
            }

            setNewPageName('');
            setNewMessage('');
            setNewPermission('1');
            setUpdate(false);
            setSelectedId(null);

        } catch (error) {
            console.error('Error saving config page:', error);
            toast.error('Error saving config page.');
        }
    };


    const handleEditClick = (entry) => {
        setNewPageName(entry.PageName);
        setNewMessage(entry.Message);
        setNewPermission(entry.Permission);
        setUpdate(true);
        setSelectedId(entry.id);
    };

    const handleCancelUpdate = () => {
        setNewPageName('');
        setNewMessage('');
        setNewPermission('1');
        setUpdate(false);
        setSelectedId(null);
    };

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Config Pages</h1>

                {isUpdate ? (
                    <div className='rounded-lg mt-5 border'>
                        <div className='px-5 py-3 border-b font-bold text-lg'>
                            <p>{selectedId ? 'Edit Config' : 'Add New Config'}</p>
                        </div>
                        <div className='flex flex-col gap-3 w-[100%] lg:w-[80%] mx-auto px-5 py-3'>
                            {/* Page Name Input */}
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end font-bold md:w-[30%]'>PageName</p>
                                <input
                                    className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'
                                    type="text"
                                    value={newPageName}
                                    onChange={(e) => setNewPageName(e.target.value)}
                                />
                            </div>

                            {/* Message Input */}
                            <div className='flex flex-col md:flex-row gap-3'>
                                <p className='text-center md:text-end font-bold md:w-[30%] md:pt-3'>Message</p>
                                <textarea
                                    className='md:w-[50%] h-[125px] py-1 border outline-none px-3 rounded-md bg-transparent'
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                ></textarea>
                            </div>

                            {/* Permission Dropdown */}
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end font-bold md:w-[30%]'>Permission</p>
                                <select
                                    className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'
                                    value={newPermission}
                                    onChange={(e) => setNewPermission(e.target.value)}
                                >
                                    <option className='text-black' value="1">Active</option>
                                    <option className='text-black' value="0">Inactive</option>
                                </select>
                            </div>

                            {/* Buttons */}
                            <div className='flex gap-3 justify-center'>
                                <button
                                    className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                    onClick={handleAddOrUpdateDetails}
                                >
                                    {isUpdate ? 'Update Details' : 'Add Details'}
                                </button>
                                <button
                                    className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                    onClick={handleCancelUpdate}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='rounded-lg mt-5 border'>
                        <div className='px-5 py-3 border-b font-bold text-lg'>
                            <p>Config List</p>
                        </div>
                        <div className='border-b px-5 py-3'>
                            <button
                                className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                onClick={() => setUpdate(true)}
                            >
                                + Add New
                            </button>
                        </div>
                        <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                            <div className='text-sm font-bold m-auto'>
                                Total Record: {tableEntries.length}
                            </div>
                            <div></div>
                            <div></div>
                            <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                                <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page:</p>
                                <div className='w-[25%] md:w-[100%]'>
                                    <input
                                        className='w-[100%] border bg-transparent rounded-md py-1'
                                        type="number"
                                        value={entitiesPerPage}
                                        onChange={(e) => setEntitiesPerPage(parseInt(e.target.value) || 5)}
                                    />
                                </div>
                            </div>
                            <div className='text-sm font-bold m-auto'>
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>
                )}

                {/* Table Section */}
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div></div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>
                                Show no of entity
                                <select
                                    className='ml-2 outline-none rounded-md bg-transparent border-[1px]'
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>

                    {/* Table */}
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>

                                    <th className='px-2'>SlNo</th>
                                    <th className='px-2'>PageName</th>
                                    <th className='px-2'>Message</th>
                                    <th className='px-2'>Permission</th>
                                    <th className='px-2'>Updated_by</th>
                                    <th className='px-2'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>

                                        <td className='px-5 md:px-2'>{entity.SlNo}</td>
                                        <td className='px-5 md:px-2'>{entity.PageName}</td>
                                        <td className='px-5 md:px-2'>{entity.Message}</td>
                                        <td className='px-5 md:px-2'>{entity.Permission === 0 ? "Inctive" : "Active"}</td>
                                        <td className='px-5 md:px-2'>{entity.adminID}</td>
                                        <td className='px-5 md:px-2'>
                                            <button className='text-blue-500' onClick={() => handleEditClick(entity)}>Edit</button>
                                        </td>
                                    </tr>
                                ))}

                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td colSpan="8">&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button
                            className='bg-white text-black font-bold rounded-md px-5 py-1'
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            Prev
                        </button>

                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>

                        <button
                            className='bg-white text-black font-bold rounded-md px-5 py-1'
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}