import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { SERVER_URL } from '../../../config';


export default function WalletDetails() {
    const [data, setData] = useState([]);
    const [walletType, setWalletType] = useState('DEPOSIT');
    const [amount, setAmount] = useState('');
    const [amountOperator, setAmountOperator] = useState('greater');
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchWalletDetails();
    }, [currentPage, entitiesPerPage]);

    const fetchWalletDetails = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/walletDetails`, {
                params: {
                    walletType,
                    amount: amount || null,
                    amountOperator,
                },
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching wallet details:', error);
            setError(error);
        }
    };

    const totalRecords = data.length || 0;

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = data.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(totalRecords / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalRecords / entitiesPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Wallet Detail</h1>
                <div className='rounded-lg mt-5 border'>
                    <div className='px-5 py-3 border-b font-bold text-lg'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className='text-sm font-bold m-auto'>
                            Total Record: {totalRecords}
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center'>
                            <p className='text-sm font-bold'>Select Wallet:</p>
                            <select
                                className='bg-transparent border px-3 py-1 rounded-md outline-none'
                                value={walletType}
                                onChange={(e) => setWalletType(e.target.value)}
                            >
                                <option className="text-black" value='DEPOSIT'>Deposit</option>
                                <option className="text-black" value='INCOME'>Income</option>
                            </select>
                        </div>
                        <div className='flex md:grid gap-1 m-auto'>
                            <div className='flex items-center gap-3'>
                                <p className='text-sm font-bold'>Amount</p>
                               
                                <div className='border rounded-md  w-[50%]' >
                                <input
                                    type='text'
                                    className='bg-transparent w-full py-1 px-2  outline-none'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder='amount'
                                />
                                </div>
                                <select
                                    className='bg-transparent w-14 border text-center text-lg rounded-md outline-none'
                                    value={amountOperator}
                                    onChange={(e) => setAmountOperator(e.target.value)}
                                >
                                    <option className="text-black" value='greater'>&gt;</option>
                                    <option className="text-black" value='less'>&lt;</option>
                                    <option className="text-black" value='equal'>=</option>
                                </select>
                            </div>
                            <div>
                                <button
                                    onClick={fetchWalletDetails}
                                    className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] via-[#FF99BD] to-[#F23985] rounded-full shadow-md'
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page:</p>
                            <div className='w-[25%] '>
                                <select
                                    className='ml-2 outline-none rounded-md bg-transparent border-[1px]'
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="10">10</option>
                                    <option className='text-black' value="15">15</option>
                                </select>
                            </div>
                        </div>
                        <div className='text-sm font-bold m-auto'>
                            Page {currentPage} of {totalPages}
                        </div>
                    </div>
                </div>
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>
                            <p className='my-auto font-bold text-lg'>Showing {walletType} Wallet</p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>Cust_UserName</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Wallet Type</th>
                                    <th className='px-2'>Credit</th>
                                    <th className='px-2'>Debit</th>
                                    <th className='px-2'>Balance</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((user, userIndex) => (
                                        <tr key={user.id} className='h-16 text-sm text-center rounded-md'>
                                            <td>{userIndex + 1}</td>
                                            <td>{user.CusID}</td>
                                            <td>{user.username}</td>
                                            <td>{user.email}</td>
                                            <td>{walletType}</td>
                                            <td>{user.totalDeposit || 0}</td> {/* Assuming this field exists */}
                                            <td>{user.totalWithdraw || 0}</td> {/* Assuming this field exists */}
                                            <td>{user.balance || 0}</td> {/* Assuming this field exists */}
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='h-16'>
                                        <td colSpan="8">No records available</td>
                                    </tr>
                                )}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button
                            className='bg-white text-black font-bold rounded-md px-5 py-1'
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            Prev
                        </button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => setCurrentPage(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button
                            className='bg-white text-black font-bold rounded-md px-5 py-1'
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}