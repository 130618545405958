import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash, faGlobe } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import { useLocation } from 'react-router-dom';

AOS.init();

const Register = () => {
    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);
    const [country, setCountry] = useState("");
    const location = useLocation();
    const [sponsorID, setSponsorID] = useState('');
    const [isRefIdReadOnly, setIsRefIdReadOnly] = useState(false);
  
    const getQueryParams = () => {
      const params = new URLSearchParams(location.search);
      return params.get('ref_id'); 
    };
  
    useEffect(() => {
      const ref_id = getQueryParams();
      if (ref_id) {
        setSponsorID(ref_id); 
        setIsRefIdReadOnly(true); 
      }
    }, [location.search]);


    const countries = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo (Congo-Brazzaville)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Democratic Republic of the Congo",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar (formerly Burma)",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Korea",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ];

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handlePasswordVisibility = () => {
        setVisible(!visible);
    };


    return (
        <div>
            <Header />
            <div
                className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden flex items-center"
                style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
            >
                <div className="w-[90%] m-auto flex flex-col lg:flex-row gap-5 md:gap-0  justify-center 2xl:items-center py-5">
                    <div className="lg:w-[50%] w-full h-full"
                        data-aos="fade-right"
                        data-aos-duration="2000">
                        <div><img src="/assets/Icon (2).png" className="w-[25%] mx-auto md:mx-0"></img></div>
                        <div>
                            <p className="font-bold text-lg md:text-2xl 2xl:text-3xl mt-12"> Build digital products with:</p>
                            <p className="font-bold text-sm md:text-lg 2xl:text-xl mt-6"> All-in-one tool</p>
                            <p className="text-sm 2xl:text-lg mt-3">Amazing Features to make your life easier & work efficient.</p>
                            <p className="font-bold text-sm md:text-lg 2xl:text-xl mt-6"> Easily add & manage your services</p>
                            <p className="text-sm 2xl:text-lg mt-3">It brings together your tasks, projects, timelines, files and more.</p>
                        </div>

                    </div>

                    <div className="lg:w-[40%]" data-aos="fade-left" data-aos-duration="2000" u>
                        <div className="border-2 rounded-md py-5 md:px-10 px-3">
                            <div className="text-center">
                                <p className=" text-3xl font-bold uppercase">Register</p>
                                <p className=" mt-1 text-sm px-2">
                                    Access to our dashboard.
                                </p>
                            </div>
                            <div className="border-b-2 py-3 pb-7 text-[#F23985] font-medium">
                                <div className="mt-2 relative">
                                    <input
                                        type="text"
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter Sponsor ID"
                                        value={sponsorID}
                                        onChange={(e) => setSponsorID(e.target.value)}
                                        readOnly={isRefIdReadOnly}
                                    />
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-2 relative">
                                    <input
                                        type="text"
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter your Username"
                                    />
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-2 relative">
                                    <input
                                        type="text"
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter Name"
                                    />
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-2 relative">
                                    <input
                                        type="text"
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10  text-sm `}
                                        placeholder="Enter Email"
                                    />
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-2 relative">
                                    <select
                                        value={country}
                                        onChange={handleCountryChange}
                                        className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm bg-white appearance-none`}
                                    >
                                        <option value="" disabled>
                                            Select Country
                                        </option>
                                        {countries.map((country) => (
                                            <option key={country} className="text-black" value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                    <FontAwesomeIcon
                                        icon={faGlobe}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                </div>
                                <div className="mt-2 relative">
                                    <input
                                        type={visible ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={`w-full rounded-lg focus:outline-none py-3 px-10  text-sm `}
                                        placeholder="Enter the password"
                                    />
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="text-[#A4A4A4] absolute left-3 top-3.5"
                                    />
                                    <button
                                        onClick={handlePasswordVisibility}
                                        className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                                    >
                                        {visible ? (
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                size="sm"
                                                className="text-gray-400"
                                            />
                                        )}
                                    </button>
                                </div>


                                <div className="mt-5 cursor-pointer" >
                                    <div className="shadow-lg text-white shadow-black/30 bg-white py-1 px-2 rounded-xl">
                                        <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">
                                            Sign up
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <p className="font-semibold text-sm">Or continue with</p>
                                <div className="flex justify-between gap-2 mt-2 mb-5">
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg">

                                        <button
                                            className="flex items-center gap-2"
                                        >
                                            <img src="../assets/google.png" className="w-4 h-4" alt="Google Icon" />
                                            <p className="font-semibold text-black text-sm">Google</p>
                                        </button>


                                    </div>
                                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer" >
                                        <img src="../assets/fb.png" className="w-5" />
                                        <p className="font-semibold text-black text-sm">Facebook</p>
                                    </div>
                                </div>
                                <p className="text-xs text-center font-semibold">
                                    {" "}
                                    By registering you with our
                                    <span className="text-black font-semibold">
                                        {" "}
                                        Terms and Conditions.
                                    </span>
                                </p>
                                <p className='text-center text-sm font-semibold mt-2'>Already a memeber ? <a href='/user/' className='text-blue-800'>Sign in</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

