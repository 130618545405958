//  const SERVER_URL = "http://localhost:3006";
const SERVER_URL = "https://mybusinessclub.com";

const TOKEN_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'

//const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjJhZDFiZjU1LTRiMWQtNDA5YS05ZWMxLTJlNDJlMGNlYTZjMSIsIm9yZ0lkIjoiNDA4NzEyIiwidXNlcklkIjoiNDE5OTc5IiwidHlwZUlkIjoiYjdkZjU0NjQtNWE5MS00MmE2LWIyNWEtN2RhZGI2MmQwN2VmIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjY2NzA5NjIsImV4cCI6NDg4MjQzMDk2Mn0.vIYnrl6kUacKM44EfP-nEntBqsyQV749rFJRaOtckvk';

const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE';

// export const SERVER_URL = "https://mybusinessclub.com";
const PROVIDER_URL = 'https://red-special-sea.bsc.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2'

const MBCPayOut_ContractAddress = "0x232Cf52cd35922211fEA5A74Ce9Ecb4280e5D337"
const MBCToken_ContractAddress = "0x55d398326f99059fF775485246999027B3197955"


module.exports = {
    SERVER_URL, TOKEN_ADDRESS, MORALIS_API_KEY, PROVIDER_URL,
    MBCPayOut_ContractAddress, MBCToken_ContractAddress
} 