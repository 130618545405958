import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useSelector } from "react-redux";
import { SERVER_URL, TOKEN_ADDRESS, MORALIS_API_KEY } from '../../../config';

export default function SubscriptionHistory() {

    const user = useSelector((state) => state.user);
    const email = user[0].email;
    const CusID = user[0].CusID;

    console.log("member id ", CusID)

    const [transactions, setTransactions] = useState([])

    const fetchSubscriptionHistory = async () => {
        try {
            let response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
            console.log("fetchSubscriptionHistory",response)
            if (response.data.status === true) {
                console.log("fetchSubscriptionHistory",response.data.data)
                setTransactions(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (CusID !== null) {
            fetchSubscriptionHistory();
        }
    }, [CusID])


    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(transactions.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const openImageInNewTab = (entity) => {
        window.open(entity.doc, '_blank');
    };

    const totalPages = Math.ceil(transactions.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

        const formatDate = (isoDate) => {
            const date = new Date(isoDate);

            const options = {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };

            return new Intl.DateTimeFormat('en-US', options).format(date);
        };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
                <div className='rounded-2xl'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                        <div className='md:w-[40%]'>
                            <p className='my-auto font-bold text-xl  uppercase'>User KYC Log</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center  '>
                            <thead className='  uppercase font-bold border-b py-3'>
                                <tr>
                                    <th className='py-3 px-2'>S.No</th>
                                    <th className='px-2'>Cust_ID</th>
                                    <th className='px-2'>Ref_ID</th>
                                    <th className='px-2 whitespace-nowrap'>Date / Time</th>
                                    <th className='px-2'>Status	</th>
                                    <th className='px-2'>Subscribe_Type</th>
                                    <th className='px-2 whitespace-nowrap'>Subscription_Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{index + 1}</td>
                                        <td className='px-5 md:px-2'>{entity.CusID}</td>
                                        <td className='px-5 md:px-2'>{entity.subscribed_by}</td>
                                        <td className='px-5 md:px-2'>{formatDate(entity.createdAt)}</td>
                                        <td className='px-5 md:px-2 text-xs'>
                                            <div className={`font-bold px-3 py-1 mx-auto rounded-md ${entity.status === 'active' ? 'text-white bg-[#198754] ' : 'text-black bg-yellow-400'}`}>{entity.status}</div>
                                        </td>
                                        <td className='px-5 md:px-2'>{entity.plan}</td>
                                        <td className='px-5 md:px-2'>$ {entity.amount}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
