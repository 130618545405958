import React, { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../config';
import axios from 'axios';

const SendEmail = () => {
    const [formData, setFormData] = useState({
        category: '',
        subject: '',
        content: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { category, subject, content } = formData;

        if (!category || !subject || !content) {
            toast.error('All fields are required!');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${SERVER_URL}/api/sendEmail`, { category, subject, content });

            if (response.data.status === true) {
                toast.success('Email sent successfully!');
                setFormData({ category: '', subject: '', content: '' });
            } else {
                toast.error('Failed to send email.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Error sending email.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-[80%] mx-auto font-poppins">
            <h2 className="text-xl font-bold text-white mt-20">SEND EMAIL</h2>

            <form onSubmit={handleSubmit} className="mt-6 bg-white/10 p-6 rounded-lg shadow-lg">
                <div className="space-y-4">
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                        className="w-full p-3 bg-transparent border border-white/50 rounded-lg text-white outline-none"
                    >
                        <option className='text-black' value="" disabled>Select Email Category</option>
                        <option className='text-black' value="Engagement Reminder & Events Email">Engagement Reminder & Events Email</option>
                        <option className='text-black' value="Weekly Digest & Engagement Email">Weekly Digest & Engagement Email</option>
                        <option className='text-black' value="Platform Update Notification Email">Platform Update Notification Email</option>
                    </select>

                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder="Enter Subject"
                        required
                        className="w-full p-3 text-white bg-transparent border border-white/50 rounded-lg outline-none"
                    />

                    <textarea
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        placeholder="Enter Email Content"
                        required
                        className="w-full p-3 text-white bg-transparent border border-white/50 rounded-lg outline-none"
                    />

                    <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-[#F23985] to-[#FB9236] py-2 rounded-lg font-medium text-white shadow-md hover:opacity-90"
                    >
                        {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                        ) : (
                            "Send Email"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SendEmail;
