import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import AOS from "aos";
import axios from 'axios';
import { SERVER_URL } from '../../config';
import toast from "react-hot-toast";

AOS.init();

const VerifyEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState(true);

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    useEffect(() => {
        if (token) {
            verifyEmailToken(token);
        }
    }, [token]);

    const verifyEmailToken = async (token) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/handleEmailVerification`, { token });

            if (response.data.status === 'success') {
                toast.success("Email verified successfully!");
                navigate('/login');
            } else {
                toast.error("Error while verifying the email, Please try again later.");
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            toast.error("Error while verifying the email, Please try again later.");
        } finally {
            setLoader(false);
        }
    };


    return (
        <div>
            <div className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden"
                style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}>
                {loader && (
                    <div className='fixed z-30 inset-0 w-screen left-0 top-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20'>
                        <div className='flex items-center gap-2 text-xl font-semibold'>
                            <FontAwesomeIcon icon={faGear} className='fa-spin' />
                            <br />
                            <h1 className="text-2xl font-semibold">Verifying Email...</h1>
                            <p>Please wait while we verify your email address.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerifyEmail;
