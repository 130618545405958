import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faShareNodes, faWallet, faClockRotateLeft, faFire, faBell, faArrowRightFromBracket, faCube, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from "react-hot-toast";
import DashboardHeader from './DashboardHeader';
import Earnings from './Earnings';
import Referral from './Referral';
import Profile from './Profile';
import Notification from './Notification';


export default function Dashboard() {

    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [active, setActive] = useState('div1');
    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(5);

    const count = 5;

    console.log("cccccount", count)

    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    };


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setActiveTab(tab === activeTab ? null : tab);

        switch (tab) {
            case 'Earnings':
                handleAnchorClick('div2');
                break;
            case 'Referrals':
                handleAnchorClick('div3');
                break;
            case 'Notification':
                handleAnchorClick('div4');
                break;
            default:
                handleAnchorClick('div1');
        }
    };


    const handleAnchorClick = (tab) => {
        setActive(tab);
    };



    const iconMap = {
        'cube': faCube,
        'users': faUserGroup,
        'wallet': faWallet,
        'bell': faBell
    };

    const reloadPage = () => {
        window.location.reload();
    };


    const TabButton = ({ label, tab, icon, onClick, badge }) => {
        return (
            <button
                className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[80%] mx-auto uppercase font-bold rounded-xl py-2.5 ${tab === activeTab ? 'bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985]' : ''}`}
                onClick={onClick}
            >
                <div className='relative w-[10%]'>
                    <FontAwesomeIcon icon={icon} className='text-lg' />
                    {badge > 0 && (
                        <span className='absolute -top-1 -right-1 inline-block font-poppins w-4 h-4 text-[10px] font-bold text-white bg-red-600 rounded-full text-center'>
                            {badge}
                        </span>
                    )}
                </div>
                <span className="w-[75%] md:w-[60%] text-start">{label}</span>
            </button>
        );
    };



    return (
        <div className=' bg-cover bg-no-repeat text-white' style={{ backgroundImage: 'url("./assets/NewMBCbg.png")' }}>
            <DashboardHeader selectedTab={selectedTab} />
            <Toaster />
            <div className='p-5 md:flex gap-5 2xl:h-screen overflow-hidden overflow-y-auto'>
                <div class="hidden md:block w-[25%]">
                    <nav className='mt-[30%] '>
                        <div>
                            <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => { handleTabClick('Dashboard') }} />
                            <TabButton label='My earnings' tab='Earnings' icon={iconMap['wallet']} onClick={() => handleTabClick('Earnings')} />
                            <TabButton label='Referrals' tab='Referrals' icon={iconMap['users']} onClick={() => handleTabClick('Referrals')} />
                            <TabButton label='Notification' tab='Notification' icon={iconMap['bell']} onClick={() => handleTabClick('Notification')} badge={count} />
                        </div>
                        <div className='flex justify-center mt-32'><button className=' font-bold uppercase'>Logout</button></div>
                    </nav>
                </div>
                <div className='block md:hidden'>
                    <button onClick={handleOpenNavbar}>
                        <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                        </svg>
                    </button>
                    {isOpenNavbar && (
                        <nav className='w-[80%] z-10 py-5 absolute border rounded-3xl bg-[#872245] text-white grid content-around ' data-aos="fade-right" >
                            <div>
                                <div>
                                    <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => { handleTabClick('Dashboard') }} />
                                    <TabButton label='My earnings' tab='Earnings' icon={iconMap['wallet']} onClick={() => handleTabClick('Earnings')} />
                                    <TabButton label='Referrals' tab='Referral' icon={iconMap['users']} onClick={() => handleTabClick('Referral')} />
                                    <TabButton label='Notification' tab='Notification' icon={iconMap['bell']} onClick={() => handleTabClick('Notification')} badge={count} />
                                </div>
                                <div className='flex justify-center py-3 text-lg'><button className=' font-bold uppercase'>Logout</button></div>
                            </div>
                        </nav>
                    )}
                </div>
                <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Profile/>
                </div>
                <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%]' >
                    <Earnings />
                </div>
                <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Referral/>
                </div>
                <div id='div4' style={{ display: active === 'div4' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Notification/>
                </div>
            </div>
        </div>
    )
}