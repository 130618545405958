import { faBars, faBell, faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { setAdminEmail } from '../../store';
import toast, { Toaster } from "react-hot-toast";

export default function Header({ selectedTab }) {

    const [count, setCount] = useState(5);
    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const dispatch = useDispatch();

    const handleDropdown = () => {
        setDropdown(!isDropdown);
    }

    const handleClickOutside = (event) => {
        if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const Logout = () => {
        dispatch(setAdminEmail(''));
        sessionStorage.clear();
        // window.location = '/Admin';
    };

    return (
        <div>
            <div className='fixed left-0 top-0 right-0 backdrop-blur-sm z-10'>
                <nav className='flex justify-between items-center px-5 py-5 md:py-3'>
                    <div className='w-[50%] flex gap-7'>
                        <div className='hidden md:block w-[30%]'>
                            <a href='/'><img src='/assets/MBC_new_LG.png' className='w-[60px] mx-auto'></img></a>
                        </div>
                        <div className='flex items-center'>
                            <p className='font-bold  uppercase flex flex-col md:flex-row'>
                                <p className='flex'>
                                    <p className='text-white/50 text-sm uppercase  my-auto'>Dashboard</p>
                                    <span className='px-2 font-bold'>/</span>
                                </p>
                                <p className='uppercase text-sm md:text-base font-bold'>{selectedTab}</p>
                            </p>
                        </div>
                    </div>
                    <div className='w-[50%] md:w-[35%] flex justify-end items-center lg:pr-10'>
                        <div className='hidden md:block'>
                            <div className='flex gap-3'>
                                {/* <p className='uppercase  font-bold text-lg flex items-center text-nowrap'>Balance :
                                <span className='rounded-full border border-[#E43875] font-poppins text-base px-3 py-1 ml-2'>612187.6794</span></p> */}
                                <div className='pr-3'>
                                    <w3m-button balance='hide' />
                                </div>
                                {/* <button className='relative w-10 h-10 rounded-full border border-[#E43875]'>
                                <FontAwesomeIcon icon={faBell} />
                                {count > 0 && (
                                    <span className='absolute -top-2 -right-2 bg-black text-white rounded-full border border-[#E43875]  w-5 h-5 flex items-center justify-center text-xs'>
                                        {count}
                                    </span>
                                )}
                            </button> */}
                                <button className='relative w-10 h-10 rounded-full border border-[#E43875]' onClick={handleDropdown} ref={buttonRef}>
                                    <FontAwesomeIcon icon={faUser} />
                                </button>
                                {isDropdown && (
                                    <div className='absolute font-poppins font-medium top-20 right-10 text-sm grid gap-2 w-[10rem] rounded-md px-3 py-2 border border-white/50 bg-[#571226]'
                                        data-aos='fade-up' data-aos-duration='1000'
                                        ref={dropdownRef}
                                        onClick={Logout}
                                    >
                                        <button>Logout</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='block md:hidden'>
                            <div>
                                <p className='uppercase  font-bold text-sm text-center flex flex-col'>
                                    <span>Balance</span>
                                    <span className='rounded-full border border-[#E43875] font-poppins text-base px-3 py-1 ml-2'>612187.6794</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

    )
}