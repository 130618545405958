import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import UserVisual from './userVisual';

export default function UsersList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [usersList, setUsersList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [viewUserID, setViewUserID] = useState(null); 

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getUsersList`);
            if (response.data.status === true) {
                setUsersList(response.data.data);
            } else {
                setUsersList([]);
            }
        } catch (error) {
            console.error("Error fetching Users List:", error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    };

    const filteredUsers = usersList.filter((data) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            data.CusID?.toString().toLowerCase().includes(searchLower) ||
            data.email?.toLowerCase().includes(searchLower) ||
            data.username?.toLowerCase().includes(searchLower) ||
            data.role?.toLowerCase().includes(searchLower) ||
            formatDate(data.createdAt)?.toLowerCase().includes(searchLower)
        );
    });

    const totalPages = Math.ceil(filteredUsers.length / entitiesPerPage);
    const currentEntities = filteredUsers.slice(
        (currentPage - 1) * entitiesPerPage,
        currentPage * entitiesPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        const newEntitiesPerPage = parseInt(e.target.value);
        setEntitiesPerPage(newEntitiesPerPage);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    if (viewUserID) {
        return (
            <div className="mx-auto font-poppins">
                <button
                    onClick={() => setViewUserID(null)}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md mt-20 ml-12"
                >
                    Back
                </button>
                <UserVisual CusID={viewUserID} />
            </div>
        );
    }

    return (
        <div className="mx-auto font-poppins">
            <div data-aos="fadeIn" data-aos-duration="2000">
                <div className="p-3 md:p-7 mt-5 font-poppins">
                    <h1 className="text-2xl font-bold uppercase ">USER MANAGEMENT</h1>
                    <div className="rounded-lg mt-5 border">
                        <div className="px-5 py-3 border-b font-bold">
                            <p>Filter Your Search</p>
                        </div>
                        <div className="p-3 grid flex-col lg:grid-cols-5 gap-3 lg:gap-10">
                            <div className="text-sm font-bold m-auto">
                                Total Record : {filteredUsers.length}
                            </div>
                            <div className="flex gap-2 items-center justify-center lg:col-span-3">
                                <p className="text-sm font-bold">Search:</p>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search anything..."
                                    className="pl-3 border bg-transparent rounded-md py-1 w-full lg:w-[50%]"
                                />
                            </div>
                            <div className="text-sm font-bold m-auto">
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5 border pb-3">
                        <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b">
                            <div>
                                <p className="font-bold">Showing All Users</p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-end text-sm px-3 my-auto">Show number of entities
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={handleDropdownChange}
                                        value={entitiesPerPage}
                                    >
                                        <option className="text-black" value="5">5</option>
                                        <option className="text-black" value="10">10</option>
                                        <option className="text-black" value="50">50</option>
                                        <option className="text-black" value="100">100</option>
                                    </select>
                                </p>
                            </div>
                        </div>

                        <div className="mb-5 overflow-hidden hover:overflow-x-auto">
                            <table className="w-full text-center text-[15px]">
                                <thead className="font-bold border-b py-3 text-nowrap">
                                    <tr>
                                        <th className="py-3 px-2">ID</th>
                                        <th className="px-2">Cus ID</th>
                                        <th className="px-2">Email</th>
                                        <th className="px-2">Username</th>
                                        <th className="px-2">Country</th>
                                        <th className="px-2">Created At</th>
                                        <th className="px-2">View Tree</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {currentEntities.map((data, index) => (
                                        <tr className="h-16 text-center rounded-md font-bold" key={data.CusID}>
                                            <td className="px-2">
                                                {(currentPage - 1) * entitiesPerPage + index + 1}
                                            </td>
                                            <td className="px-2">{data.CusID}</td>
                                            <td className="px-2">{data.email}</td>
                                            <td className="px-2">{data.username}</td>
                                            <td className="px-2">{data.country}</td>
                                            <td className="px-2">{formatDate(data.createdAt)}</td>
                                            <td className="px-2">
                                                <button
                                                    onClick={() => setViewUserID(data.CusID)}
                                                    className="bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-white px-4 py-2 rounded-md"
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-center gap-5 mt-5 px-5">
                                <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <div>
                                    {pageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => handlePageClick(number)}
                                            className={`px-3 py-1 ${number === currentPage ? 'border border-white rounded-lg' : ''}`}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                                <button onClick={handleNextClick} disabled={currentPage === totalPages}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}








