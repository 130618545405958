import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

export default function Searchuser() {
  // const tableEntries = [
  //   {
  //     id: "1",
  //     ref: "DTL",
  //     upline: "UP",
  //     downline: "DN",
  //     serialno: "341",
  //     custID: "503418",
  //     password: "9KzqA0M/9LBRoRWRMl8qjhc/heWFv4yxHwTMEnfx0U8=",
  //     username: "Cako",
  //     custname: "Firat Üstel",
  //     SponsorID: "354713",
  //     registrationdate: "11/Sep/2024",
  //     activedate: "04/Sep/2024",
  //     lastactive: "1000",
  //   },
  // ];
const [tableEntries,settableEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  // Determine the current entries to display
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  // Handle previous and next page clicks
  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle dropdown change for entities per page
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const openImageInNewTab = (entity) => {
    window.open(entity.doc, '_blank');
  };

  // Calculate pagination details
  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [id, setId] = useState('');
  const [username, setUsername] = useState('');
  const [packageStatus, setPackageStatus] = useState('Active'); // Default option
  const [custName, setCustName] = useState('');
  const handleSubmitClick = async () => {
    const Datasubmit = {
      id: id, 
    username: username,
    Cust_Name   :packageStatus,
  Cust_Package   :custName,
    };  
    console.log(Datasubmit);    
    try {
      const response = await axios.get('http://localhost:3006/api/getsearchMember',Datasubmit);

      console.log("data1", response.data);
      settableEntries(response.data.data);
      
      console.log("data", response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='p-3 md:p-7 mt-5 font-poppins'>
        <h1 className='text-2xl font-bold uppercase '>Search Member
        </h1>
        <div className=' rounded-lg mt-5 border '>
          <div className='px-5 py-3 border-b'>
            <p className='my-auto font-bold text-lg'>Search Member Records
            </p>
          </div>
          <div className='p-5 border-b flex flex-col md:flex-row  gap-3 md:gap-5 lg:gap-0 justify-evenly'>
            <div className='lg:w-[40%] flex flex-col gap-3'>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] font-bold text-end'     onChange={(e) => setId(e.target.value)} >S No.</div>
                <div className='md:w-[70%]'><input className='w-[100%] py-2 outline-none border rounded-md bg-transparent px-5'></input></div>
              </div>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] font-bold text-end'     onChange={(e) => setUsername(e.target.value)} >Cust UName</div>
                <div className='md:w-[70%]'><input className='w-[100%] py-2 outline-none border rounded-md bg-transparent px-5'></input></div>
              </div>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] font-bold text-end'     onChange={(e) => setPackageStatus(e.target.value)} >Package</div>
                <div className='w-[100%] md:w-[70%]'>
                  <select className='w-[100%] py-2 text-center font-bold outline-none border rounded-md bg-transparent'>
                    <option className='text-black'>Active</option>
                    <option className='text-black'>Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='lg:w-[40%] flex flex-col gap-3'>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] text-end  font-bold'>CusID</div>
                <div className='md:w-[70%]'><input className='w-[100%] py-2 outline-none border rounded-md bg-transparent px-5'></input></div>
              </div>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] text-end font-bold'     onChange={(e) => setCustName(e.target.value)} >Cust Name</div>
                <div className='md:w-[70%]'><input className='w-[100%] py-2 outline-none border rounded-md bg-transparent px-5'></input></div>
              </div>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] text-end hidden md:block'></div>
                <div className='md:w-[70%]'><input className='w-[100%] py-2 outline-none border rounded-md bg-transparent px-5'></input></div>
              </div>
            </div>
          </div>
          <div className='p-5 flex flex-col md:flex-row  gap-3 md:gap-0 justify-evenly'>
            <div className='md:w-[50%] lg:w-[40%] flex flex-col gap-3'>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] font-bold text-end'>Reg Date</div>
                <div className='md:w-[70%]'>
                  <DatePicker
                    selected={selectedFrom}
                    onChange={(date) => setSelectedFrom(date)}
                    className="w-[100%] py-2 outline-none border rounded-md bg-transparent px-5"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row  gap-3 md:gap-5 items-center'>
                <div className='md:w-[30%] font-bold text-end hidden md:block'></div>
                <div className='md:w-[70%]'>
                  <DatePicker
                    selected={selectedTo}
                    onChange={(date) => setSelectedTo(date)}
                    className="w-[100%] py-2 outline-none border rounded-md bg-transparent px-5"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
            </div>
            <div className='md:w-[50%] lg:w-[40%] flex justify-center items-center'>
              <button className='px-10 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handleSubmitClick }>Submit</button>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className=' rounded-lg mt-5 border '>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
            <div className=''>
              <p className='my-auto font-bold text-lg'>Searched Members List
              </p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='p-3 grid md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-10'>
            <div className=' text-sm font-bold m-auto'>
              Total Record : 4
            </div>
            <div className='hidden md:block'></div>
            <div className='flex gap-1 m-auto items-center justify-center'>
              <p className='text-sm font-bold text-nowrap w-[60%]'>Records Per Page :</p>
              <div className='w-[25%] md:w-[40%]'>
                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
              </div>
            </div>
            <div className=' text-sm font-bold m-auto'>
              Page of 1 of 0
            </div>
          </div>
          <div className=' rounded-lg border-y pb-3'>
            <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
              <table className='w-full text-center'>
                <thead className='text-[13px] uppercase font-bold border-b py-3'>
                  <tr>
                    <th className='py-3 px-2'>#</th>
                    <th className='px-2'>Ref</th>
                    <th className='px-2'>U</th>
                    <th className='px-2'>D</th>
                    <th className='px-2'>Login</th>
                    <th className='px-2'>SerialNo</th>
                    <th className='px-2'>Cust ID</th>
                    <th className='px-2'>Password</th>
                    <th className='px-2'>User Name</th>
                    <th className='px-2'>Cust Name</th>
                    <th className='px-2'>Sponsor ID</th>
                    <th className='px-2'>Registration Date</th>
                    <th className='px-2'>Active Date</th>
                    <th className='px-2'>Last Active Package</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                      <td className='px-5 md:px-2'>{entity.id}</td>
                      <td className='px-5 md:px-2'>{entity.ref}</td>
                      <td className='px-5 md:px-2'>{entity.upline}</td>
                      <td className='px-5 md:px-2'>{entity.downline}</td>
                      <td className='px-5 md:px-2'><button className='bg-blue-500 rounded-md px-3 py-2 text-[13px] font-bold'>Login</button></td>
                      <td className='px-5 md:px-2'>{entity.serialno}</td>
                      <td className='px-5 md:px-2'>{entity.CusID}</td>
                      <td className='px-5 md:px-2'>
                        {entity.password.length > 15
                          ? `${entity.password.substring(0, 15)}...`
                          : entity.password}
                      </td>
                      <td className='px-5 md:px-2'>{entity.username}</td>
                      <td className='px-5 md:px-2'>{entity.Cust_Name}</td>
                      <td className='px-5 md:px-2'>{entity.sponsorid}</td>
                      <td className='px-5 md:px-2'>{entity.Entry_Date}</td>
                      <td className='px-5 md:px-2'>{entity.activedate}</td>
                      <td className='px-5 md:px-2'>{entity.lastactive}</td>
                    </tr>
                  ))}
                  {/* Add empty rows if needed */}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}