import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

export default function Apexchat() {
    useEffect(() => {
        const options = {
            series: [680, 410, 280],
            chart: {
                width: 350,
                type: 'pie',
            },
            labels: ['Total Earnings', 'Referral Earnings', 'Purchased Earnings'],
            fill: {
                opacity: 1,
                colors: ['#ff0000', '#00ff00', '#3357ff'],
            },
            stroke: {
                show: false,
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'], 
                },
            },
            legend: {
                labels: {
                    colors: ['#fff'], 
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300,
                    },
                    legend: {
                        position: 'bottom',
                        labels: {
                            colors: ['#fff'],
                        },
                    },
                },
            }],
        };

        const chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <div>
            <div id="chart"></div>
        </div>
    );
}
