import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";

export default function WithdrawHistory() {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [withdrawList, setWithdrawList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchWithdrawHistory = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getWithdrawHistory`);
            if (response.data.status === true) {
                setWithdrawList(response.data.data);
            } else {
                setWithdrawList([]);
            }
        } catch (error) {
            console.error("Error fetching Withdraw History:", error);
        }
    };

    useEffect(() => {
        fetchWithdrawHistory();
    }, []);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    };

    const filteredWithdraws = withdrawList.filter((data) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            data.CusID?.toString().toLowerCase().includes(searchLower) ||
            data.transaction_hash?.toLowerCase().includes(searchLower) ||
            data.sender_address?.toLowerCase().includes(searchLower) ||
            data.receiver_address?.toLowerCase().includes(searchLower) ||
            data.currency?.toLowerCase().includes(searchLower) ||
            formatDate(data.created_at)?.toLowerCase().includes(searchLower)
        );
    });

    const totalPages = Math.ceil(filteredWithdraws.length / entitiesPerPage);
    const currentEntities = filteredWithdraws.slice(
        (currentPage - 1) * entitiesPerPage,
        currentPage * entitiesPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        const newEntitiesPerPage = parseInt(e.target.value);
        setEntitiesPerPage(newEntitiesPerPage);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const openTransactionHash = (hash) => {
        if (hash) {
            window.open(`https://bscscan.com/tx/${hash}`, '_blank');
        }
    };

    return (
        <div className="mx-auto font-poppins">
            <div data-aos="fadeIn" data-aos-duration="2000">
                <div className="p-3 md:p-7 mt-5 font-poppins">
                    <h1 className="text-2xl font-bold uppercase ">WITHDRAW HISTORY</h1>
                    <div className="rounded-lg mt-5 border">
                        <div className="px-5 py-3 border-b font-bold">
                            <p>Filter Your Search</p>
                        </div>
                        <div className="p-3 grid flex-col lg:grid-cols-5 gap-3 lg:gap-10">
                            <div className="text-sm font-bold m-auto">
                                Total Record : {filteredWithdraws.length}
                            </div>
                            <div className="flex gap-2 items-center justify-center lg:col-span-3">
                                <p className="text-sm font-bold">Search:</p>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search anything..."
                                    className="pl-3 border bg-transparent rounded-md py-1 w-full lg:w-[50%]"
                                />
                            </div>
                            <div className="text-sm font-bold m-auto">
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5 border pb-3">
                        <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b">
                            <div>
                                <p className="font-bold">Showing All Withdrawals</p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-end text-sm px-3 my-auto">Show number of entities
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={handleDropdownChange}
                                        value={entitiesPerPage}
                                    >
                                        <option className="text-black" value="5">5</option>
                                        <option className="text-black" value="10">10</option>
                                        <option className="text-black" value="50">50</option>
                                        <option className="text-black" value="100">100</option>
                                    </select>
                                </p>
                            </div>
                        </div>

                        <div className="mb-5 overflow-hidden hover:overflow-x-auto">
                            <table className="w-full text-center text-[15px]">
                                <thead className="uppercase font-bold border-b py-3 text-nowrap">
                                    <tr>
                                        <th className="py-3 px-2">ID</th>
                                        <th className="px-2">CusID</th>
                                        <th className="px-2">Wallet Address</th>
                                        <th className="px-2">Currency</th>
                                        <th className="px-2">Amount</th>
                                        <th className="px-2">Status</th>
                                        <th className="px-2">Created At</th>
                                        <th className="px-2">Transaction Hash</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {currentEntities.map((data, index) => (
                                        <tr className="h-16 text-center rounded-md font-bold" key={index}>
                                            <td className="px-2">
                                                {(currentPage - 1) * entitiesPerPage + index + 1}
                                            </td>
                                            <td className="px-2">{data.CusID}</td>
                                            <td className="px-2 truncate max-w-[30px] hover:overflow-visible relative group">
                                                <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {data.sender_address}
                                                </div>
                                                <div className="absolute left-0 top-0 bg-gray-800 text-white text-sm rounded p-2 opacity-0 group-hover:opacity-100 z-10 w-max max-w-[400px]">
                                                    {data.sender_address}
                                                </div>
                                            </td>
                                            <td className="px-2">{data.currency}</td>
                                            <td className="px-2">{Number(data.amount).toFixed(3)}</td>
                                            <td className="px-2">{data.status}</td>
                                            <td className="px-2">{formatDate(data.created_at)}</td>
                                            <td className="px-2">
                                                <button
                                                    className="bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-white px-4 py-2 rounded-md"
                                                    onClick={() => openTransactionHash(data.transaction_hash)}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-center gap-5 mt-5 px-5">
                                <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <div>
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handlePageClick(index + 1)}
                                            className={`px-3 py-1 ${index + 1 === currentPage ? 'border border-white rounded-lg' : ''}`}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                                <button onClick={handleNextClick} disabled={currentPage === totalPages}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
