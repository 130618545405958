import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css'

Aos.init();

const About = () => {

  const navigate = useNavigate();

  const handleInetrest = () => {
    navigate('/Membership', { state: { scrollToSection: 'sec-3' } });
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className=" absolute z-10 top-1/2 transform -translate-y-1/2 -left-5 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
    >
      <div className="flex justify-center items-center w-full h-full">
        <FontAwesomeIcon icon={faArrowLeft} className="text-white font-bold text-xl" />
      </div>
    </div>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className=" absolute top-1/2 transform -translate-y-1/2 -right-5 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736]"
    >
      <div className="flex justify-center items-center w-full h-full">
        <FontAwesomeIcon icon={faArrowRight} className="text-white font-bold text-xl" />
      </div>
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div>
      <div className="fixed inset-0 z-0 ">
        <img src="assets/NewMBCbg.png" className="h-full w-full object-cover object-top"></img>
      </div>
      <div className="relative z-10 text-white">
        <Header />
        <div className="sec-1 overflow-hidden">
          <div className="w-[85%] pt-20 md:pt-32 pb-10 mt-5 mx-auto flex justify-center items-center min-h-[80vh]">
            <div className="flex lg:flex-row flex-col items-center lg:gap-0 gap-10">
              <div className="lg:w-[60%]" data-aos="fade-right">
                <div className="flex justify-start">
                  <img src="../assets/aboutusimg.png" className="w-6/12" />
                  <div className="flex flex-col items-center gap-10">
                    <img src="../assets/image.png" className="w-7/12" />
                    <div className="bg-white/60 py-7 px-5 rounded-2xl border flex justify-center items-center w-[70%] mx-auto">
                      <img src="../assets/Icon (2).png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[40%] mx-auto">
                <div className="pb-2 border-b-2">
                  <p className='font-bold  text-3xl md:text-4xl lg:text-5xl capitalize text-shadow-custom' data-aos="fade-left" >Who We Are</p>
                </div>
                <div className="py-5">
                  <p className="text-xl text-shadow-custom font-semibold leading-10" data-aos="zoom-in" data-aos-duration="1000">The Elite Network for Visionary Entrepreneurs</p>
                </div>
                <p className="2xl:text-lg" data-aos="zoom-in" data-aos-duration="1250">My Business Club (MBC) is a private membership platform designed to empower entrepreneurs. we’ve built a platform that rewards your efforts <strong>instantly.</strong> With <strong>Smart Contract Pay,</strong> every sale and connection you make pays out <strong>immediately.</strong> No delays, no middlemen, just <strong>fast, secure income</strong> flowing directly to your wallet.<br /><br />
                  Members enjoy global profit sharing instant payouts with smart contracts , access to exclusive educational content, podcasts, and travel perks that grow with your membership level. Join a community where you can build faster, earn smarter, and access resources to help you scale your business globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="sec-2 min-h-screen md:min-h-[80vh] font-poppins text-white pt-10">
          <div className="2xl:w-[80%] w-[90%] pt-5 pb-10 mx-auto  flex justify-center items-center">
            <div>
              <div className="">
                <p className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase " data-aos="fade-up" data-aos-duration="1000">Ask our happy customers about us.</p>
              </div>
              <div className="w-[60%] rounded-lg mt-1 mb-5 h-2 bg-gradient-to-r from-[#F23985] to-[#FB9236]" data-aos="fade-right" data-aos-duration="1500">
              </div>
              <div className="py-5 md:w-[85%]">
                <p className="font-semibold" data-aos="fade-up" data-aos-duration="1250">To be one of the pioneering, dynamic and leading companies that offer quality products and services with an understanding of continuous improvement in the fields in which it operates.</p>
              </div>

              <div className="mt-5">
                <div className="flex flex-wrap justify-center gap-3 " data-aos="fade-up" data-aos-duration="1500">
                  <img src="../assets/Ellipse_8.png" className="w-20" />
                  <img src="../assets/Ellipse_9.png" className="w-20" />
                  <img src="../assets/Ellipse_10.png" className="w-20" />
                  <img src="../assets/Ellipse_11.png" className="w-20" />
                  <img src="../assets/Ellipse_12.png" className="w-20" />
                  <img src="../assets/Ellipse_13.png" className="w-20" />
                  <img src="../assets/Ellipse_14.png" className="w-20" />
                  <img src="../assets/Ellipse_15.png" className="w-20" />
                  <img src="../assets/Ellipse_16.png" className="w-20" />
                  <img src="../assets/Ellipse_17.png" className="w-20" />
                  <img src="../assets/Ellipse_18.png" className="w-20" />
                  <img src="../assets/Ellipse_19.png" className="w-20" />
                  <img src="../assets/Ellipse_26.png" className="w-20" />
                  <img src="../assets/Ellipse_21.png" className="w-20" />
                  <img src="../assets/Ellipse_27.png" className="w-20" />
                  <img src="../assets/Ellipse_22.png" className="w-20" />
                  <img src="../assets/Ellipse_28.png" className="w-20" />
                  <img src="../assets/Ellipse_23.png" className="w-20" />
                  <img src="../assets/Ellipse_29.png" className="w-20" />
                  <img src="../assets/Ellipse_24.png" className="w-20" />
                  <img src="../assets/Ellipse_30.png" className="w-20" />
                  <div className="w-20 h-20 rounded-full bg-white p-1 cursor-pointer" onClick={handleInetrest}>
                    <div className=" bg-gradient-to-b from-[#E43875] to-[#F98736] h-full w-full rounded-full flex justify-center items-center ">
                      <p className="font-semibold text-xs text-center">Join Club Now</p>
                    </div>
                  </div>

                </div>
              </div>
              <div>

              </div>

            </div>

          </div>
        </div>
        */}
        <div className="sec-3  min-h-screen md:min-h-[80vh] font-poppins text-white pt-10 overflow-hidden">
          <div className="w-[85%] pb-10 mx-auto flex flex-col justify-center items-center min-h-screen">
            <div className="w-full flex justify-center items-center gap-10">
              <div className="w-[50%] flex" data-aos="fade-right" data-aos-duration="1000">
                <div className="w-[98%] h-1 bg-gradient-to-r from-[#E43875] to-[#F98736] "></div>
                <div className="w-5 h-5 -translate-x-2 -translate-y-2 rounded-full bg-[#F98736] p-1">
                  <div className="bg-white rounded-full w-full h-full"></div>
                </div>
              </div>
              <div>
                <p className="text-2xl font-bold text-center" data-aos="zoom-out" data-aos-duration="1000" style={{ textShadow: "0px 1px 1px #000000" }}>Testimonials that
                  Speaks to Our Results</p>
              </div>
              <div className="w-[50%] flex" data-aos="fade-left" data-aos-duration="1000">
                <div className="w-5 h-5 translate-x-2 -translate-y-2 rounded-full bg-[#F98736] p-1">
                  <div className="bg-white rounded-full w-full h-full"></div>
                </div>
                <div className="w-[98%] h-1 bg-gradient-to-r from-[#E43875] to-[#F98736] "></div>
              </div>



            </div>
            <div className="w-[90%] mx-auto mt-10 relative" data-aos="fade-up" data-aos-duration="1000">
              <Slider className='' {...settings} >
                <div className="px-1 lg:px-3">
                  <div className='bg-[#F1F1F1] h-[465px] 2xl:h-auto rounded-2xl backdrop-blur-sm w-[100%] py-5 px-5 md:px-7'>
                    <div className="grid content-between h-full text-black">
                      <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-purple-500"></div></div>
                      <p className="mt-3">“Being part of My Business Club feels like joining a community that truly has your back. Beyond the amazing learning and investment opportunities, the referral earning system has been a fantastic way to earn extra income. I've been able to share the benefits of MBC with my network and see real rewards come back to me. It’s more than just a club—it's a place where your success is celebrated and shared.”</p>
                      <div className="flex gap-5 justify-center items-center">
                        <div className="flex gap-2 mt-5">
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                        </div>
                        <div className="mt-6">
                          <p className="font-semibold text-sm">5.0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-1 lg:px-3">
                  <div className='bg-[#F1F1F1] h-[465px] 2xl:h-auto rounded-2xl backdrop-blur-sm w-[100%] py-5 px-5 md:px-7'>
                    <div className="grid content-between h-full text-black">
                      <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-red-500"></div></div>
                      <p className="mt-3">“When I first joined MBC, I was drawn in by the unique opportunities, but what really impressed me was the referral program. Not only have I grown personally and professionally through the resources and support offered, but I’ve also earned extra
                        income by referring others to the club. It's a win-win situation that has made me even more invested in the community and my own success.”</p>
                      <div className="flex gap-5 justify-center items-center">
                        <div className="flex gap-2 mt-5">
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                        </div>
                        <div className="mt-6">
                          <p className="font-semibold text-sm">4.0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-1 lg:px-3">
                  <div className='bg-[#F1F1F1] h-[465px] 2xl:h-auto rounded-2xl backdrop-blur-sm w-[100%] py-5 px-5 md:px-7'>
                    <div className="grid content-between h-full text-black">
                      <div className="flex justify-end w-full"><div className="w-3 h-3 rounded-full bg-yellow-500"></div></div>
                      <p className="mt-3">"At first, I wasn’t sure what to expect, but it’s been one of the best decisions I’ve ever made. The resources and support I’ve received have not only improved my financial situation but also my mindset. The way the club integrates technology and education is incredible, and I feel more secure and informed in my business decisions than ever before. MBC has truly made a difference in my life."</p>
                      <div className="flex gap-5 justify-center items-center">
                        <div className="flex gap-2 mt-5">
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                          <FontAwesomeIcon icon={faStarHalf} className="text-yellow-500" />
                        </div>
                        <div className="mt-6">
                          <p className="font-semibold text-sm">4.5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>


          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
