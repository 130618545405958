import { faCalendarDays, faChevronLeft, faChevronRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Apexchat from './Apexchat'

export default function Profile() {
    return (
        <div className='md:pl-5 lg:p-0'>
            <div className='flex flex-col lg:flex-row gap-5'>
                <div className='lg:w-[65%] border rounded-md p-5'>
                    <p className='text-end'><FontAwesomeIcon icon={faCalendarDays} /></p>
                    <div className='flex flex-col lg:flex-row justify-between'>
                        <p className=' font-bold text-xl lg:text-2xl lg:w-[20%] my-auto'>My Earnings</p>
                        <div className='lg:w-[75%]'>
                            <Apexchat />
                        </div>
                    </div>
                </div>

                <div className='lg:w-[35%] p-5 grid content-center rounded-md bg-gradient-to-b from-[#E43875] to-[#F98736]'>
                    <div className='flex justify-between'>
                        <p className=' uppercase font-bold my-auto'>Profile</p>
                        <button><FontAwesomeIcon icon={faPenToSquare} className='text-black p-2 rounded-full bg-white' /></button>
                    </div>
                    <div className='flex gap-5 mt-3'>
                        <div className='flex items-center justify-center'>
                            <div>
                                <img src='./assets/ProfilePicture.png'></img>
                                <p className='text-center text-sm mt-2'>Level 5</p>
                            </div>
                        </div>
                        <div>
                            <p className=' uppercase font-bold my-auto'>SUSHWANTH SAHO</p>
                            <p className='text-sm'>Premium subscription</p>
                            <p className='text-sm'>Joined 07 Dec</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex flex-col lg:flex-row gap-5 mt-5'>
                <div className='lg:w-[65%] border rounded-md p-5'>
                    <div className='flex flex-col md:flex-row justify-between'>
                        <p className=' font-bold text-xl my-auto'>Featured Exclusives</p>
                        <div className='flex gap-2 items-center'>
                            <select className='bg-transparent outline-none border-2 px-3 py-1 rounded-lg'>
                                <option value="1" className='text-black'>Popular</option>
                                <option value="2" className='text-black'>Unknown</option>
                                <option value="3" className='text-black'>No-name</option>
                            </select>
                            <FontAwesomeIcon icon={faChevronLeft} className='rounded-lg px-2 py-1 border-2' />
                            <FontAwesomeIcon icon={faChevronRight} className='rounded-lg px-2 py-1 border-2' />

                        </div>
                    </div>
                    <div className='mt-10 flex flex-col md:flex-row gap-5'>
                        <div className='md:w-[50%] flex flex-col text-center'>
                            <img src='/assets/Rectangle12.png' className='mx-auto'></img>
                            <p className=' font-bold text-lg mt-2 mb-1'>Ainstein</p>
                            <p>Move to Earn tokens.</p>
                        </div>
                        <div className='md:w-[50%] flex flex-col text-center'>
                            <img src='/assets/Rectangle12.png' className='mx-auto'></img>
                            <p className=' font-bold text-lg mt-2 mb-1'>Skilleareum </p>
                            <p>Own B2B Containers Min. $1 Million</p>
                        </div>
                    </div>
                    <p className='mt-10'>NOTE:  More of a exclusive offerings are coming soon.</p>
                </div>
                <div className='lg:w-[35%] p-5 rounded-md bg-[#FFFFFF1C]'>
                    <p className='font-bold  text-center'>Activity & Achievements </p>
                    <div className='mt-3 flex flex-col gap-2'>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Purachsed membership.</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>New Level Unlocked!</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Registered for podcast. </p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Subscription Renewed.</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Purachsed membership.</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>New Level Unlocked!</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Registered for podcast. </p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Subscription Renewed.</p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Registered for Zoom call. </p>
                        </div>
                        <div className='flex gap-3 font-poppins font-bold'>
                            <img src='/assets/Ellipse 283.png' className='my-auto'></img>
                            <p className='my-auto'>Unlocked New Level. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
