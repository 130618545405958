import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Notification = () => {
  const notifications = [
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
    { img: '../assets/Ellipse 283.png', description: 'Anup kumar Registered in skilleareum.ai on your referral.' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 5;

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(notifications.length / notificationsPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className='w-[95%] mx-auto pt-10'>
        <div className='bg-white/10 border rounded-lg sm:p-10 p-5'>
          <div className='flex justify-between items-center'>
            <p className='text-lg  uppercase'>Notifications</p>
            <div>
              <select className='border rounded-md text-xs p-1 bg-transparent focus:outline-none'>
                <option className='text-xs text-black'>Filter</option>
                <option className='text-xs text-black'>Filter</option>
                <option className='text-xs text-black'>Filter</option>
              </select>
            </div>
          </div>
          <div className='mt-10'>
            {currentNotifications.map((data, index) => (
              <div key={index} className='mt-3'>
                <div className='border rounded-md p-2'>
                  <div className='flex items-center gap-3'>
                    <img src={data.img} className='w-10' />
                    <p className='text-sm'>{data.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='flex justify-center mt-5 gap-2'>
            <button
              onClick={prevPage}
              className='text-white'
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={`px-3 py-1 rounded-md ${currentPage === number ? 'bg-[#F23985] text-white' : 'bg-white text-black'}`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={nextPage}
              className='text-white'
              disabled={currentPage === pageNumbers.length}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
