import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store";
import {
    useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import axios from "axios";
import { SERVER_URL } from '../../config';
import toast, { Toaster } from "react-hot-toast";


export default function Header({ selectedTab }) {
    const dispatch = useDispatch();
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const user = useSelector((state) => state.user);
    const name = user[0].name;
    const email = user[0].email;
    const CusID = user[0].CusID;

    const initial = email ? email.charAt(0).toUpperCase() : '';

    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleDropdown = () => {
        setDropdown(!isDropdown);
    }

    const handleClickOutside = (event) => {
        if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const handleLogout = async () => {
        dispatch(setUser(""));
        window.location = '/';
    }


    const getUserAddress = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getUserAddress?CusID=${user[0].CusID}`);
            if (response.data.status === true) {
                validateAddress(response.data.addressResult[0].income_wallet, response.data.addressResult[0].original_wallet);
            }
        } catch (error) {
            console.error("Error while fetching the user address : ", error)
        }
    }

    const validateAddress = async (income, original) => {
        try {
            if (original === null && income === null) {
                window.location = '/';
            }
            if (original === null && income !== null) {
                window.location = '/';
            }
            if (original !== null && income !== null && original !== income) {
                window.location = '/';
            }
        } catch (error) {
            console.error("Error while Checking the user address : ", error)
        }
    }


    useEffect(() => {
        getUserAddress();
    }, [CusID])

    return (
        <div>
            <Toaster position='top-center' />
            <div className='fixed left-0 top-0 right-0 backdrop-blur-sm z-10'>

                <nav className='flex justify-between px-5 py-5 md:py-3'>
                    <div className='w-[50%] flex gap-7'>
                        <div className='hidden md:block w-[30%]'>
                            <a href='/'><img src='/assets/MBC_new_LG.png' className='w-[60px] mx-auto'></img></a>
                        </div>
                        <div className='flex items-center'>
                            <p className='font-bold  uppercase flex flex-col md:flex-row'><p className='flex'><p className='text-white/50 text-sm uppercase  my-auto'>Dashboard</p><span className='px-2 font-bold'>/</span></p><p className='uppercase text-sm md:text-base font-bold'>{selectedTab}</p></p>
                        </div>
                    </div>
                    <div className='w-[20%] flex justify-end items-center md:pr-10'>

                        <div className='pr-3'>
                            <w3m-button className='text-transparent bg-clip-text bg-gradient-to-r from-[#51CBFF] to-[ #0E2DA7]' />
                        </div>
                        <button className='flex' >
                            <div className='h-10 w-10 flex justify-center items-center font-bold text-xl border rounded-full font-poppins'>{initial}</div>
                            <div className='my-auto lg:pl-2 hidden lg:block'><button className=''>{name}</button>
                            </div>
                        </button>
                    </div>
                    {/* {isDropdown && (
                    <div className='absolute font-poppins font-medium top-20 right-10 text-sm grid gap-2 w-[10rem] rounded-md px-3 py-2 border border-white/50 bg-[#571226]'
                        data-aos='fade-up' data-aos-duration='1000'
                        ref={dropdownRef}>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                )} */}
                </nav>
            </div>
        </div>
    )
}