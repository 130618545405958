import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SERVER_URL } from '../../../config';


export default function DescribeWallet() {
    const [tableEntries, setTableEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [selectedFrom, setSelectedFrom] = useState(null);
    const [selectedTo, setSelectedTo] = useState(null);
    const [walletType, setWalletType] = useState('DEPOSIT');

    const handleEntryChange = (e) => {
        let value = e.target.value;  // Get the input value as a string
        let number = parseInt(value);  // Try parsing it to an integer

        // Ensure the input is valid before updating
        if (isNaN(number)) {
            setEntitiesPerPage(5);  // If not a valid number, clear the input
        } else if (number < 5) {
            setEntitiesPerPage(5);  // Ensure the minimum value is 5
        } else {
            setEntitiesPerPage(number);  // Set the entered value if it's valid
        }

        // Reset to the first page
        setCurrentPage(1);
    }


    useEffect(() => {
        const fetchWalletHistory = async () => {
            try {
                const params = {
                    fromDate: selectedFrom ? selectedFrom.toISOString() : undefined,
                    toDate: selectedTo ? selectedTo.toISOString() : undefined,
                    walletType
                };
                const response = await axios.get(`${SERVER_URL}/api/describeWallets`, { params });
                setTableEntries(response.data);
            } catch (error) {
                console.error('Error fetching wallet history:', error);
            }
        };

        fetchWalletHistory();
    }, [selectedFrom, selectedTo, walletType]);

    const totalRecords = tableEntries.length;
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(totalRecords / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalRecords / entitiesPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Describe Wallet Detail</h1>
                <div className='rounded-lg mt-5 border'>
                    <div className='px-5 py-3 border-b font-bold text-lg'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-6 gap-3 lg:gap-5'>
                        <div className='text-sm font-bold m-auto'>
                            Total Record: {totalRecords}
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center'>
                            <p className='text-sm font-bold'>Select Wallet: </p>
                            <select
                                className='bg-transparent border p-1 md:px-3 md:py-1 rounded-md outline-none'
                                value={walletType}
                                onChange={(e) => setWalletType(e.target.value)}
                            >
                                <option className='text-black'>DEPOSIT</option>
                                <option className='text-black'>WITHDRAW</option>
                            </select>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center'>
                            <p className='text-sm font-bold'>Date</p>
                            <div className='flex items-center gap-3 md:gap-1'>
                                <p className='text-sm font-bold text-nowrap'>From:</p>
                                <div className='w-[100%] md:w-[75%]'>
                                    <DatePicker
                                        selected={selectedFrom}
                                        onChange={(date) => setSelectedFrom(date)}
                                        className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                                        showPopperArrow={false}
                                        dateFormat="yyyy-MM-dd"
                                        shouldCloseOnSelect={true}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center gap-3 md:gap-1'>
                                <p className='text-sm font-bold text-nowrap'>To:</p>
                                <div className='w-[100%] md:w-[75%]'>
                                    <DatePicker
                                        selected={selectedTo}
                                        onChange={(date) => setSelectedTo(date)}
                                        className="outline-none w-[100%] border bg-transparent text-sm p-1 rounded-md"
                                        showPopperArrow={false}
                                        dateFormat="yyyy-MM-dd"
                                        shouldCloseOnSelect={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page:</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input
                                    className='w-[100%] px-3 border bg-transparent focus:outline-none rounded-md py-1'
                                    type='text'
                                    value={entitiesPerPage}
                                    min="5"
                                    onChange={handleEntryChange}
                                />

                            </div>
                        </div>
                        <div className='text-sm font-bold m-auto'>
                            Page {currentPage} of {totalPages}
                        </div>
                    </div>
                </div>
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div className=''>
                            <p className='my-auto font-bold text-lg'>Showing Wallet Transactions</p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden duration-500 hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>View</th>
                                    <th className='px-2'>Date</th>
                                    <th className='px-2'>CusID</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Address</th>
                                    <th className='px-2'>Credited</th>
                                    <th className='px-2'>Debited</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => {
                                    const transaction = entity;
                                    const formattedDate = transaction ? new Date(transaction.created_at).toLocaleString() : 'No date provided';
                                    const polyscanUrl = `https://polygonscan.com/tx/${transaction.transaction_hash}`;

                                    return (
                                        <tr key={index} className='h-16 text-[13px] text-center rounded-md'>
                                            <td className='px-5 md:px-2'>{indexOfFirstEntity + index + 1}</td>
                                            <td className='px-5 md:px-2'>
                                                <a href={polyscanUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View</a>
                                            </td>
                                            <td className='px-5 md:px-2'>{formattedDate}</td>
                                            <td className='px-5 md:px-2'>{transaction.CusID}</td>
                                            <td className='px-5 md:px-2'>{transaction.email}</td>
                                            <td className='px-5 md:px-2'>{transaction.receiver_address || transaction.sender_address}</td>
                                            <td className='px-5 md:px-2'>{walletType === 'DEPOSIT' ? Number(transaction.amount).toFixed(2) : 0}</td>
                                            <td className='px-5 md:px-2'>{walletType === 'WITHDRAW' ? Number(transaction.amount).toFixed(2) : 0}</td>
                                        </tr>
                                    );
                                })}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}