import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import { SERVER_URL } from '../../../config';

export default function Customerinfo() {
  const [tableEntries, setTableEntries] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleRecordChange = (e) => {
    setEntitiesPerPage(parseInt(e));
    setCurrentPage(1);
  };

  const handleDownload = () => {
    if (tableEntries.length === 0) {
      toast.error('No data available for download.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(tableEntries);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'customer_data.xlsx');
  };

  const filterData = () => {
    const filteredEntries = allEntries.filter(entity => {
      const registrationDate = new Date(entity.Entry_Date);
      const fromDate = selectedFrom ? new Date(selectedFrom) : null;
      const toDate = selectedTo ? new Date(selectedTo) : null;

      const afterFrom = fromDate ? registrationDate >= fromDate : true;
      const beforeTo = toDate ? registrationDate <= toDate : true;
      return afterFrom && beforeTo;
    });

    setTableEntries(filteredEntries);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}/api/filterCustomers`, {});
        setAllEntries(response.data.data);
        setTableEntries(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [selectedFrom, selectedTo]);

  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const pageNumbers = [];
  const maxPagesToShow = 5;
  const halfMaxPages = Math.floor(maxPagesToShow / 2);
  let startPage = Math.max(1, currentPage - halfMaxPages);
  let endPage = Math.min(totalPages, currentPage + halfMaxPages);

  if (startPage === 1) {
    endPage = Math.min(maxPagesToShow, totalPages);
  } else if (endPage === totalPages) {
    startPage = Math.max(1, totalPages - maxPagesToShow + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  function formatTimestamp(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='p-3 md:p-7 mt-5 font-poppins'>
        <h1 className='text-2xl font-bold uppercase '>Customer Information</h1>
        <div className='rounded-lg mt-5 border'>
          <div className='px-5 py-3 border-b font-bold text-lg'>
            <p>Filter Your Search</p>
          </div>
          <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
            <div className="text-sm font-bold flex items-center">
              Total Record: {tableEntries.length}
            </div>
            <div className="flex items-center gap-4">
              <p className="text-sm font-bold">Date</p>
              <div className="flex items-center gap-3">
                <p className="text-sm font-bold">From:</p>
                <div className="w-[100px] md:w-[150px]">
                  <DatePicker
                    selected={selectedFrom}
                    onChange={(date) => setSelectedFrom(date)}
                    className="outline-none w-full border bg-transparent text-sm p-1 rounded-md"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
              <div className="flex items-center gap-3">
                <p className="text-sm font-bold">To:</p>
                <div className="w-[100px] md:w-[150px]">
                  <DatePicker
                    selected={selectedTo}
                    onChange={(date) => setSelectedTo(date)}
                    className="outline-none w-full border bg-transparent text-sm p-1 rounded-md"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
            </div>
            <div className='flex md:grid gap-1 m-auto'>
              {/* Additional elements if needed */}
            </div>
            <div className='flex md:grid gap-1 m-auto items-center justify-center'>
              <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
              <div className='w-[25%] md:w-[100%]'>
                <input
                  type='number'
                  className='w-[100%] px-3 border bg-transparent rounded-md py-1'
                  onChange={(e) => handleRecordChange(e.target.value)}
                  value={entitiesPerPage}
                />
              </div>
            </div>
            <div className='text-sm font-bold m-auto'>
              <div>
                <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handleDownload}>Get Excel Report</button>
              </div>
            </div>
          </div>
        </div>
        <div className='rounded-lg mt-5 border pb-3'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
            <div className='flex flex-col md:flex-row gap-3 items-center'>
              <p className='my-auto font-bold'>Showing Customer Records</p>
            </div>
            <button className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' onClick={handleDownload}>Get Excel Report</button>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show number of entities
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 overflow-hidden overflow-x-auto'>
            <table className='w-full text-center'>
              <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                <tr>
                  <th className='py-3 px-2'>CustID</th>
                  <th className='px-2'>Name</th>
                  <th className='px-2'>SponsorID</th>
                  <th className='px-2'>Email</th>
                  <th className='px-2'>Country</th>
                  <th className='px-2'>MobileNo</th>
                  <th className='px-2'>Registration Date</th>
                  <th className='px-2'>Active Date</th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                    <td className='px-5 md:px-2'>{entity.CusID ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.name ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.sponsorid ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.email ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.country ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.Cust_mobileNo ? entity.Cust_mobileNo : ' - '}</td>
                    <td className='px-5 md:px-2'>{formatTimestamp(entity.Entry_Date) ?? ' - '}</td>
                    <td className='px-5 md:px-2'>{entity.activedate ? formatTimestamp(entity.activedate) : ' - '}</td>
                  </tr>
                ))}
                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
}