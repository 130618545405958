import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

export default function Chart() {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: [
        {
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61],
        },
        {
          name: 'Revenue',
          data: [76, 85, 101, 98, 87],
        },
      ],
      chart: {
        type: 'bar',
        height: 225,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 8, // Set the borderRadius for rounded corners
          endingShape: 'rounded',
        },
      },
      colors: [
        '#D9D9D9', // Solid gray color for Net Profit
        '#ee5f56', // Base color for Revenue (overridden by gradient)
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)',
        },
      },
      fill: {
        opacity: 1,
        type: ['solid', 'gradient'], // Gradient only for the "Revenue" series
        gradient: {
          shade: 'light',
          type: 'horizontal', // Left-to-right gradient
          shadeIntensity: 0.5,
          gradientToColors: ['#FB9236'], // End color for the gradient
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return '$ ' + val + ' thousands';
          },
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    // Clean up on component unmount
    return () => chart.destroy();
  }, []);

  return <div id="chart" ref={chartRef}></div>;
}
