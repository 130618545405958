import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import './Style/UserDashboard.css';
import { SERVER_URL, MBCPayOut_ContractAddress } from '../config';
import { useSelector } from "react-redux";
import axios from "axios";
import { ethers } from "ethers";
import { MBCPayOut_abi } from '../ABI';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddWhitelist() {

    const user = useSelector((state) => state.user);
    const email = user[0].email;
    const CusID = user[0].CusID;

    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [Popup, setPopup] = useState(false);

    const Add = async () => {
        try {
            setPopup(true);

            if (!isConnected) {
                toast.error("Please Connect Wallet First");
                setPopup(false);
                return false;
            }

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();

            const MBCContract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
            const gasPrice = await provider.getGasPrice();

            let admin = await MBCContract.ADMIN();

            if (address !== admin) {
                toast.error("Signer is not the Admin");
                setPopup(false);
                return false;
            }

            let userAddress = [
                '0x12b24A10cfc152Efa9b2A6FD590954fE179Bd725',
                '0x5987731299057526B84480db675b6bb549eF7D8c',
                '0x87b92f28Ffa170B377B9D2434d0973d16b91493d',
                '0x6008D005B8C77C81bd90470E1889DeC71c06494D',
                '0xD7DC24E3D4CA22e368207b1eF679b4C761E46181',
                '0x5745B1A56f126D357a54d2c682303480980AEE08',
                '0x5D0357EDB2f05AA558DAf329D34A74964cAD5668',
                '0xAe64E6466Ed28409DeD8C94a1998bD0d5E0E3687',
                '0xba1D857108d4a6adc0a3B1d593b8E1241b3e3c7c'
            ];
            let referrers = [
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b',
                '0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b'
            ];
            let planList = [8, 8, 8, 8, 8, 8, 8, 8, 8]

            const AddwhiteList = await MBCContract.addWhitelist(userAddress, referrers, planList, {
                gasPrice: ethers.utils.parseUnits(ethers.utils.formatUnits(gasPrice, "gwei"), "gwei"),
                gasLimit: 500000
            });
            await AddwhiteList.wait();

            let hash = AddwhiteList.hash;
            let status = AddwhiteList.status;

            const response = await axios.post(`${SERVER_URL}/api/AddWhitelist`, {
                userAddress,
                referrers,
                planList,
                hash,
                status,
            });

            if (status === 1) {
                toast.success('Whitelisted Successfully');
            } else {
                toast.error("Please try again!")
            }

            setTimeout(() => {
                window.location.reload()
            }, 3000);
            setPopup(false);
        } catch (error) {
            setPopup(false);
            console.error("An unexpected error occurred:", error);
            toast.error("An unexpected error occurred.");
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        }
    };

    return (
        <div>
            <ToastContainer position='top-center' />
            <div className="fixed inset-0 z-0 ">
                <img src="/assets/NewMBCbg.png" className="h-full w-full object-cover object-top"></img>
            </div>
            <div className='flex justify-end mr-5 mt-5'>
                <w3m-button />
            </div>
            <div className='text-white' data-aos="fadeIn" data-aos-duration='2000'>
                <div className='md:pl-5 md:pr-10 font-poppins'>
                    <div className='mt-28 border rounded-md px-10 py-28 grid grid-cols-2 lg:grid-cols-4 gap-5'>

                        <div className="col-span-2 lg:col-span-4 text-center">
                            <h2 className="text-2xl font-semibold mb-5">
                                Whitelist Users
                            </h2>
                            <p className="text-lg mb-10">
                                As an admin, you can whitelist 9 users by clicking the "Whitelist" button below. This action will allow you to assign the users to specific plans and register them on the contract.
                            </p>
                            <p className="text-lg mb-10">
                                please connect to the admin wallet : 0xc990d55ca928dcAD6ec21cC3e821dF6F3499Dc5b
                            </p>
                            <button
                                className="mt-5 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold w-[20%] py-2 rounded-md"
                                onClick={Add}>
                                Whitelist Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {Popup && (
                <div className='fixed z-30 inset-0 w-screen left-0 top-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20'>
                    <div className='flex items-center gap-2 text-xl font-semibold'>
                        <FontAwesomeIcon icon={faGear} className='fa-spin' />
                        <p>Loading...</p>
                    </div>
                </div>
            )}
        </div>
    )
}