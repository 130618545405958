import React, { useState } from 'react';
import axios from 'axios';

export default function CreditWallet() {
    const [CusID, setCusID] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [error, setError] = useState('');

    const handleFetchWallet = async () => {
        if (!CusID) {
            setError('Please enter a Member ID.');
            return;
        }

        try {
            const response = await axios.get(`http://localhost:3006/api/getIncomeWallet/${CusID}`);
            setWalletAddress(response.data.rows.income_wallet);
        } catch (err) {
            setError('Error fetching wallet address. Please try again.');
            setWalletAddress('');
        }
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Credit Wallet </h1>
                <div className=' rounded-lg mt-5 border '>
                    <div className='px-5 py-3 border-b  font-bold text-lg'>
                        <p>Credit Wallet Using Member ID</p>
                    </div>
                    <div className='p-5 flex flex-col md:flex-row justify-center items-center gap-5'>
                        <p>Member ID</p>
                        <input className='w-[100%] md:w-[50%] border py-3 px-5 rounded-md bg-transparent' value={CusID} onChange={(e) => setCusID(e.target.value)}></input>
                    </div>
                    <div className='pb-5 flex justify-center'>
                        <button className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md ' onClick={handleFetchWallet}>Get button</button>
                    </div>
                    {walletAddress && (
                        <div className='text-center mt-5'>
                            <p className='font-bold'>Income Wallet Address:</p>
                            <p>{walletAddress}</p>
                        </div>
                    )}
                    {error && (
                        <div className='text-center mt-5 text-red-600'>
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
