import React, { useState } from 'react';
import axios from 'axios';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DebitWallet() {
    const [CusID, setCusID] = useState('');
    const [depositWallet, setDepositWallet] = useState(null);
    const [details, setdetails] = useState(false)
    const [selectedOption, setSelectedOption] = useState('Income');
    const [isOpen, setIsOpen] = useState(false);


    // Update the CusID when input changes
    const handleInputChange = (e) => {
        setCusID(e.target.value);
    };

    // Fetch data only when the button is clicked
    const handleButtonClick = async () => {
        if (CusID.trim() === '') {
            setDepositWallet(null);
            return;
        }

        try {
            const response = await axios.get(`http://localhost:3006/api/getDepositWallet?CusID=${CusID}`);
            if (response.data && response.data.deposit_wallet) {
                setDepositWallet(response.data.deposit_wallet);
            } else {
                setDepositWallet('No data found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setDepositWallet('Error fetching data');
        }
    };



    const handleDetails = () => {
        setdetails(!details)
    }

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Debit Wallet </h1>
                <div className=' rounded-lg mt-5 border '>
                    <div className='px-5 py-3 border-b  font-bold text-lg'>
                        <p>Debit Wallet Using Member ID</p>
                    </div>
                    <div className='p-5 flex flex-col md:flex-row justify-center items-center gap-5'>
                        <p>Customer Member ID</p>
                        <input className='w-[100%] md:w-[50%] border py-3 px-5 rounded-md bg-transparent'></input>
                    </div>
                    <div className='pb-5 flex justify-center'>
                        <button className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md ' onClick={handleDetails}>Get button</button>
                    </div>
                </div>
                {details && (
                    <div className=' rounded-lg mt-5 border '>
                        <div className='px-5 py-3 border-b  font-bold text-lg'>
                            <p>Customer Member ID Details</p>
                        </div>
                        <div className='grid gap-3 px-5 py-3'>
                            <div className=' flex flex-col md:flex-row  items-center gap-5'>
                                <p className='md:w-[30%] text-center md:text-end '>Select Wallet</p>
                                <div className='relative w-[100%] md:w-[50%]'>
                                    <button
                                        onClick={toggleDropdown}
                                        className='w-full h-[50px] border py-3 px-5 outline-none rounded-md bg-transparent flex justify-between items-center'
                                    >
                                        {selectedOption}
                                        <span><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </button>

                                    {isOpen && (
                                        <div className='absolute w-full text-black mt-1 border bg-white rounded-md'>
                                            <ul>
                                                <li
                                                    className='px-5 py-2 cursor-pointer hover:bg-gray-200 uppercase'
                                                    onClick={() => handleOptionClick('Income')}
                                                >
                                                    Income
                                                </li>
                                                <li
                                                    className='px-5 py-2 cursor-pointer hover:bg-gray-200 uppercase'
                                                    onClick={() => handleOptionClick('Deposit')}
                                                >
                                                    Deposit
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className=' flex flex-col md:flex-row  items-center gap-5'>
                                <p className='md:w-[30%] text-center md:text-end '>Your Balance</p>
                                <div className='w-[100%] md:w-[50%] h-[50px] border py-3 px-5 outline-none rounded-md bg-transparent cursor-not-allowed'></div>
                            </div>
                            <div className=' flex flex-col md:flex-row  items-center gap-5'>
                                <p className='md:w-[30%] text-center md:text-end '>Member ID</p>
                                <div className='w-[100%] md:w-[50%] border py-3 px-5 outline-none rounded-md bg-transparent cursor-not-allowed'>708644</div>
                            </div>
                            <div className=' flex flex-col md:flex-row  items-center gap-5'>
                                <p className='md:w-[30%] text-center md:text-end '>Amount</p>
                                <input className='w-[100%] md:w-[50%] border py-3 px-5 outline-none rounded-md bg-transparent'></input>
                            </div>
                            <div className=' flex flex-col md:flex-row  items-center gap-5'>
                                <p className='md:w-[30%] text-center md:text-end '>Remark</p>
                                <input className='w-[100%] md:w-[50%] border py-3 px-5 outline-none rounded-md bg-transparent'></input>
                            </div>
                        </div>
                        <div className='pb-5 flex justify-center'>
                            <button className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md '>Submit</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}


//     return (
//         <div data-aos="fadeIn" data-aos-duration='2000'>
//             <div className='p-3 md:p-7 mt-5 font-poppins'>
//                 <h1 className='text-2xl font-bold uppercase '>Debit Wallet</h1>
//                 <div className='rounded-lg mt-5 border'>
//                     <div className='px-5 py-3 border-b font-bold text-lg'>
//                         <p>Debit Wallet Using Member ID</p>
//                     </div>
//                     <div className='p-5 flex flex-col md:flex-row justify-center items-center gap-5'>
//                         <p>Customer Member ID</p>
//                         <input
//                             className='w-[100%] md:w-[50%] border py-3 px-5 rounded-md bg-transparent'
//                             value={CusID}
//                             onChange={handleInputChange}
//                         />
//                     </div>
//                     {depositWallet !== null && (
//                         <div className='p-5 flex flex-col md:flex-row justify-center items-center gap-5'>
//                             <p className='font-bold'>Deposit Wallet:</p>
//                             <p>{depositWallet}</p>
//                         </div>
//                     )}
//                     <div className='pb-5 flex justify-center'>
//                         <button 
//                             className='bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md'
//                             onClick={handleButtonClick}
//                         >
//                             Get Wallet Info
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }