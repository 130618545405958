import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import toast from "react-hot-toast";

export default function Alltickets() {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [replyInput, setReplyInput] = useState('');
    const [status, setStatus] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [imageModal, setImageModal] = useState({ isOpen: false, imageUrl: '' });
    const [replyModal, setReplyModal] = useState({ isOpen: false, ticket: null });

    const fetchTickets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/fetchTickets`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const ticketData = Array.isArray(data.tickets) ? data.tickets : [];
            setTickets(ticketData);
            setFilteredTickets(ticketData);
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    const OpenedTicket = async (ticket_id) => {
        try {
            await axios.get(`${SERVER_URL}/api/OpenedTicket?ticket_id=${ticket_id}`);
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        const filtered = filterStatus
            ? tickets.filter(ticket => ticket.status.toLowerCase() === filterStatus.toLowerCase())
            : tickets;
        setFilteredTickets(filtered);
        setCurrentPage(1);
    }, [filterStatus, tickets]);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredTickets.slice(indexOfFirstEntity, indexOfLastEntity);

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(filteredTickets.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleViewImage = (imageUrl) => {
        setImageModal({ isOpen: true, imageUrl });
    };

    const handleCloseImageModal = () => {
        setImageModal({ isOpen: false, imageUrl: '' });
    };

    const openReplyModal = (ticket) => {
        OpenedTicket(ticket.ticket_id);
        setReplyModal({ isOpen: true, ticket });
    };

    const handleReplySubmit = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/replyToTicket`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ admin_reply: replyInput, status: status, ticket_id: replyModal.ticket.ticket_id })
            });
            if (!response.ok) {
                toast.error('Error during updating the ticket');
                console.error(response);
                return false;
            }
            toast.success('Ticket Updated');

            fetchTickets();
            setReplyInput("");
            setReplyModal({ isOpen: false, ticket: null });
        } catch (error) {
            console.error("Error updating admin reply:", error);
        }
    };

    useEffect(() => {
        if (replyModal.ticket) {
            setReplyInput(replyModal.ticket.admin_reply || '');
            setStatus(replyModal.ticket.status || 'In Progress');
        }
    }, [replyModal.ticket]);

    const totalPages = Math.ceil(filteredTickets.length / entitiesPerPage);
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (
        <div className="mx-auto font-poppins">
            <div data-aos="fadeIn" data-aos-duration="2000">
                <div className="p-3 md:p-7 mt-5 font-poppins">
                    <h1 className="text-2xl font-bold uppercase">All Support Ticket</h1>
                    <div className="rounded-lg mt-5 border">
                        <div className="px-5 py-3 border-b font-bold">
                            <p>Filter Your Search</p>
                        </div>
                        <div className="p-3 grid flex-col lg:grid-cols-3 gap-3 lg:gap-10">
                            <div className="text-sm font-bold m-auto">
                                Total Record: {filteredTickets.length}
                            </div>
                            <div className="flex gap-1 m-auto items-center md:gap-10 justify-center md:justify-between">
                                <p className="text-end  px-3 my-auto">
                                    Filter
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={(e) => setFilterStatus(e.target.value)}
                                        value={filterStatus}
                                    >
                                        <option className="text-black" value="">All</option>
                                        <option className="text-black" value="Open">Open</option>
                                        <option className="text-black" value="In Progress">In Progress</option>
                                        <option className="text-black" value="On Hold">On Hold</option>
                                        <option className="text-black" value="Resolved">Resolved</option>
                                    </select>
                                </p>
                            </div>
                            <div className="text-sm md:justify-end font-bold m-auto">
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5 border pb-3">
                        <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b">
                            <div>
                                <p className="font-bold">Showing All Ticket</p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-end text-sm px-3 my-auto">Show number of entities
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={handleDropdownChange}
                                        value={entitiesPerPage}
                                    >
                                        <option className="text-black" value="5">5</option>
                                        <option className="text-black" value="10">10</option>
                                        <option className="text-black" value="50">50</option>
                                        <option className="text-black" value="100">100</option>
                                    </select>
                                </p>
                            </div>
                        </div>

                        <div className="mb-5 overflow-hidden hover:overflow-x-auto">
                            <table className="w-full text-center text-[15px]">
                                <thead className="uppercase font-bold border-b py-3 text-nowrap">
                                    <tr>
                                        <th className="py-3 px-2">ID</th>
                                        <th className="px-2">Ticket ID</th>
                                        <th className="px-2">CusID</th>
                                        <th className="px-2">Status</th>
                                        <th className="px-2">Created At</th>
                                        <th className="px-2">Image</th>
                                        <th className="px-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {currentEntities.map((ticket, index) => (
                                        <tr key={ticket.ticket_id} className="h-16 text-center rounded-md font-bold">
                                            <td className="px-2">{(currentPage - 1) * entitiesPerPage + index + 1}</td>
                                            <td className="px-2">#{ticket.ticket_id}</td>
                                            <td className="px-2">{ticket.CusID}</td>
                                            <td className="px-2">{ticket.status}</td>
                                            <td className="px-2">{formatDate(ticket.created_at)}</td>
                                            <td className="px-2">
                                                <button
                                                    className={` text-black px-4 py-2 rounded-md ml-2 ${ticket.image === null ? 'cursor-not-allowed bg-gray-500' : 'bg-gray-300'}`}
                                                    onClick={() => handleViewImage(ticket.fullImageUrl)}
                                                    disabled={!ticket.fullImageUrl}
                                                >
                                                    View Image
                                                </button>
                                            </td>
                                            <td className="px-2">
                                                <button
                                                    className="bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-white px-4 py-2 rounded-md"
                                                    onClick={() => openReplyModal(ticket)}
                                                >
                                                    Reply
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-center gap-5 mt-5 px-5">
                                <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <div>
                                    {pageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => handlePageClick(number)}
                                            className={`px-3 py-1 ${number === currentPage ? 'border border-white rounded-lg' : ''}`}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                                <button onClick={handleNextClick} disabled={currentPage === totalPages}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {imageModal.isOpen && (
                        <div className="fixed inset-0 backdrop-blur-md flex justify-center items-center z-50">
                            <div className="bg-white mt-28 p-1 rounded-lg w-[70%]">
                                <img src={imageModal.imageUrl} alt="Ticket Image" className='w-full' />
                                <div className="flex justify-center mt-3">
                                    <button
                                        className="bg-red-500 text-white px-4 py-2 rounded-md"
                                        onClick={handleCloseImageModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {replyModal.isOpen && (
                        <div className="fixed inset-0 backdrop-blur-md bg-opacity-75 flex items-center justify-center z-50">
                            <div className="bg-white text-black p-6 rounded-lg shadow-lg border border-gray-300 w-[90%] md:w-[50%]">
                                <h3 className="text-xl font-bold mb-3">Reply to Ticket</h3>
                                <p className="text-sm text-gray-700 mt-2">
                                    <strong>Subject:</strong> {replyModal.ticket.subject}
                                </p>
                                <p className="text-sm text-gray-700 mt-4">
                                    <strong>Description:</strong> {replyModal.ticket.description}
                                </p>

                                <textarea
                                    className="w-full mt-4 p-2 border rounded-md"
                                    rows="4"
                                    placeholder="Enter your reply here"
                                    value={replyInput}
                                    onChange={(e) => setReplyInput(e.target.value)}
                                />

                                <div className="mt-4">
                                    <label htmlFor="status" className="block text-sm text-gray-700 font-bold">Status</label>
                                    <select
                                        id="status"
                                        className="w-full mr-5 p-2 border rounded-md"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option value="">Select the Status</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Resolved">Resolved</option>
                                    </select>
                                </div>

                                <div className="mt-3 flex justify-end gap-5">
                                    <button
                                        className="bg-gray-300 text-black px-4 py-2 rounded-md"
                                        onClick={() => setReplyModal({ isOpen: false, ticket: null })}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-white px-4 py-2 rounded-md"
                                        onClick={handleReplySubmit}
                                    >
                                        Submit Reply
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </div>
    );
}
