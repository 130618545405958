import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';

export default function Openticket() {

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    const fetchTickets = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/tickets`);
            if (response.data.status) {
                const openTickets = response.data.tickets.filter(ticket => ticket.StatusReport === 'Open');
                setTableEntries(openTickets);
            } else {
                setTableEntries([]);
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [tableEntries, setTableEntries] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredEntities = tableEntries.filter((entity) => {
        const searchLower = searchTerm.toLowerCase();
        const custIdMatch = entity.CusID === parseInt(searchLower, 10);

        return (
            custIdMatch ||
            (entity.EmailID?.toLowerCase().includes(searchLower) || '') ||
            (entity.CustUsername?.toLowerCase().includes(searchLower) || '') ||
            (entity.StatusReport?.toLowerCase().includes(searchLower) || '') ||
            (entity.TxNo?.toString().includes(searchLower) || '') ||
            (entity.user_message?.toLowerCase().includes(searchLower) || '')
        );
    });

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntities.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;
    const totalPages = Math.ceil(filteredEntities.length / entitiesPerPage);

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Open Ticket
                </h1>
                <div className=' rounded-lg mt-2 border '>
                    <div className='px-5 py-3 border-b  font-bold'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className='text-sm font-bold m-auto'>
                            Total Record : {currentEntities.length}
                        </div>
                        <div className='text-sm font-bold m-auto'>Page {currentPage} of {totalPages}</div>
                        <div
                            className='mr-2'
                        >
                            <input
                                type='text'
                                placeholder='Search tickets...'
                                className='p-2 mr-2 border text-black rounded-md'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        {/* <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                            Page of 1 of 0
                        </div> */}
                    </div>
                </div>

                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b'>
                        <div>
                            <p className='font-bold'>Showing All Ticket</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="10">10</option>
                                    <option className='text-black' value="50">50</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center text-[13px]'>
                            <thead className='uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>

                                    <th className='px-2'>TxNo</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>EmailID</th>
                                    <th className='px-2'>Cust Username</th>
                                    <th className='px-2'>Receiver</th>
                                    <th className='px-2'>Message</th>
                                    <th className='px-2'>Time Stamp</th>
                                    <th className='px-2'>Status</th>
                                    <th className='px-2'>Status Report</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{index + 1}</td>

                                        <td className='px-5 md:px-2'>{entity.TxNo}</td>
                                        <td className='px-5 md:px-2'>{entity.CustID}</td>
                                        <td className='px-5 md:px-2'>{entity.EmailID}</td>
                                        <td className='px-5 md:px-2'>{entity.CustUsername}</td>
                                        <td className='px-5 md:px-2'>{entity.Receiver}</td>
                                        <td className='px-5 md:px-2' dangerouslySetInnerHTML={{ __html: entity.user_message }}></td>
                                        <td className='px-5 md:px-2'>{formatDate(entity.open_time)}</td>
                                        <td className='px-5 md:px-2'>{entity.Status}</td>
                                        <td className='px-5 md:px-2'>{entity.StatusReport}</td>
                                    </tr>
                                ))}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}