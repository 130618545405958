import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

export default function AdminLogin() {

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [adminOrCusID, setAdminOrCusID] = useState("");

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = filteredData.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(filteredData.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredData.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/getAdminLogins`);
      if (response.data.status === true) {
        setData(response.data.data);
        setFilteredData(response.data.data); 
      }
    } catch (error) {
      console.log("Error while fetching the admin login details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterSubmit = () => {
    let filtered = [...data];

    if (selectedFrom) {
      filtered = filtered.filter(entity => new Date(entity.created_at) >= selectedFrom);
    }
    if (selectedTo) {
      filtered = filtered.filter(entity => new Date(entity.created_at) <= selectedTo);
    }

    if (adminOrCusID) {
      const searchTerm = adminOrCusID.toLowerCase();
      filtered = filtered.filter(entity => 
        entity.name.toLowerCase().includes(searchTerm) || entity.adminID.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredData(filtered); 
    setCurrentPage(1); 
  };

  function formatTimestamp(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='p-3 md:p-7 mt-5 font-poppins'>
        <h1 className='text-2xl font-bold uppercase '>Admin Login History</h1>
        <div className="rounded-lg mt-5 border">
          <div className="px-5 py-3 border-b font-bold text-lg">
            <p>Filter Your Search</p>
          </div>
          <div className="p-3 flex flex-wrap gap-3 lg:gap-10 justify-between">

            <div className="text-sm font-bold flex items-center">
              Total Record: {filteredData.length}
            </div>

            <div className="flex items-center gap-4">
              <p className="text-sm font-bold">Date</p>
              <div className="flex items-center gap-3">
                <p className="text-sm font-bold">From:</p>
                <div className="w-[100px] md:w-[150px]">
                  <DatePicker
                    selected={selectedFrom}
                    onChange={(date) => setSelectedFrom(date)}
                    className="outline-none w-full border bg-transparent text-sm p-1 rounded-md"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
              <div className="flex items-center gap-3">
                <p className="text-sm font-bold">To:</p>
                <div className="w-[100px] md:w-[150px]">
                  <DatePicker
                    selected={selectedTo}
                    onChange={(date) => setSelectedTo(date)}
                    className="outline-none w-full border bg-transparent text-sm p-1 rounded-md"
                    showPopperArrow={false}
                    dateFormat="yyyy-MM-dd"
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <p className="text-sm font-bold">Admin Name or adminID:</p>
              <input 
                className="w-[150px] px-2 border rounded-md bg-transparent py-1"
                value={adminOrCusID}
                onChange={(e) => setAdminOrCusID(e.target.value)}
              />
            </div>

            <div>
              <button
                onClick={handleFilterSubmit}
                className="px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md">
                Submit
              </button>
            </div>
          </div>
        </div>

        <div className='rounded-lg mt-5 border pb-3'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
            <div className=''>
              <p className='my-auto font-bold'>Showing Admin Records</p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 overflow-hidden overflow-x-auto'>
            <table className='w-full text-center'>
              <thead className='text-sm uppercase font-bold border-b py-3'>
                <tr>
                  <th className='py-3 px-2'>#</th>
                  <th className='px-2'>AdminID</th>
                  <th className='px-2'>Name</th>
                  <th className='px-2'>Email</th>
                  <th className='px-2'>Remote_addr</th>
                  <th className='px-2'>Country</th>
                  <th className='px-2'>Date & Time</th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16'>
                    <td className='px-5 md:px-2'>{indexOfFirstEntity + index + 1}</td>
                    <td className='px-5 md:px-2'>{entity.adminID}</td>
                    <td className='px-5 md:px-2'>{entity.name}</td>
                    <td className='px-5 md:px-2'>{entity.email}</td>
                    <td className='px-5 md:px-2'>{entity.ip_address}</td>
                    <td className='px-5 md:px-2'>{entity.country_name}</td>
                    <td className='px-5 md:px-2'>{formatTimestamp(entity.created_at)}</td>
                  </tr>
                ))}

                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
}