import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { MBCPayOut_ContractAddress, SERVER_URL } from '../../config';
import { MBCPayOut_abi } from '../../ABI';
import toast from "react-hot-toast";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ChangePrice = () => {
    const [tiers, setTiers] = useState(1);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const handleChangePrice = async () => {
        try {
            setLoading(true);

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);

            if (address.toLowerCase() !== '0xbc543d7cb1cf80636a7188286c55fdc7fd68b343'.toLowerCase()) {
                toast.error("Signer is not the Admin");
                setLoading(false);
                return;
            }

            if (isNaN(tiers) || isNaN(percentage) || tiers <= 0 || percentage <= 0) {
                toast.error("Invalid input values");
                setLoading(false);
                return;
            }

            const gasPrice = await provider.getGasPrice();
            const gasLimit = await contract.estimateGas.changeDirectReferralBonus(
                Number(tiers),
                Number(percentage)
            );

            const tx = await contract.changeDirectReferralBonus(Number(tiers), Number(percentage), {
                gasPrice,
                gasLimit,
            });
            const receipt = await tx.wait();
            console.log(receipt)
            if (receipt.status === 1) {
                const hash = receipt.transactionHash;

                const response = await axios.post(`${SERVER_URL}/api/changeRewards`, {
                    tiers,
                    percentage,
                    address,
                    hash,
                });

                if (response.data.status === true) {
                    toast.success("Rewards Updated Successfully");
                    setTiers(1);
                    setPercentage(0);
                } else {
                    toast.error("Error Updating Rewards");
                }
            } else {
                toast.error("Transaction failed");
            }
        } catch (err) {
            console.error("Error updating rewards:", err);
            toast.error("Error Updating Rewards");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='flex justify-center items-center h-full mt-24 font-poppins'>
            <div className="w-[95%] mx-auto max-w-xl relative">
                <div className="bg-white rounded-lg px-5 py-10 ">
                    <div className="lg:w-[60%] mx-auto">
                        <p className="text-black font-semibold leading-6 text-center pb-5">Change Direct Referral Bonus</p>
                    </div>
                    <div className="md:w-[85%] mx-auto mt-10 md:mt-0 px-5">
                        <div>
                            <label className="text-black/40 font-bold">Tier</label>

                            <div className="mt-2 bg-[#EFEFEF] rounded-xl px-5 py-2">
                                <select
                                    value={tiers}
                                    onChange={(e) => setTiers(e.target.value)}
                                    className="focus:outline-none font-semibold text-[#F23985] w-full"
                                >
                                    {Array.from({ length: 8 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mt-3">
                            <label className="text-black/40 font-bold">Percentage</label>
                            <input
                                type="number"
                                value={percentage}
                                onChange={(e) => {
                                    const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                    setPercentage(value);
                                }}
                                className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-full"
                            />
                        </div>

                        <div className="mt-5">
                            <button
                                type="submit"
                                onClick={handleChangePrice}
                                className="w-full rounded-2xl py-3 shadow-lg shadow-black/30 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-semibold"
                            >
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                ) : (
                                    "Change Reward"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePrice;
