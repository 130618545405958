// import React, { useState } from 'react'
// import Account from './Account';
// import Security from './Security';
// import UserKYC from './UserKYC';
// import PaymentDetails from './PaymentDetails';

// export default function AccountSettings() {
//     const [activeTab, setActiveTab] = useState('Account');

//     const handleTabClick = (tab) => {
//         setActiveTab(tab);
//     };	

//     return (
//         <div className='py-5 md:p-5 font-poppins text-white overflow-hidden overflow-y-auto'>
//             <div>
//                 <p className='text-white/50'>
//                     Account Settings <span className='text-white font-semibold'>/ {activeTab}</span>
//                 </p>
//                 <div className='hidden md:block'>
//                     <div className='flex gap-3 mt-5'>
//                         <button
//                             onClick={() => handleTabClick('Account')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Account' ? 'bg-[#E43875] text-white' : ''}`}
//                         >
//                             Account
//                         </button>
//                          <button
//                             onClick={() => handleTabClick('Security')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Security' ? 'bg-[#E43875] text-white' : ''}`}
//                         >
//                             Security
//                         </button> 
//                          <button
//                             onClick={() => handleTabClick('User KYC')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'User KYC' ? 'bg-[#E43875] text-white' : ''}`}
//                         >
//                             User KYC
//                         </button> 
//                          <button
//                             onClick={() => handleTabClick('Payment Details')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Payment Details' ? 'bg-[#E43875] text-white' : ''}`}
//                         >
//                             Payment Details
//                         </button> 
//                     </div>
//                 </div>
//                 <div className='block md:hidden'>
//                     <div className='grid grid-cols-2 mt-5'>
//                         <button
//                             onClick={() => handleTabClick('Account')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Account' ? 'bg-[#E43875] text-white border-[0.5px]' : ''}`}
//                         >
//                             Account
//                         </button>
//                          <button
//                             onClick={() => handleTabClick('Security')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Security' ? 'bg-[#E43875] text-white border-[0.5px]' : ''}`}
//                         >
//                             Security
//                         </button> 
//                          <button
//                             onClick={() => handleTabClick('User KYC')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'User KYC' ? 'bg-[#E43875] text-white border-[0.5px]' : ''}`}
//                         >
//                             User KYC
//                         </button> 
//                          <button
//                             onClick={() => handleTabClick('Payment Details')}
//                             className={`px-5 md:px-7 py-1 rounded-md ${activeTab === 'Payment Details' ? 'bg-[#E43875] text-white border-[0.5px]' : ''}`}
//                         >
//                             Payment Details
//                         </button> 
//                     </div>
//                 </div>
//                 {activeTab === 'Account' &&
//                     <Account />
//                 }
//                  {activeTab === 'Security' &&
//                     <Security />
//                 }
//                 {activeTab === 'User KYC' &&
//                     <UserKYC />
//                 }
//                 {activeTab === 'Payment Details' &&
//                     <PaymentDetails />
//                 } 
//             </div>
//         </div>
//     )
// }

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Account from './Account';
import Security from './Security';
import UserKYC from './UserKYC';
import PaymentDetails from './PaymentDetails';
import toast, { Toaster } from "react-hot-toast";


const TabButton = ({ isActive, onClick, label }) => (
  <button
    onClick={onClick}
    className={`px-5 md:px-7 py-1 rounded-md ${isActive ? 'bg-[#E43875] text-white' : ''}`}
  >
    {label}
  </button>
);

export default function AccountSettings() {
  const location = useLocation();


  const [activeTab, setActiveTab] = useState('Account');

  const tabConfig = {
    Account: <Account />,
    Security: <Security />,
    // 'User KYC': <UserKYC />,
    // 'Payment Details': <PaymentDetails />,
  };


  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get('profile');
  };


  useEffect(() => {
    const profileParam = getQueryParams();

    if (profileParam) {
      const formattedProfileParam = profileParam.replace(/-/g, ' ');
      if (Object.keys(tabConfig).includes(formattedProfileParam)) {
        setActiveTab(formattedProfileParam);
      }
    }
  }, [location.search]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const queryParam = tab.replace(/\s+/g, '-');
    window.history.pushState(null, '', `?profile=${queryParam}`);
  };

  return (
    <div>
     
      <div className="py-5 md:p-5 font-poppins text-white overflow-hidden overflow-y-auto">
        <p className="text-white/50">
          Account Settings <span className="text-white font-semibold">/ {activeTab}</span>
        </p>

        {/* Desktop Tab Buttons */}
        <div className="hidden md:block">
          <div className="flex gap-3 mt-5">
            {Object.keys(tabConfig).map((tab) => (
              <TabButton
                key={tab}
                isActive={activeTab === tab}
                onClick={() => handleTabClick(tab)}
                label={tab}
              />
            ))}
          </div>
        </div>

        {/* Mobile Tab Buttons */}
        <div className="block md:hidden">
          <div className="grid grid-cols-2 mt-5">
            {Object.keys(tabConfig).map((tab) => (
              <TabButton
                key={tab}
                isActive={activeTab === tab}
                onClick={() => handleTabClick(tab)}
                label={tab}
              />
            ))}
          </div>
        </div>

        {/* Render Content based on active tab */}
        <div className="mt-5">{tabConfig[activeTab]}</div>
      </div>
    </div>
  );
}
