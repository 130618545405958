import React, { useState,useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useSelector } from 'react-redux';

export default function LevelIncome() {
  const user = useSelector((state) => state.user);
  const [transactions, setTransactions] = useState([])
  const getRecords = async()=>{
    try{
        await axios.get(`${SERVER_URL}/api/getLevelIncome?id=${user[0].CusID}`).then((res)=>{
            console.log(res,'response');
            if(res.data.status == true){
               setTransactions(res.data.history)
            }else{
              setTransactions([])
            }
        })
    }catch(err){
        console.log(err)
        setTransactions([])
    }
}
useEffect(()=>{
    getRecords()
},[user])

  const tableEntries = [
    {
      transactionid: "87082942",
      level: "1",
      downlineid: "868716",
      date: "2024-09-10",
      time: "12:30 PM",
      description: "Level Bonus : MLO(868716 Lvl - 5 TrID : 87082942)",
      amount: "1.5",
      transferstatus: "towallet",
      lossamt: "0",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  // Determine the current entries to display
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  // Handle previous and next page clicks
  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(transactions.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle dropdown change for entities per page
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const openImageInNewTab = (entity) => {
    window.open(entity.doc, '_blank');
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  // Calculate pagination details
  const totalPages = Math.ceil(transactions.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const getLevel = (data) => {
    if (!data || typeof data !== 'string') {
      return {
        user: '',
        levels: '',
        transactionId: ''
      };
    }
  
    // Split by 'Lvl -' to separate downlineID and level
    const levelParts = data.split('Lvl -');
    if (levelParts.length < 2) {
      return {
        user: '',
        levels: '',
        transactionId: ''
      };
    }
  
    // Extract downlineID
    const downlinePart = levelParts[0].split('(');
    const downlineID = downlinePart[1].trim();
  
    // Extract levelNumber and transaction ID
    const levelAndTransaction = levelParts[1].split(' TrID :');
    const levelNumber = levelAndTransaction[0].trim();
    const transactionID = levelAndTransaction[1].replace(')', '').trim();  // Remove closing parenthesis if present
  
    return {
      user: downlineID,
      levels: levelNumber,
      transactionId: transactionID
    };
  };
  
  

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <p className='my-auto font-bold text-xl  uppercase'>Level Income
        </p>
        <div className='rounded-2xl mt-5'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
            <div className='md:w-[35%] flex gap-3'>
              {/* <input className='border rounded-md text-center bg-transparent w-[75%] py-1 outline-none placeholder:text-white' placeholder='Search by level'></input> */}
              {/* <button className='w-[25%] text-sm font-bold rounded-md bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md'>Show</button> */}
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
            <table className='w-full text-center  '>
              <thead className='text-sm uppercase font-bold border-b py-3 '>
                <tr>
                  <th className='py-3 px-2'>transaction id	</th>
                  <th className='px-2'>level no	</th>
                  <th className='px-2'>downline id</th>
                  <th className='px-2'>Date</th>
                  <th className='px-2'>description</th>
                  <th className='px-2'>amount</th>
                  <th className='px-2'>transfer status</th>
                </tr>
              </thead>
             <tbody className='divide-y'>
  {currentEntities.map((entity, index) => (
    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
      <td className='px-5 md:px-2'>{entity.TrID}</td>
      <td className='px-5 md:px-2 '>{entity.description ? getLevel(entity.description).levels : ''}</td>
      <td className='px-5 md:px-2'>{entity.description ? getLevel(entity.description).user : ''}</td>
      <td className='px-5 md:px-2'>{entity.dot ? formatDate(entity.dot) : ''}</td>
      <td className='px-5 md:px-2'>{entity.description}</td>
      <td className='px-5 md:px-2'>{entity.credit}</td>
      <td className='px-5 md:px-2 text-green-500'>SUCCESS</td>
    </tr>
  ))}

                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}
