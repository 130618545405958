import React, { useRef, useState, useEffect } from 'react'
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck, faPhone, faXmarkCircle, faCirclePlay, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'
import toast from "react-hot-toast";
import { SERVER_URL } from "../../config";
// import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import axios from 'axios';



Aos.init();

export default function Exclusive() {

    const CustomPrevArrow = ({ onClick }) => (
        <div onClick={onClick} className="absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-5 cursor-pointer z-10">
            <FontAwesomeIcon icon={faArrowLeft} className='bg-black duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736] text-white rounded-full p-2' />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div onClick={onClick} className="absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-5 cursor-pointer z-10">
            <FontAwesomeIcon icon={faArrowRight} className='bg-black duration-500 hover:bg-gradient-to-r from-[#E43875] to-[#F98736] text-white rounded-full p-2' />
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const exploreSectionRef = useRef(null);
    const scrollToExploreSection = () => {
        exploreSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const navigate = useNavigate();

    const handleInetrest = () => {
        navigate('/Membership', { state: { scrollToSection: 'sec-3' } });
    };

    const user = useSelector((state) => state.user);
    const email = user ? user[0].email : '';
    const CusID = user ? user[0].CusID : '';

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]+$/;

        if (!formData.firstName.trim()) {
            toast.error('First name is required.');
            return;
        }

        if (!formData.lastName.trim()) {
            toast.error('Last name is required.');
            return;
        }

        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            toast.error('A valid email address is required.');
            return;
        }

        if (!formData.phoneNumber.trim() || !phoneRegex.test(formData.phoneNumber)) {
            toast.error('Mobile number should contain only numbers.');
            return;
        }

        if (!formData.subject.trim()) {
            toast.error('Subject is required.');
            return;
        }

        if (!formData.message.trim()) {
            toast.error('Message is required.');
            return;
        }

        try {
            console.log("Sending form data:", formData);
            const response = await fetch(`${SERVER_URL}/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            console.log("Server response:", result);

            if (response.ok) {
                toast.success('We will contact you soon!');
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
            } else {
                toast.error(result.error || 'Error sending message.');
            }
        } catch (error) {
            console.error("Submit error:", error);
            toast.error('Error sending message.');
        }
    };

    const handleClick = () => {
        console.log("CusID:", CusID);

        if (!CusID) {
            console.error("CusID not available");
            toast.error('Please Login to use MBC Exclusive');
            return;
        }

        try {
            window.open(`https://skilleareum.ai/redirect?CusID=${CusID}`, "_blank");
        } catch (error) {
            console.error("Error handling click event:", error);
        }
    };

    const [videoplay, setvideoplay] = useState(false);
    const [videoplay1, setvideoplay1] = useState(false);
    const [videoplay2, setvideoplay2] = useState(false);

    const handleVideoplay = () => {
        setvideoplay(!videoplay)
    }
    const handleVideoplay1 = () => {
        setvideoplay1(!videoplay1)
    }
    const handleVideoplay2 = () => {
        setvideoplay2(!videoplay2)
    }


    const [showTooltip, setShowTooltip] = useState(false);
    const closeTooltip = () => {
        setShowTooltip(false);
    };


    useEffect(() => {
        const hasVisited = sessionStorage.getItem('SkilleareumVideo');
        if (!hasVisited) {
            setShowTooltip(true);
            sessionStorage.setItem('SkilleareumVideo', 'true');
        }
    }, []);

    const upcomingproduct = [
        {
            heading: "Travel Portal App",
            details: "Private access to guaranteed best hotel and cruise rates online.",
        },
        {
            heading: "Safe Bot App",
            details:
                "Automated trading system with full custody. Proven track record averaging over 100% annual returns.",
        },
        {
            heading: "Copy Traders",
            details:
                "Allows members to follow professional traders for crypto or forex trading. Maintains full asset custody for users.",
        },
        {
            heading: "Ainstein",
            details:
                "A hands-free autopilot trading system leveraging AI for low-risk crypto trading.",
        },
        {
            heading: "Bitcoin Mining",
            details:
                "High-transparency mining with institutional-grade facilities. Minimum investment: $1 million.",
        },
        {
            heading: "Tokenization",
            details:
                "Trade and fractional ownership of real-world assets (properties, yachts, etc.) through NFTs.",
        },
        {
            heading: "DNA Behavioral Genetics Test",
            details:
                "Personalized insights into genetic influences on personality and entrepreneurial tendencies. Includes additional DNA-based nutrition plans and supplements.",
        },
    ];

    const [showAll, setShowAll] = useState(false);

    const displayedProducts = showAll ? upcomingproduct : upcomingproduct.slice(0, 6);




    return (
        <div className=' font-poppins text-white overflow-hidden'>
            <div className='fixed inset-0 z-0'>
                <img src='/assets/NewMBCbg.png' className='h-full w-full object-cover object-top'></img>
            </div>
            <div className='relative z-10'>
                <Header />
                <div className='sec-1 pt-28 pb-10 min-h-screen grid content-center'>
                    <div className='w-[90%] mx-auto mt-2 pb-5 flex flex-col md:flex-row gap-3 md:gap-0'>
                        <div className='font-bold w-[100%] flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center'>
                            <p className='text-3xl text-center text-shadow-custom' data-aos="fade-right">Our Exclusive Partnered Products</p>
                            <div className='flex justify-center md:justify-start'>
                                <a href='/Login'>
                                    <div className=' bg-white p-[3px] rounded-full group flex overflow-hidden relative cursor-pointer' data-aos="fade-left" data-aos-duration="2500">
                                        <button className='bg-black  text-[#F98736] rounded-full -translate-x-10 -rotate-45 duration-500 group-hover:translate-x-0 absolute w-8 h-8 grid place-content-center '><FontAwesomeIcon icon={faArrowRight} /></button>
                                        <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                            <button className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>Register Now</button>
                                            <button className='bg-black rounded-full  text-[#F98736] h-8 w-8 grid place-content-center -rotate-45'><FontAwesomeIcon icon={faArrowRight} /></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='w-[90%] mx-auto flex flex-col md:flex-row items-center justify-around gap-5 md:gap-0'>
                        <div className='md:w-[50%] flex justify-center'>

                            {/*<p className='font-bold  text-3xl uppercase' data-aos="fade-right" data-aos-duration="1000">Ainstein.Ai</p>
                            <ul className='mt-3'>
                                <li data-aos="fade-right" data-aos-duration="1250">1. The World’s First Zero-Loss Arbitrage AI</li>
                                <li data-aos="fade-right" data-aos-duration="1500">2. Real-Time Profits: Train AI to identify arbitrage opportunities. </li>
                                <li data-aos="fade-right" data-aos-duration="1750">3. Secure Transactions: No drawdowns, no losses—only profits. </li>
                                <li data-aos="fade-right" data-aos-duration="2000">4. Full Transparency: Complete visibility into all transactions.</li>
                            </ul>*/}
                        </div>
                    </div>
                    <div className='mt-5 w-[90%] mx-auto flex flex-col md:flex-row items-center justify-around gap-5 md:gap-0' >
                        <div className='md:w-[30%]'>
                            <img src='/assets/SKILL 30 SEPT.jpg' className='mx-auto border rounded-xl' data-aos="zoom-in" data-aos-duration="1250"></img>
                            <div className='flex justify-center mt-3'>
                                <div className='flex gap-2 px-3 rounded-full border bg-white/25' data-aos="fade-right"><FontAwesomeIcon icon={faCircle} className='text-[5px] text-[#00ff00] my-auto' />Live Now</div>
                            </div>
                        </div>
                        <div className='md:w-[50%]'>
                            <div className='flex md:flex-row flex-col justify-start' data-aos="fade-right"
                                data-aos-duration="1000">
                                <div className='flex items-center gap-4'>
                                    <p className='font-bold  text-3xl capitalize cursor-pointer' data-aos="fade-right" data-aos-duration="1000" >Skilleareum.ai</p>
                                    {/*<FontAwesomeIcon className='cursor-pointer w-10 h-10 ' onClick={handleVideoplay} icon={faCirclePlay} />*/}
                                </div>
                                {/* {showTooltip && (
                                    <div className="relative md:mt-0 mt-3" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="absolute left-3 top-9 transform -translate-y-1/2">
                                            <div
                                                style={{ clipPath: 'polygon(100% 50%, 0% 0%, 0% 100%)' }}
                                                className="bg-white/20 w-5 h-5 rotate-180"
                                            ></div>
                                        </div>

                                        <div className="relative md:pl-8 md:-top-0 bottom-0 -pl-10">
                                            <div className=" z-50 p-4  bg-white/20 backdrop-blur-lg text-white rounded-md shadow-lg">
                                                <button className="text-white text-lg font-bold rotate-45 absolute top-0 right-2" onClick={closeTooltip}>
                                                    +
                                                </button>
                                                <p className="text-xs 2xl:text-sm text-center font-semibold">
                                                    Having doubts about connecting in Skilleareum? Check out this video!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className="relative mt-5">
                                <div className="absolute top-0 left-2 h-full border  border-dotted border-gray-200" data-aos="zoom-in" data-aos-duration="1000"></div>
                                <div className="space-y-8">
                                    <div className="relative pl-10">
                                        <div className="absolute left-0 top-0 w-5 h-5 bg-black rounded-full border-2 border-white" data-aos="zoom-in" data-aos-duration="1000"></div>
                                        <p className="text-sm" data-aos="fade-right" data-aos-duration="1250">
                                            The World’s First Learn & Earn Social–EduFi DAO Platform.
                                        </p>
                                        <hr className="mt-4 border-gray-300" />
                                    </div>
                                    <div className="relative pl-10">
                                        <div className="absolute left-0 top-0 w-5 h-5 bg-black rounded-full border-2 border-white" data-aos="zoom-in" data-aos-duration="1000"></div>
                                        <p className="text-sm" data-aos="fade-right" data-aos-duration="1500">
                                            Token Incentives: Earn tokens by completing educational courses.
                                        </p>
                                        <hr className="mt-4 border-gray-300" />
                                    </div>
                                    <div className="relative pl-10">
                                        <div className="absolute left-0 top-0 w-5 h-5 bg-black rounded-full border-2 border-white" data-aos="zoom-in" data-aos-duration="1000"></div>
                                        <p className="text-sm" data-aos="fade-right" data-aos-duration="1750">
                                            Global Learning Ecosystem: Participate in a decentralized, unbiased
                                            learning environment.
                                        </p>
                                        <hr className="mt-4 border-gray-300" />
                                    </div>
                                    <div className="relative pl-10">
                                        <div className="absolute left-0 top-0 w-5 h-5 bg-black rounded-full border-2 border-white" data-aos="zoom-in" data-aos-duration="1000"></div>
                                        <p className="text-sm" data-aos="fade-right" data-aos-duration="2000">
                                            Instantaneous Rewards: Tokenize every learning achievement.
                                        </p>
                                        <hr className="mt-4 border-gray-300" />
                                    </div>
                                    <div className="relative pl-10">
                                        <div className="absolute left-0 top-0 w-5 h-5 bg-black rounded-full border-2 border-white" ></div>
                                        <p className="text-sm">
                                            Unlock the Benefits of Skilleareum Today!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className='mt-5 font-bold' data-aos="fade-right" data-aos-duration="1250">
                                How to download Telegram?
                            </div>

                            <div className="button-container mt-3 flex justify-start gap-4" data-aos="fade-right" data-aos-duration="1250">
                                <button
                                    className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                                    onClick={handleVideoplay2}>
                                    Windows
                                </button>
                                <button
                                    className="toggle-button border-2 border-white text-white py-3 px-6 text-lg font-medium rounded-md hover:bg-white hover:text-black transition-all duration-300"
                                    onClick={handleVideoplay1}>
                                    Mac OS
                                </button>
                            </div>*/}


                        </div>
                    </div>
                    {/* <div className='mt-5 w-[90%] mx-auto text-center text-lg leading-10'>
                        <p><span class='font-bold'>Unlock the Benefits of MBC- Skilleareum Today!<br/>
                        To enjoy Skilleareum’s exclusive benefits, become a member of MBC<br/></span>
                            <span class='font-bold'><a href='/Signup' className='hover:underline duration-500'>“Join Now”</a> CTA(should navigate to sign up)</span> and take the first step toward a rewarding fu</p>
                    </div> */}
                    <p className='text-center mt-7 w-[90%] mx-auto'><span className='font-bold'>Note:</span> To enjoy Skilleareum’s exclusive benefits, become a member of MBC.</p>
                </div>
                {videoplay && (
                    <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                        <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                            <div className='flex justify-end'>
                                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                            </div>
                            <iframe
                                width="100%"
                                height="500"
                                src="/assets/Intro/skilleareum MBC.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ borderRadius: '15px', overflow: 'hidden' }}
                            ></iframe>
                        </div>
                    </div>
                )}
                {videoplay1 && (
                    <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                        <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                            <div className='flex justify-end'>
                                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay1} icon={faXmarkCircle} />
                            </div>
                            <iframe
                                width="100%"
                                height="500"
                                src="/assets/Intro/Mac tele login comp.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ borderRadius: '15px', overflow: 'hidden' }}
                            ></iframe>
                        </div>
                    </div>
                )}
                {videoplay2 && (
                    <div className='z-50 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                        <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                            <div className='flex justify-end'>
                                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay2} icon={faXmarkCircle} />
                            </div>
                            <iframe
                                width="100%"
                                height="500"
                                src="/assets/Intro/windows tele login comp.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ borderRadius: '15px', overflow: 'hidden' }}
                            ></iframe>
                        </div>
                    </div>
                )}
                <div ref={exploreSectionRef} className='py-10'></div>
                {/*<div className='sec-2 py-10 min-h-screen' >
                    <div className='w-[90%] md:w-[75%] mx-auto' >
                        <p className='font-bold  text-2xl md:text-4xl lg:text-5xl leading-12 capitalize text-shadow-custom' data-aos="fade-up" data-aos-duration="1000">Exciting Projects <br /> Coming Soon</p>
                        <hr className='w-[35%]' data-aos="fade-right" data-aos-duration="1000"></hr>
                    </div>
                    <div className='w-[90%] md:w-[75%] mx-auto'>
                        <p className='mt-7 font-bold text-xl text-shadow-custom' data-aos="fade-right" data-aos-duration="1250">Together We Are Stronger Community</p>
                        <div data-aos="fade-up" data-aos-duration="1500">
                            <Slider className='my-5 w-[90%] md:w-auto mx-auto' {...settings}>
                                <div className='px-5'>
                                    <div className='flex flex-col gap-2 text-center'>
                                        <img src='/assets/C1.png' className='mx-auto'></img>
                                        <p className='text-lg mt-2 font-bold'>Blockchain Fitness App</p>
                                        <p className='text-sm '>Move to earn tokens.</p>
                                    </div>
                                </div>
                                <div className='px-5'>
                                    <div className='flex flex-col gap-2 text-center'>
                                        <img src='/assets/C2.png' className='mx-auto'></img>
                                        <p className='text-lg mt-2 font-bold'>Bitcoin Mining NFTs</p>
                                        <p className='text-sm '>Own B2B Containers Min. $1 Million</p>
                                    </div>
                                </div>
                                <div className='px-5'>
                                    <div className='flex flex-col gap-2 text-center'>
                                        <img src='/assets/C3.png' className='mx-auto'></img>
                                        <p className='text-lg mt-2 font-bold'>Passive CRYPTO & FX</p>
                                        <p className='text-sm '>Master traders, full custody</p>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>*/}
                <div className='sec-2 mt-10 overflow-hidden'>
                    <div className='w-[90%] mx-auto'>
                        <div className='font-bold w-[100%] flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center'>
                            <p className='text-3xl text-center text-shadow-custom' data-aos="fade-right">Our Upcoming Partnered Products </p>
                            <div className='flex justify-center md:justify-start'>
                                <div className=' bg-white p-[3px] rounded-full group flex overflow-hidden relative cursor-pointer' onClick={() => setShowAll(true)} data-aos="fade-left" data-aos-duration="2500">
                                    <button className='bg-black  text-[#F98736] rounded-full -translate-x-10 -rotate-45 duration-500 group-hover:translate-x-0 absolute w-8 h-8 grid place-content-center '><FontAwesomeIcon icon={faArrowRight} /></button>
                                    <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                        <button className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>View More</button>
                                        <button className='bg-black rounded-full  text-[#F98736] h-8 w-8 grid place-content-center -rotate-45'><FontAwesomeIcon icon={faArrowRight} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid md:grid-cols-2 gap-5 mt-7'>
                            {displayedProducts.map((item, index) => (
                                <div key={index} className="flex justify-center">
                                    <div
                                        className="lg:w-[80%] text-black bg-gradient-to-r from-[#E6DBFF] via-[#F6F6F6] to-[#D8D7FF] rounded-xl p-7 2xl:p-10 shadow-md relative"
                                        data-aos="fade-up"
                                    >
                                        <div className="flex justify-end">
                                            <button className="w-6 h-6 text-sm rounded-full bg-white shadow-md absolute right-2 top-2">
                                                <FontAwesomeIcon icon={faArrowRight} className="-rotate-45 text-black" />
                                            </button>
                                        </div>
                                        <h1 className="text-xl font-semibold">{item.heading}</h1>
                                        <p className="mt-2 2xl:mt-4 text-sm 2xl:text-base">{item.details}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='sec-3 pt-10 pb-10 md:pb-0 overflow-hidden'>
                    <p className='w-[80%] mx-auto text-center font-bold  text-2xl md:text-4xl lg:text-5xl capitalize text-shadow-custom' data-aos="fade-up" data-aos-duration="1000">Access Exclusive Member-Only Benefits</p>
                    <div className='flex flex-col md:flex-row mt-10 gap-10  w-[90%] md:w-[100%] mx-auto md:mx-0'>
                        <div className='md:w-[50%] flex justify-center md:justify-end ' data-aos="zoom-in" data-aos-duration="1000">
                            <img src='/assets/Group 1597883159.png' className='w-[90%] hidden md:block'></img>
                            <img src='/assets/ExclusiveMB.png' className='w-[90%] block md:hidden'></img>
                        </div>
                        <div className='md:w-[50%] tracking-wider'>
                            <div className='flex justify-start'><p className='font-bold text-xl text-shadow-custom pb-1 border-b ' data-aos="fade-left" data-aos-duration="1250">A Trusted Wealth & Wellness Club</p></div>
                            <ul className='mt-10 flex flex-col gap-3 font-bold '>
                                <li className='flex gap-3' data-aos="fade-left" data-aos-duration="1500"><span className='bg-white rounded-full px-1 py-[4px] font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheck} className='text-xs px-1 py-[3px] bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full' /></span> Exclusive Library of Books</li>
                                <li className='flex gap-3' data-aos="fade-left" data-aos-duration="1750"><span className='bg-white rounded-full px-1 py-[4px] font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheck} className='text-xs px-1 py-[3px] bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full' /></span> Exclusive Podcast series</li>
                                <li className='flex gap-3' data-aos="fade-left" data-aos-duration="2000"><span className='bg-white rounded-full px-1 py-[4px] font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheck} className='text-xs px-1 py-[3px] bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full' /></span> Exclusive Business Meetings</li>
                                <li className='flex gap-3' data-aos="fade-left" data-aos-duration="2500"><span className='bg-white rounded-full px-1 py-[4px] font-bold my-auto flex items-center'><FontAwesomeIcon icon={faCheck} className='text-xs px-1 py-[3px] bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full' /></span> Exclusive newsletter</li>
                            </ul>
                            <div className='flex justify-center md:justify-start  mt-10'>
                                <div className=' bg-white p-[3px] rounded-full group flex overflow-hidden relative cursor-pointer' data-aos="fade-left" data-aos-duration="2500" onClick={handleInetrest}>
                                    <FontAwesomeIcon icon={faArrowRight} className='bg-black  text-[#F98736] rounded-full -translate-x-10 -rotate-45 duration-500 group-hover:translate-x-0 px-[10px] py-2 absolute' />
                                    <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                        <button className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>Join us Now</button>
                                        <FontAwesomeIcon icon={faArrowRight} className='bg-black rounded-full px-[10px] text-[#F98736] py-2 -rotate-45' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-4 pb-10 md:pb-24'>
                    <div className='flex flex-col md:flex-row w-[85%] mx-auto'>
                        <div className='md:w-[50%] relative md:flex md:items-end' data-aos="fade-up" data-aos-duration="1000">
                            <div><img src='/assets/image5000.png'></img></div>
                            <div className='bg-white/50 px-5 py-7 md:p-10 rounded-b-2xl rounded-r-2xl md:absolute backdrop-blur-md -translate-y-10 md:translate-y-10'>
                                <div className='flex flex-col md:flex-row gap-0 md:gap-5'>
                                    <div><FontAwesomeIcon icon={faEnvelope} className='text-[#E43875] bg-white px-3 py-3 rounded-full my-auto border shadow-md' /></div>
                                    <div className='flex flex-col text-lg'>
                                        <p className=' bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736] text-transparent font-bold'>My Business Club</p>
                                        <p className='bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736] text-transparent font-medium'>support@mybusinessclub.com <br />mybusinessclubinfo@gmail.com</p></div>
                                </div>
                                <div className='flex gap-5'>
                                    {/* <div><FontAwesomeIcon icon={faPhone} className='text-[#E43875] bg-white px-3 py-3 rounded-full my-auto border shadow-md' /></div> */}
                                    {/* <div className='my-auto'><p className='bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736] text-transparent '>+64 1233 4567 890</p></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='md:w-[50%]' data-aos="fade-up" data-aos-duration="1250">
                            <div className='w-full h-full md:px-10 py-7 border-2 rounded-2xl md:-translate-x-14 md:translate-y-14 bg-gradient-to-b from-[#E43875] to-[#F98736]'>
                                <p className='font-bold  text-3xl md:text-5xl text-center uppercase'>Contact us</p>
                                <form onSubmit={handleSubmit} className='w-[75%] mx-auto my-7 flex flex-col gap-3 text-[#F23985] font-semibold'>
                                    <div className='flex gap-2'>
                                        <input
                                            name='firstName'
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className='w-[50%] py-2 rounded px-3 outline-none'
                                            placeholder='First Name*'
                                            required
                                        />
                                        <input
                                            name='lastName'
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className='w-[50%] py-2 rounded px-3 outline-none'
                                            placeholder='Last Name*'
                                            required
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <input
                                            name='email'
                                            value={formData.email}
                                            onChange={handleChange}
                                            className='w-[50%] py-2 rounded px-3 outline-none'
                                            placeholder='Email*'
                                            type='email'
                                            required
                                        />
                                        <input
                                            name='phoneNumber'
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            className='w-[50%] py-2 rounded px-3 outline-none'
                                            placeholder='Phone Number*'
                                            type='tel'
                                            required
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name='subject'
                                            value={formData.subject}
                                            onChange={handleChange}
                                            className='w-[100%] py-2 rounded px-3 outline-none'
                                            placeholder='Subject*'
                                            required
                                        />
                                    </div>
                                    <div>
                                        <textarea
                                            name='message'
                                            value={formData.message}
                                            onChange={handleChange}
                                            className='w-[100%] py-2 rounded px-3 h-[120px] outline-none'
                                            placeholder='Message*'
                                            required
                                        />
                                    </div>
                                    <div className='flex justify-center text-white'>
                                        <div className='bg-white p-[3px] cursor-pointer rounded-full my-auto group flex overflow-hidden relative'>
                                            <FontAwesomeIcon icon={faArrowRight} className='bg-black text-[#F98736] rounded-full -translate-x-10 duration-500 group-hover:translate-x-0 px-[10px] py-2 absolute' />
                                            <div className='flex gap-2 duration-500 group-hover:translate-x-10'>
                                                <button type='submit' className='py-1 px-3 rounded-full font-bold bg-gradient-to-r from-[#E43875] to-[#F98736]'>Send Message</button>
                                                <FontAwesomeIcon icon={faArrowRight} className='bg-black text-[#F98736] rounded-full px-[10px] py-2 ' />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
