import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';

export default function Suspensionid() {

    const admin = useSelector((state) => state.admin);
    const adminID = admin[0] ? admin[0].adminID : '';

    const [currentPage, setCurrentPage] = useState(1);
    const [suspendedStatus, setSuspendedStatus] = useState("");
    const [cusID, setCusID] = useState('');
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [tableEntries, setTableEntries] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editableUser, setEditableUser] = useState(null);


    const fetchAllUsers = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getAllUsers`);
            if (response.data.status) {
                setTableEntries(response.data.users);
                setAllUsers(response.data.users);
            }
        } catch (error) {
            console.error('Error fetching all users:', error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);


    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleListButtonClick = () => {
        if (suspendedStatus) {
            const status = suspendedStatus === "Suspended" ? 1 : 0;
            const filteredUsers = allUsers.filter(user => user.suspend === status.toString());
            setTableEntries(filteredUsers);
        } else {
            setTableEntries(allUsers);
        }
    };

    const handleSubmit = () => {
        const filtered = allUsers.filter(user => {
            const matchesCustId = cusID ? user.CusID.toString().includes(cusID) : true;
            return matchesCustId;
        });
        setTableEntries(filtered);
        setCurrentPage(1);
    };

    const handleUpdateClick = (user) => {
        setIsUpdate(true);
        setEditableUser(user);
    };

    const handleSaveClick = async () => {
        try {
            const updatedUser = { ...editableUser };
            const response = await axios.post(`${SERVER_URL}/api/updateSuspendStatus`, {
                status: editableUser.suspended,
                CusID: editableUser.CusID,
                adminID : adminID
            });

            if (response.data.status === true) {
                toast.success('Updated Successfully!')
                fetchAllUsers();
                setIsUpdate(false);
                setEditableUser(null);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleCancelClick = () => {
        setIsUpdate(false);
        setEditableUser(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>Suspended ID</h1>
                <div className='rounded-lg mt-5 border'>
                    <div className='px-5 py-3 border-b font-bold text-lg'>
                        <p>Customer List ( Showing {suspendedStatus} Customers )</p>
                    </div>
                    {isUpdate ? (
                        <div className='rounded-lg mt-5 border'>
                            <div className='flex flex-col gap-3 w-[100%] lg:w-[80%] mx-auto px-5 py-3'>
                                {/* Page Name Input */}
                                <div className='flex flex-col md:flex-row gap-3 items-center'>
                                    <p className='text-end font-bold md:w-[30%]'>PageName</p>
                                    <input
                                        className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'
                                        type='text'
                                        name='CusID'
                                        value={editableUser.CusID}
                                        onChange={handleInputChange}
                                        readOnly
                                    />
                                </div>

                                {/* Message Input */}
                                <div className='flex flex-col md:flex-row gap-3'>
                                    <p className='text-center md:text-end font-bold md:w-[30%] md:pt-3'>Message</p>
                                    <textarea
                                        className='md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'
                                        name='email'
                                        value={editableUser.email}
                                        onChange={handleInputChange}
                                        readOnly
                                    ></textarea>
                                </div>

                                {/* Permission Dropdown */}
                                <div className='flex flex-col md:flex-row gap-3 items-center'>
                                    <p className='text-end font-bold md:w-[30%]'>Status</p>
                                    <select
                                        className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'
                                        name='suspended'
                                        value={editableUser.suspended}
                                        onChange={handleInputChange}
                                    >
                                        <option className='text-black' value={0}>Not Suspended</option>
                                        <option className='text-black' value={1}>Suspended</option>
                                    </select>
                                </div>

                                {/* Buttons */}
                                <div className='flex gap-3 justify-center'>
                                    <button
                                        className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                        onClick={handleSaveClick}
                                    >
                                        Update Details
                                    </button>
                                    <button
                                        className='px-5 py-2 text-sm border border-white/50 font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                            <div className='text-sm font-bold m-auto'>
                                Total Record: {currentEntities.length}
                            </div>
                            <div className='flex md:grid gap-1 m-auto'>
                                <p className='text-sm font-bold'>Customer Type</p>
                                <select
                                    className='bg-transparent border rounded-md py-1 w-[100%] text-center outline-none'
                                    onChange={(e) => setSuspendedStatus(e.target.value)}
                                    value={suspendedStatus}
                                >
                                    <option className='text-black' value="Suspended">Suspended</option>
                                    <option className='text-black' value="Not Suspended">Not Suspended</option>
                                </select>
                                <div>
                                    <button
                                        className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                        onClick={handleListButtonClick}
                                    >
                                        List
                                    </button>
                                </div>
                            </div>
                            <div className='flex md:grid gap-1 m-auto'>
                                <div className='flex items-center gap-1 text-sm text-nowrap'>
                                    <p className='font-bold'>CusID:</p>
                                    <input
                                        className='w-[50%] border rounded-md bg-transparent py-1'
                                        value={cusID}
                                        onChange={(e) => setCusID(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button
                                        className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                                <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page:</p>
                                <div className='w-[25%] md:w-[100%]'>
                                    <input
                                        className='w-[100%] border bg-transparent rounded-md py-1'
                                        type="number"
                                        value={entitiesPerPage}
                                        onChange={(e) => setEntitiesPerPage(parseInt(e.target.value) || 5)}
                                    />
                                </div>
                            </div>
                            <div className='text-sm font-bold m-auto'>
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    )}
                </div>
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div></div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>Ref</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>Email</th>
                                    <th className='px-2'>Cust UserName</th>
                                    <th className='px-2'>Entry Date</th>
                                    <th className='px-2'>status</th>
                                    <th className='px-2'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{index + 1}</td>
                                        <td className='px-5 md:px-2'>{entity.ref}</td>
                                        <td className='px-5 md:px-2'>{entity.CusID}</td>
                                        <td className='px-5 md:px-2'>{entity.email}</td>
                                        <td className='px-5 md:px-2'>{entity.username}</td>
                                        <td className='px-5 md:px-2'>{entity.Entry_Date === null ? entity.created_at : entity.Entry_Date}</td>
                                        <td className='px-5 md:px-2'>{entity.suspend}</td>
                                        <td>
                                            <button
                                                className='px-3 py-1 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                                onClick={() => handleUpdateClick(entity)}
                                            >
                                                Update
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {emptyRowCount > 0 &&
                                    Array(emptyRowCount).fill(null).map((_, index) => (
                                        <tr key={`empty-${index}`} className="empty-row">
                                            <td className="py-2">&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}