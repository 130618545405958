import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import axios from 'axios';
import { SERVER_URL } from '../../../config';

const test = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED"
  }
};

const renderCustomNode = ({ nodeDatum, toggleNode }) => (
  <g style={{ cursor: 'pointer' }} onClick={() => toggleNode()}>
    <circle r={7} fill="#000000" />
    <foreignObject x="-100" y="-55" width="200" height="200">
      <img
        src={nodeDatum.image || 'https://img.icons8.com/?size=100&id=aZ0WjSq1u1YB&format=png&color=000000'}
        width="40"
        height="40"
        style={{ marginBottom: '5px', borderRadius: '50%', display: 'block', margin: '0 auto' }}
        alt={nodeDatum.name}
      />
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '10px',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
          textAlign: 'center'
        }}
      >
        <p className=' text-black uppercase' style={{ fontSize: '14px', fontWeight: 'bold', margin: 0 }}>
          {nodeDatum.name}
        </p>
        <p className='text-black/70' style={{ fontSize: '12px' }}>
          Referrer ID: {nodeDatum.attributes.referrerId}
        </p>
      </div>
    </foreignObject>
  </g>
);

function UserVisual({ CusID }) {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    axios.get(`${SERVER_URL}/api/getMLMtree?CusID=${CusID}`)
      .then((response) => {
        setTreeData([response.data]);
      })
      .catch((error) => {
        console.error('Error fetching tree data:', error);
      });
  }, [CusID]);

  return (
    <div id="treeWrapper" className="flex items-center justify-center" style={{ width: "100%", height: "88vh" }}>
      {treeData.length > 0 ? (
        <Tree
          data={treeData}
          renderCustomNodeElement={renderCustomNode}
          nodeSvgShape={test}
          pathFunc="step"
          separation={{ siblings: 2, nonSiblings: 2 }}
          orientation="vertical"
          translate={{ x: 550, y: 250 }}
          allowForeignObjects={true}
          initialDepth={1}
        />
      ) : (
        <p className="font-bold -translate-y-10">Loading...</p>
      )}
    </div>
  );
}

export default UserVisual;
