import React from 'react'
import CountUp from 'react-countup'
import Header from '../Layout/Header2'

export default function Businessclub() {
    return (
        <div>
        <Header/>
        <section className="flex justify-center items-center min-h-screen bg-white text-center font-poppins pt-28 pb-10">
            <div>
                <div className='flex justify-center mb-4'>
                    <div className='relative'>
                        <div className='mx-auto w-32 py-1 rounded-full font-bold border-2 border-black'>Welcome To</div>
                        <div className='absolute w-[2px] h-4 bg-black -top-5 right-3' data-aos='fade-down'></div>
                        <div className='absolute w-[2px] h-5 bg-black -top-[22px] right-1' data-aos='fade-down' style={{ rotate: '25deg' }}></div>
                        <div className='absolute w-[2px] h-3 bg-black -top-[10px] right-0' data-aos='fade-down' style={{ rotate: '50deg' }}></div>
                    </div>
                </div>
                <h1 className='text-2xl md:text-5xl mb-1 font-bold uppercase '> My <span className='text-gradient'>Business</span> club</h1>
                <p>The Future Of Business Community Is Us!</p>
                <div className='block md:hidden lg:block'>
                    <div className='w-[90%] mx-auto flex flex-col lg:flex-row gap-5 lg:gap-0 justify-between text-start mt-4'>
                        <div className='w-[100%] lg:w-[20%] grid content-around bussiness_club'>
                            <div>
                                <i className='fa-solid fa-quote-left text-2xl'></i>
                                <p className='font-medium'>we empower entrepreneurs through a community-centric model - an innovative referral program.</p>
                                <div className='flex justify-start mt-3'>
                                    <p className='font-bold  text-3xl uppercase text-gradient flex items-center'><CountUp end={2675} duration={2.5} separator=',' /><span className='text-xl'>+</span></p>
                                </div>
                                <p className='font-medium'>Community Users</p>
                            </div>
                            <div className='flex gap-3 mt-3 text-2xl'>
                                <a target='blank' href='https://www.facebook.com/profile.php?id=61559105265026'><i className='fa-brands fa-facebook cursor-pointer' data-aos="fade-up" data-aos-duration="1000" /></a>
                                <a target='blank' href='https://rb.gy/a1nli1'><i className='fa-brands fa-x-twitter cursor-pointer' data-aos="fade-up" data-aos-duration="1250" /></a>
                                <a target='blank' href='https://www.instagram.com/mybusinessclub_official/'><i className='fa-brands fa-instagram cursor-pointer' data-aos="fade-up" data-aos-duration="1500" /></a>
                                <a target='blank' href='https://rb.gy/7x2p32'><i className='fa-brands fa-linkedin cursor-pointer' data-aos="fade-up" data-aos-duration="1750" /></a>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[40%] flex items-center justify-center'>
                            <img src='/assets/MainHero.png' className='p-5 rounded-full bg-gradient-to-r from-[#E43875] to-[#F98736]' data-aos='flip-left'></img>
                        </div>
                        <div className='w-[100%] lg:w-[20%] grid content-around'>
                            <div>
                                <div className='flex gap-2 mb-4'>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1000"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1250"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1500"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1750"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="2000"></i>
                                </div>
                                <div className='flex justify-start items-center'>
                                    <p className='font-bold  text-4xl uppercase text-gradient'><CountUp end={10} duration={2.5} separator=',' /></p><span className='font-bold'>Years</span>
                                </div>
                                <p className='font-medium'>Of Business Expertise</p>
                                <svg width="150" height="24" viewBox="0 0 150 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0" y1="12" x2="130" y2="12" stroke="black" stroke-width="2" />
                                    <path d="M140 12 L130 6 V18 Z" fill="black" />
                                </svg>
                            </div>
                            <div className='flex justify-start'>
                                <div className='text-center'>
                                    <i className='fa-solid fa-arrow-down-long' />
                                    <p className='font-medium'>Scroll to Explore</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden md:block lg:hidden text-start w-[90%] mx-auto'>
                    <div className='flex justify-between mt-4'>
                        <div className='w-[50%] grid content-around'>
                            <div>
                                <i className='fa-solid fa-quote-left text-2xl'></i>
                                <p className='font-medium'>we empower entrepreneurs through a community-centric model - an innovative referral program.</p>
                                <div className='flex justify-start mt-5'>
                                    <p className='font-bold  text-3xl uppercase text-gradient flex items-center'><CountUp end={2675} duration={2.5} separator=',' /><span className='text-xl'>+</span></p>
                                </div>
                                <p className='font-medium'>Community Users</p>
                            </div>
                            <div className='flex gap-3 mt-3 text-2xl'>
                                <a target='blank' href='https://www.facebook.com/profile.php?id=61559105265026'><i className='fa-brands fa-facebook cursor-pointer' data-aos="fade-up" data-aos-duration="1000" /></a>
                                <a target='blank' href='https://rb.gy/a1nli1'><i className='fa-brands fa-x-twitter cursor-pointer' data-aos="fade-up" data-aos-duration="1250" /></a>
                                <a target='blank' href='https://www.instagram.com/mybusinessclub_official/'><i className='fa-brands fa-instagram cursor-pointer' data-aos="fade-up" data-aos-duration="1500" /></a>
                                <a target='blank' href='https://rb.gy/7x2p32'><i className='fa-brands fa-linkedin cursor-pointer' data-aos="fade-up" data-aos-duration="1750" /></a>
                            </div>
                        </div>
                        <div className='w-[30%] grid content-around'>
                            <div>
                                <div className='flex gap-2 mb-4'>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1000"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1250"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1500"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="1750"></i>
                                    <i className='fa-solid fa-star' data-aos="fade-up" data-aos-duration="2000"></i>
                                </div>
                                <div className='flex justify-start items-center'>
                                    <p className='font-bold  text-4xl uppercase text-gradient'><CountUp end={10} duration={2.5} separator=',' /></p><span className='font-bold'>Years</span>
                                </div>
                                <p className='font-medium'>Of Business Expertise</p>
                                <svg width="150" height="24" viewBox="0 0 150 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0" y1="12" x2="130" y2="12" stroke="black" stroke-width="2" />
                                    <path d="M140 12 L130 6 V18 Z" fill="black" />
                                </svg>
                            </div>
                            <div className='flex justify-start'>
                                <div className='text-center'>
                                    <i className='fa-solid fa-arrow-down-long' />
                                    <p className='font-medium'>Scroll to Explore</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[100%] flex items-center justify-center'>
                        <img src='/assets/MainHero.png' className='p-5 rounded-full bg-gradient-to-r from-[#E43875] to-[#F98736]' data-aos='flip-left'></img>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}
