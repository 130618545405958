import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../../config';

export default function Profile() {
    const admin = useSelector((state) => state.admin);
    const adminID = admin[0] ? admin[0].adminID : '';

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            toast.error('New password and confirm password do not match');
            return;
        }

        if (!oldPassword || !newPassword) {
            toast.error('All fields are required');
            return;
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/adminUpdatePassword`, {
                adminID,
                oldPassword,
                newPassword
            });

            if (response.data.success) {
                toast.success('Password changed successfully!');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Please enter correct old password!');
        }
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-xl md:text-2xl font-bold uppercase '>Change Password</h1>
                <div className='border rounded-md mt-2 font-poppins'>
                    <div className='px-5 py-3 border-b'>
                        <p className='font-bold'>Change Password (Regularly change your password for enhanced security!)</p>
                    </div>
                    <div className='px-5 py-3'>
                        <div className='w-[100%] lg:w-[80%] mx-auto flex flex-col gap-3'>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>Old Password</p>
                                <div className='relative w-[100%] md:w-[50%]'>
                                    <input
                                        type={showOldPassword ? "text" : "password"}
                                        className='bg-transparent py-2 px-3 outline-none border rounded-md w-full'
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className='absolute right-2 top-2'
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                    >
                                        <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>New Password</p>
                                <div className='relative w-[100%] md:w-[50%]'>
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        className='bg-transparent py-2 px-3 outline-none border rounded-md w-full'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className='absolute right-2 top-2'
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                    >
                                        <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row gap-3 items-center'>
                                <p className='text-end md:w-[30%] font-semibold'>Confirm Password</p>
                                <input
                                    type="password"
                                    className='bg-transparent py-2 px-3 outline-none w-[100%] md:w-[50%] border rounded-md'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className='flex justify-center'>
                                <button
                                    onClick={handleSubmit}
                                    className='px-5 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}