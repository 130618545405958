import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../../Style/UserDashboard.css';
import { SERVER_URL, MBCPayOut_ContractAddress } from '../../../config';
import { useSelector } from "react-redux";
import axios from "axios";
import { ethers } from "ethers";
import { MBCPayOut_abi } from '../../../ABI';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Whitelist() {

  const user = useSelector((state) => state.user);
  const email = user[0].email;
  const CusID = user[0].CusID;

  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [IncomeAddress, setIncomeAddress] = useState(null)
  const [transactions, setTransactions] = useState([]);
  const [Popup, setPopup] = useState(false);
  const [selectedClub, setSelectedClub] = useState('');
  const [Subscriptionplan, setSubscriptionplan] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({
    type: '',
    amount: 0,
    no: 0
  });

  const clubs = [
    { name: 'Club 2', number: 2 },
    { name: 'Club 6', number: 6 },
    { name: 'Club 26', number: 26 },
    { name: 'Club 80', number: 80 },
    { name: 'Club 200', number: 200 },
    { name: 'Club 500', number: 500 },
    { name: 'Club 1000', number: 1000 },
    { name: 'Club 2500', number: 2500 },
    { name: 'Club 5000', number: 5000 },
    { name: 'Club 10000', number: 10000 },
  ];

  const handleClubChange = (e) => {
    const selectedClubObj = clubs.find(club => club.name === e.target.value);
    setSelectedClub(selectedClubObj);
  };

  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?CusID=${CusID}`);
    if (response.data.status === true) {
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  const [whitelistStatus, setWhitelistStatus] = useState(false);

  const fetchWhitelistStatus = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/checkWhitelist?userAddress=${address}`);

      if (response.status === 200) {
        setWhitelistStatus(response.data.status)
        console.log('Whitelist Status:', response.data);
      } else {
        console.log('Address not found in whitelist');
      }
    } catch (error) {
      console.error('Error fetching whitelist status:', error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      fetchWhitelistStatus()
    }
  }, [address])


  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        let response = await axios.get(`${SERVER_URL}/api/fetchWhitelistHistory?CusID=${CusID}`);
        if (response.data.status === true) {
          setTransactions(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (CusID) {
      fetchSubscriptionHistory();
      fetchAddress()
    }
  }, [CusID]);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(transactions.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(transactions.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handleSubscriptionplan = (type, amount, no) => {

    if (IncomeAddress !== address) {
      toast.error("Please connect the correct Wallet");
      return false;
    }

    setSubscriptionplan(true);
    setSelectedSubscription({ type, amount, no });
  };

  const handleclose = () => {
    setSubscriptionplan(false);
    setSelectedSubscription({
      type: '',
      amount: 0,
      no: 0
    });
  };

  const confirmSubscription = async () => {
    try {
      setPopup(true);

      if (!isConnected) {
        toast.error("Please Connect Wallet First");
        setPopup(false);
        return false;
      }

      if (!selectedClub) {
        toast.error("Please select the club");
        setPopup(false);
        return false;
      }

      if (whitelistStatus !== 1) {
        toast.error("User is not whitelisted!");
        setPopup(false);
        return false;
      }


      const plan_id = selectedSubscription.no;

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const MBCContract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
      const gasPrice = await provider.getGasPrice();
      console.log(plan_id, selectedClub.number);

      const UpgradeWhitelist = await MBCContract.Whitelist_upgradePlan(plan_id, selectedClub.number, {
        gasPrice: ethers.utils.parseUnits(ethers.utils.formatUnits(gasPrice, "gwei"), "gwei"),
        gasLimit: 500000
      });
      await UpgradeWhitelist.wait();

      let hash = UpgradeWhitelist.hash;

      const responsePromise = axios.post(`${SERVER_URL}/api/UserWhitelist`, {
        CusID: CusID,
        userAddress: address,
        plan_id: selectedSubscription.no,
        club: selectedClub.number,
        trxhash: hash,
        email: email
      });

      await toast.promise(
        responsePromise,
        {
          loading: "Transaction is in progress...",
          success: (response) => {
            console.log(response, 'response');
            return response.data.status === true
              ? "Subscribed Successfully !!"
              : response.data.message || "Error during Subscription.";
          },
          error: (error) => {
            console.error("Error during Subscription:", error);

            if (error.response) {
              const statusCode = error.response.status;
              const errorMessage = error.response.data.message || "Error during Subscription.";

              switch (statusCode) {
                case 400: return "Bad Request: Please check your input data.";
                case 401: return "Unauthorized: Please check your credentials.";
                case 403: return "Forbidden: You do not have access to this resource.";
                case 404: return "Not Found: The subscription endpoint is incorrect.";
                case 500: return "Internal Server Error: Please try again later.";
                default: return errorMessage;
              }
            } else if (error.request) {
              return "No response from server: Please check your network connection.";
            } else {
              return `Error: ${error.message}`;
            }
          }
        },
        {
          success: { duration: 5000 },
          error: { duration: 5000 }
        }
      );

      setTimeout(() => {
        window.location.reload()
      }, 3000);
      setPopup(false);
    } catch (error) {
      setPopup(false);
      console.error("An unexpected error occurred:", error);
      toast.error("An unexpected error occurred.");
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const openImageInNewTab = (hash) => {
    if (hash) {
      window.open(`https://bscscan.com/tx/${hash}`, '_blank');
    }
  };

  return (
    <div>
      <ToastContainer position='top-center' />
      <div data-aos="fadeIn" data-aos-duration='2000'>
        <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
          {Subscriptionplan ? (
            <div className='mt-10 border rounded-md px-2 md:px-10 py-5 md:py-7'>
              <div>
                <div className='flex justify-between'>
                  <h1 className='text-xl font-bold'>Whitelist Now</h1>
                  <div className='cursor-pointer' onClick={handleclose}>
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
                <div className='w-[95%] md:w-[60%] lg:w-[40%] p-5 mx-auto mt-3'>
                  <p className='text-sm font-bold'>Information</p>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Subscription</p>
                    <p className='font-semibold 2xl:text-lg'>{selectedSubscription.type}</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Choose Club Type</p>
                    <select
                      className='bg-transparent w-full outline-none font-bold'
                      value={selectedClub.name}
                      onChange={handleClubChange}
                    >
                      <option className='font-bold' value="" disabled>
                        Select a Club
                      </option>
                      {clubs.map((club, index) => (
                        <option className='text-black bg-transparent' key={index} value={club.name}>
                          {club.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='flex justify-center mt-2'>
                    <button className=' bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-5 py-2 rounded-md ' onClick={() => { confirmSubscription(); }}>
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='mt-10 border rounded-md px-10 py-7 grid grid-cols-2 lg:grid-cols-4 gap-5'>
              {[
                { type: 'AFFILIATE', no: 1, amount: 7, img: '/assets/firstPlan.png' },
                { type: 'MEMBER', no: 2, amount: 50, img: '/assets/Starter.png' },
                { type: 'SILVER', no: 3, amount: 200, img: '/assets/StarterPackages_01.png' },
                { type: 'GOLD', no: 4, amount: 600, img: '/assets/StarterPackages_02.png' },
                { type: 'PLATINUM', no: 5, amount: 1500, img: '/assets/StarterPackages_03.png' },
                { type: 'EXECUTIVE', no: 6, amount: 3000, img: '/assets/StarterPackages_04.png' },
                { type: 'AMBASSADOR', no: 7, amount: 5000, img: '/assets/StarterPackages_05.png' },
                { type: 'PRESIDENT', no: 8, amount: 10000, img: '/assets/StarterPackages_06.png' },
              ].map((plan, index) => (
                <div className="flip-card mx-auto " key={index}>
                  <div className="flip-card-inner rounded-xl">
                    <div className="flip-card-front rounded-xl border flex items-center justify-center">
                      <img src={plan.img} className='' alt={plan.type} />
                    </div>
                    <div className="flip-card-back py-3 px-3 border grid content-end rounded-xl">
                      <h1 className='font-bold text-3xl'>${plan.amount}</h1>
                      <p className='mt-2 text-sm'>{plan.type}</p>
                      <button
                        className={` mt-5 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold w-full py-2 rounded-md`}
                        onClick={() => handleSubscriptionplan(plan.type, plan.amount, plan.no)}
                      >
                        Whitelist Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='rounded-2xl mt-10'>
            <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
              <div className=''>
                <p className='my-auto font-bold text-xl '>Whitelist History</p>
              </div>
              <div className='flex items-center'>
                <p className='text-end text-sm px-3 my-auto'>Show no of entity
                  <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                    <option className='text-black' value="5">5</option>
                    <option className='text-black' value="7">7</option>
                    <option className='text-black' value="10">10</option>
                  </select>
                </p>
              </div>
            </div>
            <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
              <table className='w-full text-center '>
                <thead className='uppercase font-bold text-sm border-b py-3'>
                  <tr>
                    <th className='py-3'>SI.No</th>
                    <th>Cust ID</th>
                    <th>Hash</th>
                    <th>Date</th>
                    <th>Package Amount</th>
                    <th>Package</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                      <td className='px-5 md:px-0 '>{index + 1}</td>
                      <td className='px-5 md:px-0'>{entity.CustID ?? entity.CusID}</td>
                      <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.TrID ?? entity.trx_hash)}>{(entity.TrID ?? entity.trx_hash).slice(0, 7)}...</td>
                      <td className='px-5 md:px-0'>{formatDate(entity.dot ?? entity.createdAt)}</td>
                      <td className='px-5 md:px-0'>$ {Number(entity.amount).toFixed(0)}</td>
                      <td className='px-5 md:px-0'>{entity.plan}</td>
                      <td className='px-5 md:px-0 text-green-500'>SUCCESS</td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      {Popup && (
        <div className='fixed z-30 inset-0 w-screen left-0 top-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20'>
          <div className='flex items-center gap-2 text-xl font-semibold'>
            <FontAwesomeIcon icon={faGear} className='fa-spin' />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </div>
  )
}