import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

export default function WithdrawTransfer() {

    const admin = useSelector((state) => state.admin);
    const adminID = admin[0] ? admin[0].adminID : '';


    const [tableEntries, setTableEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [editId, setEditId] = useState(null);
    const [formData, setFormData] = useState({ fromAmount: '', toAmount: '', dayName: '' });

    const fetchCusIDs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getAllCusIDs`);
            if (response.data.status === 'success') {
                console.log(response.data.data)
                const fetchedEntries = response.data.data.map((item, index) => ({
                    id: index + 1,
                    CusID: item.CusID, 
                    Type: "Withdrawal",
                    fromAmount: Number(item.fromWithdrawal) <= 0 ? "10" : item.fromWithdrawal,
                    toAmount: Number(item.toWithdrawal) <= 100 ? "100" : item.toWithdrawal,
                    dayName: item.withdrawalDay === '' ? "All Days" : item.withdrawalDay,
                    date: item.dateTime || "10/7/2023 6:38:01 AM",
                }));
                setTableEntries(fetchedEntries);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchCusIDs();
    }, []);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClick = (entity) => {
        setEditId(entity.id);
        setFormData({
            fromAmount: entity.fromAmount,
            toAmount: entity.toAmount,
            dayName: entity.dayName || '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDateTime = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleUpdateClick = async () => {
        const currentDateTime = formatDateTime(new Date());

        const updatedEntries = tableEntries.map((entry) =>
            entry.id === editId
                ? {
                    ...entry,
                    fromAmount: formData.fromAmount,
                    toAmount: formData.toAmount,
                    dayName: formData.dayName,
                    date: currentDateTime,
                }
                : entry
        );

        const CusID = updatedEntries.find(entry => entry.id === editId)?.CusID;

        try {
            let response = await axios.post(`${SERVER_URL}/api/updateUserDetails`, {
                fromAmount: formData.fromAmount,
                toAmount: formData.toAmount,
                dayName: formData.dayName,
                dateTime: currentDateTime,
                CusID,
                adminID
            });
            if (response.data.status === 'success') {
                toast.success('User details updated successfully');
                fetchCusIDs();
                setEditId(null);
            } else {
                toast.error('Error in User details updating');
            }

        } catch (error) {
            console.error('Error updating user details:', error);
            toast.error('Error in User details updating');
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
    };

    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase '>
                    Set Withdrawal/Transfer Fund Limit
                </h1>
                <div className='rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
                        <div>
                            <p className='my-auto font-bold text-lg'>
                                Withdrawal/Transfer Fund Limit Setting
                            </p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>
                                Show no of entity
                                <select
                                    className='ml-2 outline-none rounded-md bg-transparent border-[1px]'
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>SI.NO</th>
                                    <th className='px-2'>Edit</th>
                                    <th className='px-2'>CusID</th>
                                    <th className='px-2'>Type</th>
                                    <th className='px-2'>From Amount</th>
                                    <th className='px-2'>To Amount</th>
                                    <th className='px-2'>Day Name</th>
                                    <th className='px-2'>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => {
                                    const formattedDate = formatDateTime(entity.date);
                                    return (
                                        <tr key={index} className='h-16 text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-2'>{entity.id}</td>
                                            <td className='px-5 md:px-2'>
                                                {editId === entity.id ? (
                                                    <div className='text-sm flex gap-1'>
                                                        <button className='bg-green-500 px-3 py-1 rounded-md' onClick={handleUpdateClick}>Update</button>
                                                        <button className='bg-red-500 px-3 py-1  rounded-md ' onClick={handleCancelClick}>Cancel</button>
                                                    </div>
                                                ) : (
                                                    <button className='bg-blue-500 px-3 py-1 rounded-md' onClick={() => handleEditClick(entity)}>Edit</button>
                                                )}
                                            </td>
                                            <td className='px-5 md:px-2'>
                                                {entity.CusID}
                                            </td>
                                            <td className='px-5 md:px-2'>{entity.Type}</td>
                                            <td className='px-5 md:px-2'>
                                                {editId === entity.id ? (
                                                    <input
                                                        type='text'
                                                        name='fromAmount'
                                                        value={formData.fromAmount}
                                                        onChange={handleChange}
                                                        className='border w-[75%] py-1 outline-none bg-transparent'
                                                    />
                                                ) : (
                                                    entity.fromAmount
                                                )}
                                            </td>
                                            <td className='px-5 md:px-2'>
                                                {editId === entity.id ? (
                                                    <input
                                                        type='text'
                                                        name='toAmount'
                                                        value={formData.toAmount}
                                                        onChange={handleChange}
                                                        className='border w-[75%] py-1 outline-none bg-transparent'
                                                    />
                                                ) : (
                                                    entity.toAmount
                                                )}
                                            </td>
                                            <td className='px-5 md:px-2'>
                                                {editId === entity.id ? (
                                                    <select

                                                        name='dayName'
                                                        value={formData.dayName}
                                                        onChange={handleChange}
                                                        className='border w-[75%] py-1 outline-none bg-transparent'
                                                    >
                                                        <option className='text-black' value="All Days">All Days</option>
                                                        <option className='text-black' value="Monday">Monday</option>
                                                        <option className='text-black' value="Tuesday">Tuesday</option>
                                                        <option className='text-black' value="Wednesday">Wednesday</option>
                                                        <option className='text-black' value="Thursday">Thursday</option>
                                                        <option className='text-black' value="Friday">Friday</option>
                                                        <option className='text-black' value="Saturday">Saturday</option>
                                                        <option className='text-black' value="Sunday">Sunday</option>
                                                    </select>
                                                ) : (
                                                    entity.dayName
                                                )}
                                            </td>

                                            <td className='px-5 md:px-2'>{formattedDate}</td>
                                        </tr>
                                    );
                                })}
                                {emptyRowCount > 0 &&
                                    Array.from({ length: emptyRowCount }, (_, index) => (
                                        <tr key={index + currentEntities.length} className='h-16'>
                                            <td colSpan='8'></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-center mt-4'>
                        <button
                            className={`py-1 px-3 mr-1 border rounded-md text-lg ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        {pageNumbers.map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`py-1 px-3 mx-1 border rounded-md text-lg ${currentPage === pageNumber ? 'bg-blue-500 text-white' : ''}`}
                                onClick={() => handlePageClick(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className={`py-1 px-3 ml-1 border rounded-md text-lg ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={handleNextClick}
                            disabled={currentPage === totalPages}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}