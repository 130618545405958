import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
//import { useSelector, useDispatch } from 'react-redux';



export default function DoctorHeader({ selectedTab }) {

    // const email = useSelector((state) => state.doctor_email);
    const email = 'user@gmail.com';
    console.log("email", email)
    const initial = email ? email.charAt(0).toUpperCase() : '';

    return (
        <div className='relative z-10 '>
            <nav className='flex justify-between px-5 py-5 md:py-3'>
                <div className='w-[50%] flex gap-7'>
                    <div className='hidden md:block w-[30%]'>
                        <a href='/'><img src='/assets/MBC_logo.png' className='w-[60px] mx-auto'></img></a>
                    </div>
                    <div className='flex items-center'>
                        <p className='font-bold  uppercase flex flex-col md:flex-row'><p className='flex'><p className='text-white/50 text-sm uppercase  my-auto'>Dashboard</p><span className='px-2 font-bold'>/</span></p><p className='uppercase text-sm md:text-base font-bold'>{selectedTab}</p></p>
                    </div>
                </div>
                <div className='w-[20%] flex justify-end items-center md:pr-10'>
                    <button className='flex'>
                        <div className='h-10 w-10 flex justify-center items-center font-bold text-xl border rounded-full font-poppins'>{initial}</div>
                        <div className='flex items-center h-10 pl-2'><button className='flex items-center'>{email}
                        </button></div>
                    </button>
                </div>
            </nav>
        </div>
    )
}