import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


export default function Kyc() {
  const tableEntries = [
    {
      Sno: "1",
      action: "Process	",
      id: "23",
      custID: "264122",
      name: "Danagul Kaktayeva",
      status: "Pending",
      date: "6/5/2024 10:22:19 AM",
      message: "KYC Document Approval is Pending",
      idproof: "https://user.mybusinessclub.com/KYCDoc//264122ID_052224102219.jpg",
      idstatus: "Approved",
      idmsg: "	ID Approved",
      addressproof: "https://user.mybusinessclub.com/KYCDoc//264122ID_052224102219.jpg",
      addressstatus: "Rejected",
      addressmsg: "Address doesn't match in Profile",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  // Determine the current entries to display
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  // Handle previous and next page clicks
  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle dropdown change for entities per page
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const openImageIDproof = (entity) => {
    window.open(entity.idproof, '_blank');
  };
  const openImageAddressproof = (entity) => {
    window.open(entity.addressproof, '_blank');
  };

  // Calculate pagination details
  const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);


  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='p-3 md:p-7 mt-5 font-poppins'>
        <h1 className='text-2xl font-bold uppercase '>Approved KYC Details
        </h1>
        <div className=' rounded-lg mt-5 border '>
          <div className='px-5 py-3 border-b'>
            <p className='my-auto font-bold text-lg'>Filter
            </p>
          </div>
          <div className='px-5 py-3 flex flex-col md:flex-row  gap-3 md:gap-5 '>
            <div className='flex flex-col gap-1 md:w-[25%]'>
              <p className='text-[13px] font-bold'>Status</p>
              <select className='bg-transparent px-4 py-1 rounded-md border outline-none'>
                <option className='text-black'>Pending</option>
                <option className='text-black'>Approved</option>
                <option className='text-black'>Rejected</option>
              </select>
            </div>
            <div className='flex flex-col gap-1 md:w-[25%]'>
              <p className='text-[13px] font-bold'>Cust ID</p>
              <input className='bg-transparent px-4 py-1 rounded-md border outline-none'></input>
            </div>
            <div className='my-auto md:w-[25%]'>
              <button className='px-5 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md'>Submit</button>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className=' rounded-lg mt-5 border '>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between px-5 py-3 border-b'>
            <div className=''>
              <p className='my-auto font-bold text-lg'>KYC Report
              </p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className=' rounded-lg border-b pb-3'>
            <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
              <table className='w-full text-center text-[13px] '>
                <thead className=' uppercase font-bold border-b py-3'>
                  <tr>
                    <th className='py-3 px-2'>#</th>
                    <th className='px-2'>Action</th>
                    <th className='px-2'>ID</th>
                    <th className='px-2'>Cust_ID</th>
                    <th className='px-2'>Name</th>
                    <th className='px-2'>Status</th>
                    <th className='px-2'>Date/Time</th>
                    <th className='px-2'>Message</th>
                    <th className='px-2'>ID Proof	</th>
                    <th className='px-2'>ID Status	</th>
                    <th className='px-2'>IDMsg</th>
                    <th className='px-2'>Address Proof</th>
                    <th className='px-2'>Address Status</th>
                    <th className='px-2'>AddressMsg</th>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {currentEntities.map((entity, index) => (
                    <tr key={index} className='h-16 text-center rounded-md font-bold'>
                      <td className='px-5 md:px-2'>{entity.Sno}</td>
                      <td className='px-5 md:px-2'>{entity.action}</td>
                      <td className='px-5 md:px-2'>{entity.id}</td>
                      <td className='px-5 md:px-2'>{entity.custID}</td>
                      <td className='px-5 md:px-2'>{entity.name}</td>
                      <td className='px-5 md:px-2 m-auto'><div className={` text-black rounded-full px-2 text-[13px] ${entity.status === 'Approved' ? 'bg-green-500' : entity.status === 'Rejecting' ? 'bg-blue-400' : 'bg-yellow-500'}`}>{entity.status}</div></td>
                      <td className='px-5 md:px-2'>{entity.date}</td>
                      <td className='px-5 md:px-2'>{entity.message}</td>
                      <td className='px-5 md:px-2'>
                        <div className='mx-auto flex flex-col gap-2 justify-center items-center'>
                          <div> <img src={entity.idproof} alt="Document" className="w-14 h-10 flex-shrink-0" /></div>
                          <div> <button onClick={() => openImageIDproof(entity)} className='bg-blue-500 rounded-md p-1 text-xs font-bold'>View</button></div>
                        </div>
                      </td>
                      <td className='px-5 md:px-2 m-auto'><div className={` text-black rounded-full px-2 text-[13px] ${entity.idstatus === 'Approved' ? 'bg-green-500' : entity.idstatus === 'Rejecting' ? 'bg-blue-400' : 'bg-yellow-500'}`}>{entity.idstatus}</div></td>
                      <td className='px-5 md:px-2'>{entity.idmsg}</td>
                      <td className='px-5 md:px-2'>
                        <div className='mx-auto flex flex-col gap-2 justify-center items-center '>
                          <div> <img src={entity.addressproof} alt="Document" className="w-14 h-10 object-cover" /></div>
                          <div> <button onClick={() => openImageAddressproof(entity)} className='bg-blue-500 rounded-md p-1 text-xs font-bold'>View</button></div>
                        </div>
                      </td>
                      <td className='px-5 md:px-2 m-auto'><div className={` text-black rounded-full px-2 text-[13px] ${entity.addressstatus === 'Approved' ? 'bg-green-500' : entity.addressstatus === 'Rejected' ? 'bg-fuchsia-500' : 'bg-yellow-500'}`}>{entity.addressstatus}</div></td>
                      <td className='px-5 md:px-2'>{entity.addressmsg}</td>
                    </tr>
                  ))}
                  {/* Add empty rows if needed */}
                  {Array.from({ length: emptyRowCount }).map((_, index) => (
                    <tr key={`empty-${index}`} className='h-16'>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mt-2 flex justify-between text-xs px-3'>
              <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
              <div className='my-auto flex justify-center items-center gap-1'>
                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
              </div>
              <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
} 