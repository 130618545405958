import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import './fonts/Artemus.woff';
import './fonts/Artemus.ttf';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import ReactGA from "react-ga4";
import Intercom from '@intercom/messenger-js-sdk';
import { Provider } from 'react-redux';
import { store } from './store';
import toast, { Toaster } from "react-hot-toast";

Intercom({
  app_id: 'xvklq4eq',
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'e489cd9d43d5d2eac184b6ad121ef4fb';


const bscMainnet = {
  chainId: 56,
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://red-special-sea.bsc.quiknode.pro/71805dc676a9b32991fd57b913fd3b7b6dff75f2'
};


const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mybusinessclub.com/',
  icons: ['https://mybusinessclub.com/assets/MBC_logo.png']
};

const bscTestnet = {
  chainId: 97,
  name: "Binance Testent",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com/tx/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [bscMainnet],
  themeMode: 'light',
  projectId,
  enableEmail: true,
  defaultChain: bscMainnet,
  themeVariables: {
    '--w3m-accent': 'linear-gradient(to right, #E43875, #F98736)',
  },
  enableAnalytics: true
});

// const TRACKING_ID = "G-RKMDER1ZTK";
const TRACKING_ID = "G-MYG08T2T0E";

ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Mybusinessclub"
    });
  }, []);

  return (
    <Routers />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <Toaster position='top-center' />
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
