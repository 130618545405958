import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../config';
import axios from 'axios';
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';

export default function UserTickets() {
    const user = useSelector((state) => state.user);
    const email = user[0]?.email || "";
    const CusID = user[0]?.CusID || "";

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [imageModal, setImageModal] = useState({ isOpen: false, imageUrl: '' });
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newDescription, setNewDescription] = useState('');
    const [adminReply, setAdminReply] = useState('');
    const [subject, setSubject] = useState('');


    const fetchTickets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/getTicketsByCusID?CusID=${CusID}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const ticketData = Array.isArray(data.tickets) ? data.tickets : [];
            setTickets(ticketData);
            setFilteredTickets(ticketData);
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        const filtered = filterStatus
            ? tickets.filter(ticket => ticket.status.toLowerCase() === filterStatus.toLowerCase())
            : tickets;
        setFilteredTickets(filtered);
        setCurrentPage(1);
    }, [filterStatus, tickets]);

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(filteredTickets.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleViewImage = (imageUrl) => {
        setImageModal({ isOpen: true, imageUrl });
    };

    const handleCloseImageModal = () => {
        setImageModal({ isOpen: false, imageUrl: '' });
    };

    const handleTicketClick = (ticket) => {
        setSelectedTicket(ticket);
        setNewDescription(ticket.description);
        setAdminReply(ticket.admin_reply);
        setSubject(ticket.subject)

    };

    const handleCloseModal = () => {
        setSelectedTicket(null);
        setIsEditing(false);
    };

    const handleEditDescription = () => {
        setIsEditing(true);
    };

    const handleSaveDescription = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/updateTicketDescription`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ description: newDescription, ticket_id: selectedTicket.ticket_id }),
            });

            if (response.ok) {
                toast.success('Description updated successfully');
                const updatedTickets = tickets.map(ticket =>
                    ticket.ticket_id === selectedTicket.ticket_id
                        ? { ...ticket, description: newDescription }
                        : ticket
                );
                setTickets(updatedTickets);
                setFilteredTickets(updatedTickets);
                setIsEditing(false);
                setSelectedTicket(null);
            } else {
                toast.error('Failed to update the description');
            }
        } catch (error) {
            console.error('Error updating description:', error);
            toast.error('Error updating the description');
        }
    };

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredTickets.slice(indexOfFirstEntity, indexOfLastEntity);

    const totalPages = Math.ceil(filteredTickets.length / entitiesPerPage);
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (
        <div className="mx-auto font-poppins">
            <div data-aos="fadeIn" data-aos-duration="2000">
                <div className="p-3 md:p-7 mt-5 font-poppins">
                    <h1 className="text-2xl font-bold capitalize">Support Ticket</h1>

                    <div className="rounded-lg mt-5 border">
                        <div className="px-5 py-3 border-b font-bold">
                            <p>Filter Your Search</p>
                        </div>
                        <div className="p-3 grid flex-col lg:grid-cols-3 gap-3 lg:gap-10">
                            <div className="text-sm font-bold m-auto">
                                Total Record: {filteredTickets.length}
                            </div>
                            <div className="flex gap-1 m-auto items-center md:gap-10 justify-center md:justify-between">
                                <p className="text-end  px-3 my-auto">
                                    Filter
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={(e) => setFilterStatus(e.target.value)}
                                        value={filterStatus}
                                    >
                                        <option className="text-black" value="">All</option>
                                        <option className="text-black" value="Open">Open</option>
                                        <option className="text-black" value="In Progress">In Progress</option>
                                        <option className="text-black" value="On Hold">On Hold</option>
                                        <option className="text-black" value="Resolved">Resolved</option>
                                    </select>
                                </p>
                            </div>
                            <div className="text-sm md:justify-end font-bold m-auto">
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5 border pb-3">
                        <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b">
                            <div>
                                <p className="font-bold">Showing All Ticket</p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-end text-sm px-3 my-auto">Show number of entities
                                    <select
                                        className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                        onChange={handleDropdownChange}
                                        value={entitiesPerPage}
                                    >
                                        <option className="text-black" value="5">5</option>
                                        <option className="text-black" value="10">10</option>
                                        <option className="text-black" value="50">50</option>
                                        <option className="text-black" value="100">100</option>
                                    </select>
                                </p>
                            </div>
                        </div>
                        <div className="mb-5 overflow-hidden hover:overflow-x-auto">
                            <table className="w-full text-center text-[15px]">
                                <thead className="uppercase font-bold border-b py-3 text-nowrap">
                                    <tr>
                                        <th className="py-3 px-2">ID</th>
                                        <th className="px-2">Ticket ID</th>
                                        <th className="px-2">Status</th>
                                        <th className="px-2">Subject</th>
                                        <th className="px-2">Created At</th>
                                        <th className="px-2">Image</th>
                                        <th className="px-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    {currentEntities.map((ticket, index) => (
                                        <tr key={ticket.ticket_id} className="h-16 text-center rounded-md font-bold">
                                            <td className="px-2">{(currentPage - 1) * entitiesPerPage + index + 1}</td>
                                            <td className="px-2">#{ticket.ticket_id}</td>
                                            <td className="px-2">{ticket.status}</td>
                                            <td className="px-2">{ticket.subject}</td>
                                            <td className="px-2">{formatDate(ticket.created_at)}</td>
                                            <td className="px-2">
                                                <button
                                                    className={` text-black px-4 py-2 rounded-md ml-2 ${ticket.image === null ? 'cursor-not-allowed bg-gray-500' : 'cursor-pointer bg-gray-300'}`}
                                                    onClick={() => handleViewImage(ticket.fullImageUrl)}
                                                    disabled={!ticket.fullImageUrl}
                                                >
                                                    View Image
                                                </button>
                                            </td>
                                            <td className="px-2">
                                                <button
                                                    className="bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-white px-4 py-2 rounded-md"
                                                    onClick={() => handleTicketClick(ticket)}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-center gap-5 mt-5 px-5">
                                <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <div>
                                    {pageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => handlePageClick(number)}
                                            className={`px-3 py-1 ${number === currentPage ? 'border border-white rounded-lg' : ''}`}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                                <button onClick={handleNextClick} disabled={currentPage === totalPages}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {selectedTicket && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-md bg-opacity-50">
                            <div className="bg-white rounded-lg shadow-lg p-6 w-2/3">
                                <h3 className="text-lg font-bold text-black  mt-4">Admin Response</h3>
                                <div className="w-full border text-black mb-4 rounded-md p-2">
                                    {subject}
                                </div>
                                <h3 className="text-lg font-bold text-black">Edit Ticket Description</h3>
                                <textarea
                                    className="w-full border text-black rounded-md p-2"
                                    rows="5"
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    disabled={!isEditing}
                                ></textarea>
                                <h3 className="text-lg font-bold text-black mt-4">Admin Response</h3>

                                <div className="w-full border text-black rounded-md p-2">
                                    {adminReply ? adminReply : "No Reply"}
                                </div>
                                <div className="flex justify-end mt-4 gap-3">
                                    {!isEditing ? (
                                        <button
                                            className="px-4 py-2 bg-blue-500 text-black  rounded-md"
                                            onClick={handleEditDescription}
                                        >
                                            Edit
                                        </button>
                                    ) : (
                                        <button
                                            className="px-4 py-2 bg-green-500 text-black rounded-md"
                                            onClick={handleSaveDescription}
                                        >
                                            Save
                                        </button>
                                    )}
                                    <button
                                        className="px-4 py-2 bg-gray-500 text-black rounded-md"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {imageModal.isOpen && (
                        <div className="fixed inset-0 backdrop-blur-md flex justify-center items-center z-50">
                            <div className="bg-white mt-28 p-1 rounded-lg w-[70%]">
                                <img src={imageModal.imageUrl} alt="Ticket Image" className='w-full' />
                                <div className="flex justify-center mt-3">
                                    <button
                                        className="bg-red-500 text-white px-4 py-2 rounded-md"
                                        onClick={handleCloseImageModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}
