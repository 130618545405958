import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL, PROVIDER_URL, MBCPayOut_ContractAddress } from '../../config';
import { MBCPayOut_abi } from '../../ABI';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import { ethers } from "ethers";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers5/react";

export default function RewardList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [userList, setUserList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const fetchUserRewards = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${SERVER_URL}/api/getRewards`);
            if (response.data.status === true) {
                const users = response.data.data;

                const usersWithWallet = users.filter(user => user.income_wallet);
                let provider, signer, contract
                if (isConnected === true) {
                    provider = new ethers.providers.Web3Provider(walletProvider);
                    signer = provider.getSigner();
                    contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
                } else {
                    provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
                    signer = provider.getSigner();
                    contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, signer);
                }

                const usersWithRewards = [];

                // for (let user of usersWithWallet) {
                //     try {
                //         console.log(user.income_wallet);
                //         const rewards = await contract.getuserDetails(user.income_wallet);
                //         const formattedRewards = ethers.utils.formatEther(rewards[7]);

                //         usersWithRewards.push({
                //             ...user,
                //             rewards: formattedRewards,
                //         });
                //     } catch (error) {
                //         console.error(`Error fetching rewards for wallet ${user.income_wallet}:`, error);
                //         usersWithRewards.push({
                //             ...user,
                //             rewards: "0",
                //         });
                //     }
                // }

                setUserList(usersWithRewards);
            } else {
                setUserList([]);
            }
        } catch (error) {
            console.error("Error fetching user rewards:", error);
            toast.error("Failed to fetch rewards");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserRewards();
    }, []);

    const filteredUsers = userList.filter((data) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            data.CusID?.toString().toLowerCase().includes(searchLower) ||
            data.income_wallet?.toLowerCase().includes(searchLower) ||
            data.rewards?.toString().toLowerCase().includes(searchLower)
        );
    });

    const totalPages = Math.ceil(filteredUsers.length / entitiesPerPage);
    const currentEntities = filteredUsers.slice(
        (currentPage - 1) * entitiesPerPage,
        currentPage * entitiesPerPage
    );

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        const newEntitiesPerPage = parseInt(e.target.value);
        setEntitiesPerPage(newEntitiesPerPage);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const calculatePaginationRange = () => {
        const maxButtons = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        const endPage = Math.min(totalPages, startPage + maxButtons - 1);

        if (endPage - startPage + 1 < maxButtons && startPage > 1) {
            return Array.from({ length: maxButtons }, (_, i) => startPage - maxButtons + endPage + i + 1).filter(
                (page) => page <= totalPages
            );
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    return (
        <div className="mx-auto font-poppins">
            <div data-aos="fadeIn" data-aos-duration="2000">
                <div className="p-3 md:p-7 mt-5 font-poppins">
                    <h1 className="text-2xl font-bold uppercase ">Rewards List</h1>
                    <div className="rounded-lg mt-5 border">
                        <div className="px-5 py-3 border-b font-bold">
                            <p>Filter Your Search</p>
                        </div>
                        <div className="p-3 grid flex-col lg:grid-cols-5 gap-3 lg:gap-10">
                            <div className="text-sm font-bold m-auto">
                                Total Record: {filteredUsers.length}
                            </div>
                            <div className="flex gap-2 items-center justify-center lg:col-span-3">
                                <p className="text-sm font-bold">Search:</p>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search anything..."
                                    className="pl-3 border bg-transparent rounded-md py-1 w-full lg:w-[50%]"
                                />
                            </div>
                            <div className="text-sm font-bold m-auto">
                                Page {currentPage} of {totalPages}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg mt-5 border pb-3">
                        {loading ? (
                            <p className="text-center font-bold py-5">Loading...</p>
                        ) : (
                            <div>
                                <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b">
                                    <div>
                                        <p className="font-bold">Showing All Withdrawals</p>
                                    </div>
                                    <div className="flex items-center">
                                        <p className="text-end text-sm px-3 my-auto">Show number of entities
                                            <select
                                                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                                onChange={handleDropdownChange}
                                                value={entitiesPerPage}
                                            >
                                                <option className="text-black" value="5">5</option>
                                                <option className="text-black" value="10">10</option>
                                                <option className="text-black" value="50">50</option>
                                                <option className="text-black" value="100">100</option>
                                            </select>
                                        </p>
                                    </div>
                                </div>
                                <div className="mb-5 overflow-hidden hover:overflow-x-auto">
                                    <table className="w-full text-center text-[15px]">
                                        <thead className="uppercase font-bold border-b py-3 text-nowrap">
                                            <tr>
                                                <th className="py-3 px-2">S.No</th>
                                                <th className="px-2">CusID</th>
                                                <th className="px-2">Income Wallet</th>
                                                <th className="px-2">Rewards</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y">
                                            {currentEntities.map((data, index) => (
                                                <tr className="h-16 text-center rounded-md font-bold" key={index}>
                                                    <td className="px-2">
                                                        {(currentPage - 1) * entitiesPerPage + index + 1}
                                                    </td>
                                                    <td className="px-2">{data.CusID}</td>
                                                    <td className="px-2 truncate max-w-[30px] hover:overflow-visible relative group">
                                                        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {data.income_wallet}
                                                        </div>
                                                        <div className="absolute left-0 top-0 bg-gray-800 text-white text-sm rounded p-2 opacity-0 group-hover:opacity-100 z-10 w-max max-w-[400px]">
                                                            {data.income_wallet}
                                                        </div>
                                                    </td>
                                                    <td className="px-2">{data.rewards}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="flex justify-center gap-5 mt-5 px-5">
                                        <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </button>
                                        <div>
                                            {calculatePaginationRange().map((page) => (
                                                <button
                                                    key={page}
                                                    onClick={() => handlePageClick(page)}
                                                    className={`px-3 py-1 ${page === currentPage ? 'border border-white rounded-lg' : ''}`}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                        </div>
                                        <button onClick={handleNextClick} disabled={currentPage === totalPages}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
